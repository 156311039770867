import React from "react";
import OrderReceiptResponse from "../Models/OrderReceiptResponse";
import { convertToRupiah } from "../../../CommonComponents/Utils";

type OrderReceiptItemsWidgetProps = {
    orderReceiptData: OrderReceiptResponse | null
};

export default class OrderReceiptItemsWidget extends React.Component<OrderReceiptItemsWidgetProps> {
    render() {
        const orderReceiptData = this.props.orderReceiptData;

        return(
            <div className="row">
                <div className='box_white_cart'>
                    <div className="col">
                        {
                        orderReceiptData?.orderItems[0].sellerDetail?.name !== undefined &&
                            <a href={"/"+orderReceiptData?.orderItems[0].sellerDetail?.sellerId}>
                                <div id="CreatorInformation">
                                        <ul className="list-inline">
                                            <li className="list-inline-item"><img alt="" width="35" className="creatorPhoto" src={orderReceiptData?.orderItems[0].sellerDetail?.profileImageUrl}></img></li>
                                            <li className="list-inline-item"><span>{orderReceiptData?.orderItems[0].sellerDetail?.name}</span></li>
                                            <li className="list-inline-item">&nbsp;&nbsp;<img alt="" width="17" src={ImgUrl+'shopable/icSellershopS@2x.png'}></img></li>
                                        </ul>
                                    <span className="arrow">{">"}</span>
                                </div>
                            </a>
                        }
                        {
                        orderReceiptData?.orderItems[0].items.map((items,index) => (
                        <div className='ItemDiv' key={index}>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="row">
                                        <div className="col-5">
                                            <img src={items.imageUrl} className="width97p" alt="img_product"></img>
                                        </div>
                                        <div className="col-7">
                                            <p className="font11">{items.name}</p>
                                            <p className="colorgrey font11 mtopm15">{items.itemDescription}</p>
                                            <p className="font11"><b>{convertToRupiah(items.price.netPrice)} </b></p>
                                            <p  className="font11 mtopm15">{items.attribute}</p>
                                            <p className="colorgrey font11 mtopm15">{items.weight}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}