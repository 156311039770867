import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowErrorModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { getPreloadProps, productTypesForBadgeUrls } from "../../CommonComponents/Utils";
import { ProductBadgeType } from "../../CommonTypes";
import ProductGroupMainResponse from "./Models/ProductGroupMainResponse";

export default class ProductGroupMainViewModel extends ViewModel {
    numberInCart: number = 0;
    groupId: string;
    categoryId: string;
    isLoading: boolean = false;
    model: ProductGroupMainResponse | null = null;
    errorMessage: ShowErrorModel | null = null;
    badgeUrlsMap?: {[key in ProductBadgeType]: string};

    constructor(args: {groupId: string, categoryId: string}) {
        super();
        this.groupId = args.groupId;
        this.categoryId = args.categoryId
    }

    loadPreloadingProps() {
        this.emit(() => { 
            this.isLoading = true;
         });

        getPreloadProps({
            onSuccess: (_, __, numberInCart, badgeUrlsMap) => {
                this.emit(() => {
                  
                    this.badgeUrlsMap = badgeUrlsMap;
                    this.badgeUrlsMap = badgeUrlsMap;
                    this.numberInCart = numberInCart;
                });

                this.loadContents();
            },
            onFailure: error => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = {
                        message: error,
                        onRetryButtonTapped: () => this.loadPreloadingProps(),
                    };
                });
            },
        });
    }

    loadContents() {
        this.emit(() => {
            this.isLoading = true;
            this.errorMessage = null;
        });

        requestAPI(Endpoints.storeMainProductGroup, {
            parameters: {
                productGroupId: this.groupId,
                categoryId: this.categoryId,
            },
            onSuccess: (response: BaseResponse<ProductGroupMainResponse>) => {
                if (response.result) {
                    this.emit(() => {
                        this.isLoading = false;
                        this.model = response.data;
                        this.model.feeds = this.model.feeds.map(feed => ({
                          ...feed,
                          contents: {
                            ...feed.contents,
                            bannersProducts: feed.contents.bannersProducts.map(bp => ({
                              ...bp,
                              pairedProducts: (bp.pairedProducts || []).map(p => ({
                                ...p,
                                badgeUrls: productTypesForBadgeUrls(p.badgeTypes, this.badgeUrlsMap || {})
                              })),
                              products: (bp.products || []).map(p => ({
                                ...p,
                                badgeUrls: productTypesForBadgeUrls(p.badgeTypes, this.badgeUrlsMap || {})
                              }))
                            }))
                          }
                        }))
                        console.log(this.badgeUrlsMap)
                        console.log(this.model)
                    });
                } else {
                    this.emit(() => {
                        this.isLoading = false;
                        this.errorMessage = {
                            message: response.message,
                            onRetryButtonTapped: () => this.loadContents()
                        };
                    });
                }
            },
            onFailure: (error: string) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = {
                        message: error,
                        onRetryButtonTapped: () => this.loadContents()
                    };
                });
            }
        });
    }

    changeCategoryId(value: string) {
        this.emit(() => {
            this.categoryId = value;
        });

        this.loadContents();
    }
}