import React from "react";
import shape from "../../../Assets/Images/Shape.svg";
import TrackingResponse from "../Models/TrackingResponse";
import ChannelChatPopup from "../../../CommonComponents/UI/ChannelChatPopup/ChannelChatPopup";

type PopupTrackingProps = {
  isShowLoadingTracking: boolean;
  dataTracking: TrackingResponse | null;
  showModal?: boolean;
  isEnabled?: boolean;
  Message?: string | null;
  closeModal?: () => void;
};

export default class PopupTrackingWidget extends React.Component<PopupTrackingProps> {
  render() {
    const dataTracking = this.props.dataTracking;
    const day = this.props.dataTracking?.orderTime.substring(8, 10);
    const month = this.props.dataTracking?.orderTime.substring(5, 7);
    const year = this.props.dataTracking?.orderTime.substring(0, 4);
    const hour = this.props.dataTracking?.orderTime.substring(11, 13);
    const min = this.props.dataTracking?.orderTime.substring(14, 16);
    const sec = this.props.dataTracking?.orderTime.substring(17, 19);

    return (
      <>
        <div
          className={this.props.showModal ? "modal-backdrop fade show" : ""}
        ></div>
        <div
          className={this.props.showModal ? "modal fade show" : "modal fade"}
          style={
            this.props.showModal ? { display: "block" } : { display: "none" }
          }
          id="TrackingModal"
          aria-labelledby="ErrorModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            {this.props.isShowLoadingTracking ? (
              <div
                className="modal-content"
                style={{ paddingTop: "70%", height: "100vh" }}
              >
                <div className="LoadingWidgetContainer LoadingWidgetLightTheme">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : (
              <div className="modal-content" style={{ padding: "24px" }}>
                <button
                  onClick={this.props.closeModal}
                  type="button"
                  className="btn-close closeBtn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
                <h6>Track My Order</h6>

                <div className="row">
                  <div className="col border">
                    <p className="font14">
                      <b>Status</b>
                    </p>
                    <p className="font12">{dataTracking?.status}</p>
                  </div>
                  <div className="col border">
                    <p className="font14">
                      <b>Order Date</b>
                    </p>
                    <p className="font12">
                      {day +
                        "/" +
                        month +
                        "/" +
                        year +
                        " " +
                        hour +
                        ":" +
                        min +
                        ":" +
                        sec}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p className="font14">
                      <img alt="" src={shape}></img> &nbsp;
                      <b>Courier Tracking</b>
                    </p>

                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">No.</th>
                          <th scope="col">
                            {dataTracking?.resiNumber === ""
                              ? "No Information"
                              : dataTracking?.resiNumber}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th colSpan={2}>
                            {dataTracking?.resiNumber === "" ? (
                              "No Information"
                            ) : (
                              <>
                                <img
                                  alt=""
                                  width="38"
                                  src={
                                    dataTracking?.courierDetail.courierImageUrl
                                  }
                                ></img>{" "}
                                <span>
                                  &nbsp;&nbsp;
                                  {dataTracking?.courierDetail.courierLabel}
                                </span>
                              </>
                            )}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row">
                  <div className="wrapper">
                    <ul className="StepProgress" style={{ padding: "0px" }}>
                      {dataTracking?.trackingDetailList.map((item, index) =>
                        index === 0 ? (
                          <div
                            className="row"
                            key={index}
                            style={{ height: "40px" }}
                          >
                            <div className="col-1">
                              <div className="StepProgress-item-active">✔</div>
                            </div>
                            <div className="col-10">
                              {dataTracking?.resiNumber === "" ? (
                                <p className="font14 colormediumgrey">
                                  {item.timeLabel.substring(8, 10) +
                                    "/" +
                                    item.timeLabel.substring(5, 7) +
                                    "/" +
                                    item.timeLabel.substring(0, 4) +
                                    " " +
                                    item.timeLabel.substring(11, 13) +
                                    ":" +
                                    item.timeLabel.substring(14, 16) +
                                    ":" +
                                    item.timeLabel.substring(17, 19)}
                                </p>
                              ) : (
                                <p className="font14 colormediumgrey">
                                  {item.timeLabel.substring(0, 2) +
                                    "/" +
                                    item.timeLabel.substring(3, 5) +
                                    "/" +
                                    item.timeLabel.substring(6, 10) +
                                    " " +
                                    item.timeLabel.substring(11, 13) +
                                    ":" +
                                    item.timeLabel.substring(14, 16)}
                                </p>
                              )}
                              <p className="font14">
                                <strong>{item.description}</strong>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="row"
                            key={index}
                            style={{ marginTop: "-30px", height: "130px" }}
                          >
                            <div className="col-1">
                              <div className="StepProgress-item">
                                <div className="borderLeft"></div>
                                <div className="StepProgress-items"></div>
                              </div>
                            </div>
                            <div
                              className="col-10"
                              style={{ paddingTop: "90px" }}
                            >
                              {dataTracking?.resiNumber === "" ? (
                                <p className="font14 colormediumgrey">
                                  {item.timeLabel.substring(8, 10) +
                                    "/" +
                                    item.timeLabel.substring(5, 7) +
                                    "/" +
                                    item.timeLabel.substring(0, 4) +
                                    " " +
                                    item.timeLabel.substring(11, 13) +
                                    ":" +
                                    item.timeLabel.substring(14, 16) +
                                    ":" +
                                    item.timeLabel.substring(17, 19)}
                                </p>
                              ) : (
                                <p className="font14 colormediumgrey">
                                  {item.timeLabel.substring(0, 2) +
                                    "/" +
                                    item.timeLabel.substring(3, 5) +
                                    "/" +
                                    item.timeLabel.substring(6, 10) +
                                    " " +
                                    item.timeLabel.substring(11, 13) +
                                    ":" +
                                    item.timeLabel.substring(14, 16)}
                                </p>
                              )}
                              <p className="font14">
                                <b>{item.description}</b>
                              </p>
                            </div>
                          </div>
                        )
                      )}
                    </ul>
                  </div>
                </div>
                <div className="row garisabu"></div>
                <div className="row">
                  <div className="col">
                    <p className="mtop17 font14">
                      <b>Need Help?</b>
                    </p>
                    <div
                      className="div_ask font12"
                      data-bs-toggle="modal"
                      data-bs-target="#ChatModal"
                    >
                      <img
                        src={global.ImgUrl + "shopable/ic-detail-chat.png"}
                        alt="img_chat"
                      ></img>
                      &nbsp;&nbsp;Ask Anytime
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <ChannelChatPopup />
      </>
    );
  }
}
