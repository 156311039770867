import CollapsibleViewWidget from "../../../../CommonComponents/UI/CollapsibleView/CollapsibleViewWidget";
import "./OrderPaymentStepsWidget.css";

export type OrderPaymentStepsWidgetProps = {
  steps?: {
    title?: string;
    details?: string[];
  }[];
};

function OrderPaymentStepsWidget(props: OrderPaymentStepsWidgetProps) {
  const { steps } = props;

  return (
    <div className="OrderPaymentStepsWidgetContainer">
      <div className="OrderPaymentStepsHeaderTitle">Payment Steps</div>
      {steps?.map((step, index) => (
        <>
          {" "}
          <CollapsibleViewWidget
            renderHandle={({ isOpen, onClick }) => (
              <div
                role="button"
                onClick={() => onClick?.()}
                className="OrderPaymentStepsHeaderTitle"
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  padding: "16px 24px 16px 24px",
                }}
              >
                {step.title}
                {isOpen ? (
                  <svg
                    width="18"
                    height="10"
                    viewBox="0 0 18 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 9L9 1L17 9"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                ) : (
                  <svg
                    width="18"
                    height="10"
                    viewBox="0 0 18 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 1L9 9L1 1"
                      stroke="black"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </div>
            )}
          >
            <div
              style={{
                padding: "0px 24px 0px 24px",
                fontSize: 12,
                marginBottom: 8,
                display: "flex",
              }}
            >
              <div>
                {step.details?.map((detail, index) => (
                  <div style={{ display: "flex", marginBottom: 8 }}>
                    <div style={{ width: 24 }}>{index + 1}.</div>
                    <p>{detail}</p>
                  </div>
                ))}
              </div>
            </div>
          </CollapsibleViewWidget>
          <div style={{ borderBottom: "1px solid #f2f2f2" }} />
        </>
      ))}
    </div>
  );
}

export default OrderPaymentStepsWidget;
