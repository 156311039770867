import React from "react";
import { VideoPlayer } from "../../../CommonComponents/UI/Cells/VideoPlayer";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { getLocationQueries, getScreenSizeWithMaxWidth } from "../../../CommonComponents/Utils";
import { ShowCaseDetailCoverModel } from "../Models/ShowCaseDetailCoverModel";

interface Props {
    model: ShowCaseDetailCoverModel;
    navBarBackgroundHex: string;
    isTopGradientInCoverShown: boolean;
    isBottomGradientInCoverShown: boolean;
    onClick?: () => void;
    isActive?: boolean;
}

export default class ShowCaseDetailCoverWidget extends React.Component<Props> {
    render() {
        const model = this.props.model;
        const topSafeAreaMargin = Number(getLocationQueries()["topSafeAreaMargin"] ?? "52.0");
        const onClick = this.props.onClick;

        return <div id="ShowCaseDetailCover" 
            style={{
                height: `${getScreenSizeWithMaxWidth().width / 0.8}px`
            }}
            onClick={() => {
                if (onClick) onClick();
            }}
        >
            {
                model.assetType === "VIDEO" ?
                <VideoPlayer 
                    videoUrl={model.assetUrl} 
                    videoThumbUrl={model.videoThumbnailUrl}
                    forcePlay={this.props.isActive}
                /> :
                <ImageWidget imageUrl={model.assetUrl} />
            }
            
            <div 
                style={{
                    background: this.props.isTopGradientInCoverShown ? `linear-gradient(to bottom, ${this.props.navBarBackgroundHex}88, ${this.props.navBarBackgroundHex}00)` : undefined,
                    paddingTop: `${topSafeAreaMargin + 8.0}px`,
                    textAlign: model.textAlignment === "CENTER" ? "center" : "left"
                }}
            >
                <p>{model.title}</p>
                <p>{model.subtitle}</p>
            </div>
            <div
                style={{
                    flexDirection: model.textAlignment === "CENTER" ? "column" : "row",
                    background: this.props.isBottomGradientInCoverShown ? `linear-gradient(to top, ${this.props.navBarBackgroundHex}${window.location.href.includes("/Detail") ? "88" : "FF"}, ${this.props.navBarBackgroundHex}00)` : undefined,
                }}
            >
                <div
                    style={{height: `${model.textAlignment === "CENTER" ? 100 : 48}px`, width: `${model.textAlignment === "CENTER" ? 100 : 48}px`}}
                >
                    <ImageWidget imageUrl={model.profileImageUrl} />
                </div>
                {
                    model.feedContextLabel.length > 0 &&
                    <div>
                        <p>{model.feedContextLabel}</p>
                    </div>
                }
                
            </div>
        </div>;
    }
}