import React from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import LabelItemModel from "../../../CommonComponents/Models/LabelItemModel";

import "./StoreCommonWidgets.css";

export interface StoreTopTabbarLabelItemModel extends LabelItemModel {
  onTap: () => void;
  shouldShowPing?: boolean;
}

interface StoreTopTabbarProps {
  labels: StoreTopTabbarLabelItemModel[];
  selectedId?: string;
  color?: string;
  fullWidth?: boolean;
  renderTabButton?: (props: {
    isActive?: boolean;
    label?: string;
    onClick?: () => void;
  }) => React.ReactNode;
  shouldShowBottomBorder?: boolean;
}

export default class StoreTopTabbarWidget extends React.Component<StoreTopTabbarProps> {
  render() {
    const { shouldShowBottomBorder = true } = this.props;

    return (
      <ScrollContainer
        horizontal
        // id="StoreTopTabbarRow"
        style={{
          height: "32px",
          paddingLeft: "16px",
          paddingRight: "16px",
          display: "flex",
          columnGap: "16px",
          flexWrap: "nowrap",
          overflowX: "auto",
          overflowY: "visible",
          flexShrink: 0,
          boxShadow: shouldShowBottomBorder ? "inset 0 -1px 0 0 #e4e4e4" : "",
          width: this.props.fullWidth ? "100%" : "auto",
        }}
      >
        {this.props.labels.map((model) => {
          return this.props.renderTabButton ? (
            this.props.renderTabButton({
              isActive: this.props.selectedId === model.id,
              label: model.label,
              onClick: model.onTap,
            })
          ) : (
            <button
              key={model.id}
              id={
                this.props.selectedId === model.id
                  ? "StoreTopTabbarSelectedItem"
                  : "StoreTopTabbarUnselectedItem"
              }
              onClick={model.onTap}
              style={{
                flexGrow: this.props.fullWidth ? 1 : 0,
                minWidth: "auto",
                flex: "0 0 auto",
                width: "auto",
                maxWidth: "100%",
              }}
            >
              <p style={{ position: "relative" }}>
                {model.label}{" "}
                {model.shouldShowPing && (
                  <span
                    style={{
                      position: "absolute",
                      width: 4,
                      height: 4,
                      top: 0,
                      right: -4,
                      borderRadius: 999,
                      backgroundColor: "#894CF5",
                    }}
                  ></span>
                )}
              </p>
              <div style={{ backgroundColor: this.props.color ?? "black" }} />
            </button>
          );
        })}
      </ScrollContainer>
    );
  }
}
