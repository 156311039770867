import React from "react";
import "./OrderIdWidget.css";
import OrderTimer from "../OrderTimer/OrderTimerWidget";
import SizedDiv from "../../../../CommonComponents/UI/SizedDiv";

export type OrderWidgetProps = {
  id?: string;
  // seconds
  reminder?: string;
  remainingTime?: number;
  message?: {
    headline?: string;
    contents?: string[];
  };
};

export default class OrderIdWidget extends React.Component<OrderWidgetProps> {
  render(): React.ReactNode {
    const { id, remainingTime, message } = this.props;

    return (
      <div className="OrderIdWidgetContainer">
        <p>Order #{id}</p>

        {remainingTime && (
          <>
            <SizedDiv height={16} />
            <OrderTimer seconds={remainingTime} />
          </>
        )}
        {this.props.reminder && (
          <>
            <SizedDiv height={8} />
            <div style={{ fontSize: 12 }}>{this.props.reminder}</div>
          </>
        )}
        <SizedDiv height={8} />
        {this.props.message && (
          <div className="OrderPaymentInfoMessagesContainer">
            <div className="OrderPaymentInfoMessagesHeadline">
              {message?.headline}
            </div>
            <SizedDiv height={8} />
            {message?.contents?.map((m) => (
              <div className="OrderPaymentInfoMessages">{m}</div>
            ))}
          </div>
        )}
      </div>
    );
  }
}
