import React from "react";
import ProductLocalItemModel from "../../Models/ProductLocalItemModel";
import { convertToRupiah, isResellerDomain } from "../../Utils";
import { ImageWidget } from "../ImageWidget/ImageWidget";

import "./ProductLocalItemCellWidget.css";
import { useNavigate, useParams } from "react-router-dom";
import { mixpanelManager } from "../../../Analytics/Mixpanel/Common";

type ProductLocalItemCellWidgetProps = {
  sellerId?: string;
  model: ProductLocalItemModel;
  cellWidth: number | string;
  leadingIndex?: string;
  theme?: string;
  badgeUrls?: string[];
  navigate: any;
};

class ProductLocalItemCellWidget extends React.Component<ProductLocalItemCellWidgetProps, {}> {
  render() {
    const model = this.props.model;
    const sellerId = this.props.sellerId;
    const leadingIndex = this.props.leadingIndex ?? (model.rank ? `${model.rank}` : undefined);
    const cellWidth = typeof this.props.cellWidth === "number" ? `${this.props.cellWidth}px` : this.props.cellWidth;
    const cellImageHeight = typeof this.props.cellWidth === "number" ? `${(this.props.cellWidth * 4) / 3}px` : `${(Number.parseFloat(this.props.cellWidth.replace("px", "")) * 4) / 3}px`;
    return (
      <div
        id="ProductLocalItemContainer"
        style={{ width: cellWidth }}
        onClick={() => {
          mixpanelManager.productClick(model.id, model.brand + " " + model.title, mixpanelManager.getGroupByUrl(), "", mixpanelManager.getPageNameByUrl(), mixpanelManager.getGroupByUrl());
          model.ageRestricted !== true
            ? this.props.navigate(`${isResellerDomain() ? `/shop/${sellerId}` : ""}/Product/${model.id}${sellerId !== undefined ? (isResellerDomain() ? "" : `?creator=${sellerId}`) : ""}`)
            : this.showAgeRestrictedModal(model.id, sellerId);
        }}
      >
        <div
          key={model.id}
          style={{
            height: cellImageHeight,
            borderRadius: "8px",
            overflow: "hidden",
            marginBottom: "4px",
            color: "#fffff",
          }}
        >
          <ImageWidget imageUrl={model.imageUrl} key={model.imageUrl} />
          {leadingIndex && (
            <div id="ProductLocalItemCellLeadingLabel">
              <p>{leadingIndex}</p>
            </div>
          )}
        </div>
        <p style={{ color: this.props.theme === "DARK" ? "white" : undefined }}>{model.brand}</p>
        <p style={{ color: this.props.theme === "DARK" ? "white" : undefined }}>{model.title}</p>
        <p
          style={
            model.price.discount > 0
              ? {
                  color: "#F14100",
                  fontWeight: "bold",
                }
              : {
                  color: this.props.theme === "DARK" ? "white" : undefined,
                }
          }
        >
          {convertToRupiah(model.price.netPrice)}
        </p>
        {model.price.discount > 0 && (
          <p>
            <span>{convertToRupiah(model.price.realPrice)}</span> <span>-{model.price.discount}%</span>
          </p>
        )}
        {model.footnote !== undefined && (
          <p id="ProductLocalFooterLabel" style={{ color: "var(--mediumGrey)" }}>
            {model.footnote ?? ""}
          </p>
        )}
        <div>
          {this.props.badgeUrls?.map((url) => (
            <span style={{ marginRight: 2 }}>
              <img alt="" style={{ height: 16, objectFit: "cover" }} src={url} />
            </span>
          ))}
        </div>
        {this.props.model.isSoldOut && <div id="ProductLocalItemSoldoutOverlay" />}
      </div>
    );
  }

  showAgeRestrictedModal(productId: string, sellerId?: string) {
    const scaffold = document.querySelectorAll("#Scaffold")[0];
    scaffold.appendChild(document.createElement("div")).className = "modal-backdrop show";
    const append = scaffold.appendChild(document.createElement("div"));
    append.className = "modal fade show";
    append.tabIndex = 1;
    const htmldata = `
        <div class="modal-dialog" role="document" id="AgeRestrictedModal"> 
            <div class="modal-content"> 
                <div class="modal-body"> 
                    <p><b>Age Confirmation</b></p>
                    <span>You must be over 21 to access this items</span>
                <div class="row" style="margin-top:36px;"> 
                    <div class="col text-center">
                        <button id="GoButton" class="btn btn-secondary">I’m over 21</button>
                    </div>
                    <div class="col text-center">
                        <button id="CancelButton" data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        `;
    append.innerHTML = htmldata;
    const GoButton = document.getElementById("GoButton");
    const cancelButton = document.getElementById("CancelButton");

    if (GoButton) {
      GoButton.onclick = (event) => {
        return this.props.navigate(`${isResellerDomain() ? this.props.sellerId : ""}/Product/${productId}${sellerId !== undefined ? `?creator=${sellerId}` : ""}`);
      };
    }
    if (cancelButton) {
      cancelButton.onclick = (event) => {
        return this.closeAgeRestrictedModal();
      };
    }
  }

  closeAgeRestrictedModal() {
    const modal = document.querySelectorAll(".modal")[0];
    modal.remove();
    const modalBackdrop = document.querySelectorAll(".modal-backdrop")[0];
    modalBackdrop.remove();
  }
}

const WrappedComponent = (props: any) => {
  const navigate = useNavigate();
  return <ProductLocalItemCellWidget navigate={navigate} {...props} />;
};

export default WrappedComponent;
