import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import $ from "jquery";
import ReactPixel from "react-facebook-pixel";
import CheckoutResponse from "./Models/CheckoutResponse";
import BillingResponse from "./Models/BillingResponse";
import { Optional } from "../../CommonTypes";
import PointResponse from "../Point/Models/PointResponse";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { WithCreatorLinkProps, isResellerDomain } from "../../CommonComponents/Utils";
import { eventManager } from "../../Analytics/Events/Common";
import { BeginCheckoutEvent } from "../../Analytics/Events/GA4/BeginCheckoutEvent";
import { SelectPromotionEvent } from "../../Analytics/Events/GA4/SelectPromotionEvent";

export type SectionType = "PREORDER" | "READYSTOCK";

export default class CheckoutViewModel extends ViewModel {
  isLoggedIn: boolean = Boolean(localStorage.getItem("fpAuth"));
  bagItemIds: Optional<Array<string>> = JSON.parse(localStorage.getItem("selectedBagItem") || "[]").map((x: any) =>
    x.toString()
  );
  note: Optional<string> = localStorage.getItem("deliveryNotes");
  addressId: Optional<string> = localStorage.getItem("address_id");
  orderId: Optional<string> = localStorage.getItem("orderId");
  couponId: Optional<string> = localStorage.getItem("couponId");
  couponCode: Optional<string> = localStorage.getItem("couponCode");
  couponName: Optional<string> = localStorage.getItem("couponName");
  point: number = localStorage.getItem("point") ? parseInt(localStorage.getItem("point") || "0") : -1;
  includeInsurances: Set<string> = new Set();
  courierIds: string[] = [];
  addressDetails: Optional<string> = null;
  addressName: Optional<string> = null;
  addressZip: Optional<string> = null;
  addressEmail: Optional<string> = null;
  checkoutData: CheckoutResponse | null = null;
  billingData: Optional<{
    billingItems: {
      label: string;
      value: number;
    }[];
    total: number;
    pointRewardLabel: string;
  }> = null;
  isLoading: boolean = true;
  isLoadingButtonSubmit: boolean = false;
  errorModel: ShowErrorModel | null = null;
  errorMessage: string | null = null;
  message: string | null = null;
  isSuccess: boolean = false;
  showModal: boolean = false;
  snackbarMessage: ShowSnackbarModel | null = null;
  isEnableBtn: boolean = false;
  use_point: boolean = false;
  use_coupon: boolean = false;
  navBarTransparency = 1;
  showModalLogin: boolean = false;
  availablePoints?: number;
  shippingAddressId: Optional<string>;
  shouldShowLoginPopup: boolean = false;
  creatorLink?: string;
  systemInfo?: string[];
  promoInfo?: {
    title: string;
    description: string;
  }[];

  constructor(args: WithCreatorLinkProps) {
    super();
    this.creatorLink = args.creatorLink;
    const params = new URLSearchParams(window.location.search);
    this.addressDetails = params.get("recepientAddress");
    this.addressName = params.get("recepientName");
    this.addressId = params.get("locationId");
    this.addressEmail = params.get("recepientEmail");
    this.shippingAddressId = params.get("shippingAddressId");
    localStorage.setItem("address_id", this.addressId || "");

    if (this.couponCode) {
      this.loadCheckoutBilling();
    }
  }

  createPayButtonClickHandler(payload: {
    orderId: Optional<string>;
    point: Optional<number>;
    couponId: Optional<string>;
    couponCode: Optional<string>;
    shippingAddressId: Optional<string>;
    includeInsurances: Optional<string[]>;
    courierIds: Optional<string[]>;
    deliveryNotes: Optional<string>;
  }) {
    return () => {
      window.location.href = `${
        this.creatorLink ? (isResellerDomain() ? `/shop/${this.creatorLink}` : `/${this.creatorLink}`) : ""
      }/Payment?deliveryNotes=${payload.deliveryNotes}&courierIds=${
        payload.courierIds
      }&includeInsurances=${payload.includeInsurances?.join(",")}&orderId=${payload.orderId}&point=${
        payload.point
      }&couponId=${payload.couponId}&couponCode=${payload.couponCode}&shippingAddressId=${
        payload.shippingAddressId
      }&shippingAddressId=${payload.shippingAddressId}`;
    };
  }

  createInsuranceCheckHandler(type?: SectionType) {
    if (!type) return;
    return (isChecked?: boolean) => {
      this.emit(() => {
        if (isChecked) {
          this.includeInsurances.add(type);
        } else {
          this.includeInsurances.delete(type);
        }

        this.loadCheckoutBilling();
      });
    };
  }

  createCourierClickHandler(payload: {
    index: number;
    orderId: Optional<string>;
    shippingAddressId: Optional<string>;
    bagItemIds: Optional<string[]>;
  }) {
    return () => {
      if (!payload.bagItemIds) return;

      window.location.href = `${
        isResellerDomain() ? `/shop/${this.creatorLink}` : ""
      }/Checkout/CourierSelector?bagItemIds=${payload.bagItemIds.join(",")}&courierIndex=${payload.index}&orderId=${
        payload.orderId
      }&shippingAddressId=${payload.shippingAddressId}`;
    };
  }

  prepareCheckout() {
    this.emit(() => {
      this.isLoading = true;
      this.use_point = !(localStorage.getItem("point") === null);
      this.use_coupon = !(localStorage.getItem("use_coupon") === null);
    });

    //TRACK GOOGLE
    window.gtag("event", "begin_checkout");

    this.loadCheckoutDetail();
    this.loadAvailablePoints();
    if (Boolean(this.addressId) || Boolean(this.shippingAddressId) || this.point > -1 || Boolean(this.couponId)) {
      this.loadCheckoutBilling();
      if (this.couponCode || this.couponId) {
        eventManager.fire(SelectPromotionEvent);
      }
    }
  }

  openLoginPopup() {
    this.emit(() => {
      this.shouldShowLoginPopup = true;
    });
  }

  closeLoginPopup() {
    this.emit(() => {
      this.shouldShowLoginPopup = false;
    });
  }

  loadAvailablePoints() {
    if (localStorage.fpAuth) {
      this.emit(() => {
        this.isLoading = true;
      });
      requestAPI(Endpoints.point, {
        parameters: {
          fpAuth: localStorage.fpAuth,
        },
        onSuccess: (response: BaseResponse<PointResponse>) => {
          this.emit(() => {
            this.isLoading = false;
            this.availablePoints = response.data.point;
          });
        },
        onFailure: (error: string) => {
          this.emit(() => {
            this.isLoading = false;
            this.errorModel = {
              message: error,
              onRetryButtonTapped: () => this.prepareCheckout(),
            };
          });
        },
      });
    } else {
      this.availablePoints = 0;
    }
  }

  loadCheckoutDetail() {
    this.emit(() => {
      this.isLoading = true;
    });

    requestAPI(localStorage.fpAuth === undefined ? Endpoints.checkoutDetailDevice : Endpoints.checkoutDetail, {
      parameters: {
        fpAuth: localStorage.fpAuth,
        deviceId: localStorage.uuid,
        bagItemIdList: this.bagItemIds,
        continueWithoutPromo: true,
      },
      onSuccess: (response: BaseResponse<CheckoutResponse>) => {
        try {
          eventManager.add({
            value: response.data.billing.total,
            currency: "IDR",
            items: response.data.sectionItems
              .flatMap((x) => x.items)
              .map((product) => ({
                id: product.productId,
                name: product.itemDescription,
                price: {
                  discount: product.price.discount,
                  net: product.price.netPrice,
                },
                discount: product.price.discount,
                quantity: product.quantity,
                variant: product.attribute,
                brand: product.name,
              })),
          });
          eventManager.fire(BeginCheckoutEvent);
        } catch (err) {}
        this.emit(() => {
          this.checkoutData = response.data;
          this.billingData = response.data.billing;
          this.isLoading = false;
          this.systemInfo = response.data.systemInfo;
          this.promoInfo = response.data.promoInfo;
        });
      },
      onFailure: (error: string) => {
        console.log("2");
        this.emit(() => {
          this.isLoading = false;
          this.errorMessage = error;
        });
      },
    });
  }

  handleUnsetCoupon() {
    this.emit(() => {
      this.couponName = null;
      this.couponId = null;
      this.couponCode = null;
      this.errorMessage = "";
      localStorage.setItem("couponName", "");
      localStorage.setItem("couponId", "");
      localStorage.setItem("couponCode", "");
      localStorage.setItem("use_coupon", "0");
    });
    this.loadCheckoutBilling();
  }

  loadCheckoutBilling() {
    this.emit(() => {
      this.isLoading = true;
    });

    requestAPI(localStorage.fpAuth === undefined ? Endpoints.checkoutBillingDevice : Endpoints.checkoutBilling, {
      parameters: {
        fpAuth: localStorage.fpAuth,
        orderId: this.orderId,
        includeInsurances: Array.from(this.includeInsurances),
        bagItemIdList: this.bagItemIds,
        point: this.point,
        couponId: this.couponId || "",
        couponCode: this.couponCode || "",
        deviceId: localStorage.uuid,
      },
      onSuccess: (response: BaseResponse<BillingResponse>) => {
        this.emit(() => {
          this.isLoading = false;
          this.billingData = response.data.billing;
          this.systemInfo = response.data.systemInfo;
          this.promoInfo = response.data.promoInfo;
        });

        if (window.location.host === "m.thefepi.com") {
          //TRACK FB
          var data = {
            value: response.data.billing.total,
            currency: "IDR",
          };

          ReactPixel.track("AddPaymentInfo", data);
          ReactPixel.track("InitiateCheckout", data);
        }
      },
      onFailure: (error: string) => {
        this.emit(() => {
          this.point = 0;
          this.couponName = null;
          this.couponId = null;
          this.couponCode = null;
          this.errorMessage = "";
          localStorage.setItem("point", "0");
          localStorage.setItem("couponName", "");
          localStorage.setItem("couponId", "");
          localStorage.setItem("couponCode", "");
          localStorage.setItem("use_coupon", "0");
        });
        this.emit(() => {
          this.isLoading = false;
          this.errorMessage = error;
        });
      },
    });
  }

  onCloseModal() {
    this.emit(() => {
      this.showModal = false;
      this.showModalLogin = false;
    });
  }

  onUnsetCoupon() {
    this.emit(() => {
      this.use_coupon = false;
    });

    localStorage.removeItem("use_coupon");
    localStorage.removeItem("couponId");
    this.loadCheckoutBilling();
  }

  onUnsetPoint() {
    this.emit(() => {
      this.use_point = false;
    });
    localStorage.removeItem("point");
    this.loadCheckoutBilling();
  }

  onNoteChange(val: string) {
    this.emit(() => {
      this.note = val;
    });
  }

  createPointPageRedirectUrl() {
    return `/Point?orderSubtotal=${this.checkoutData?.billing.total}&maxPointUse=${
      (this.checkoutData?.maxPointUse || 0) > (this.checkoutData?.eligiblePoint || 0)
        ? this.checkoutData?.eligiblePoint
        : this.checkoutData?.maxPointUse
    }&maxPointPercentage=${this.checkoutData?.maxPointPercentage}&eligiblePoint=${this.checkoutData?.eligiblePoint}`;
  }

  onChangeInsurance() {
    if ($("#checkbox_insurance").prop("checked")) {
      localStorage.setItem("includeShippingInsurance", "true");
      this.loadCheckoutBilling();
    } else {
      localStorage.setItem("includeShippingInsurance", "false");
      this.loadCheckoutBilling();
    }
  }

  onSnackbarIsFinished() {
    this.emit(() => {
      this.errorMessage = null;
    });
  }

  onshowModalLoginPopup() {
    this.emit(() => {
      this.showModalLogin = true;
    });
  }
}
