import React from "react";

export type RatingInputWidgetProps = {
  max?: number;
  rating?: number;
  onStarClick?: (index: number) => void;
  width?: number;
  height?: number;
};

function RatingInputWidget(props: RatingInputWidgetProps) {
  const { max = 5, rating = 0, onStarClick, width = 52, height = 52 } = props;

  return (
    <>
      {Array.from({ length: max }).map((_, index) => (
        <button
          onClick={() => {
            onStarClick?.(index);
          }}
        >
          <svg
            style={{
              width,
              height,
            }}
            viewBox="0 0 50 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25 39.6094L10.9041 46.9229C10.1728 47.3023 9.32215 46.6843 9.45702 45.8716L12.0567 30.2055L0.745258 19.0595C0.158443 18.4813 0.483359 17.4813 1.29798 17.3584L17.0006 14.9898L24.1057 0.787639C24.4743 0.0508614 25.5257 0.0508585 25.8943 0.787636L32.9994 14.9898L48.702 17.3584C49.5166 17.4813 49.8416 18.4813 49.2547 19.0595L37.9433 30.2055L40.543 45.8716C40.6778 46.6843 39.8272 47.3023 39.0959 46.9229L25 39.6094Z"
              fill={index + 1 > rating ? "#E4E4E4" : "#894CF5"}
            />
          </svg>
        </button>
      ))}
    </>
  );
}

export default RatingInputWidget;
