import "./CourierSelector.css";

import ic_info from "../../Assets/Images/ic_info_grey.svg";

import ViewController from "../../CommonComponents/Base/ViewController";
import Scaffold from "../../CommonComponents/UI/Scaffold/Scaffold";
import CourierSelectorViewModel from "./CourierSelectorViewModel";
import CourierSelectorItemCell from "./Widgets/CourierSelectorItemCell";
import ButtonWidget from "../../CommonComponents/UI/ButtonWidget/ButtonWidget";
import SizedDiv from "../../CommonComponents/UI/SizedDiv";
import { WithCreatorLinkProps, getLocationQueries, isResellerDomain, withSellerId } from "../../CommonComponents/Utils";
import mixpanel from "mixpanel-browser";

class CourierSelectorViewController extends ViewController<WithCreatorLinkProps, CourierSelectorViewModel> {
    constructor(props: WithCreatorLinkProps) {
        const { orderId, shippingAddressId, bagItemIds } = getLocationQueries();

        super(props, new CourierSelectorViewModel({
            orderId,
            shippingAddressId,
            bagItemIds: (bagItemIds ?? "").split(","),
            creatorLink: props.creatorLink
        }));

        //MIXPANEL TRACKING: semo_page_view
        if (isResellerDomain()) {
          mixpanel.track("semo_page_view", {
            page_name: "SelectCourier",
          });
        }
    }

    componentDidMount() {
        this.viewModel.getListOfCourier();
    }

    render() {
        const response = this.viewModel.response;

        return <div id="CourierSelectorVC">
            <Scaffold 
                backgroundColor="var(--washoutGrey)"
                isShowLoading={this.viewModel.isLoading}
                topBar={
                    <>
                        <div className="FlexFillSpacing" style={{
                            display: "flex",
                            alignItems: "center",
                            paddingLeft: "8px",
                            zIndex: "20",
                            fontSize: "16px",
                            fontWeight: "500"
                        }}>
                            Select Courier
                        </div>
                    </>
                }
                showErrorModel={this.viewModel.errorMessage ? {
                    message: this.viewModel.errorMessage,
                    onRetryButtonTapped: () => this.viewModel.getListOfCourier()
                } : undefined}
                showSnackbar={this.viewModel.errorSnackbarMessage}
            >
                {
                    response && <>
                        <div id="CourierSelectorMainContainer">
                            {
                                response.info &&
                                <div id="CourierSelectorImportantMessage">
                                    <img src={ic_info} alt="" />
                                    <p style={{color: "var(--mediumGrey)", fontSize: "12px"}}>
                                        { response.info }
                                    </p>
                                </div>
                            }
                            {
                                response.infoDiscounts.map((model) => {
                                    return <div id="CourierSelectorInfoContainer">
                                        <p style={{color: "var(--blueGrey)", fontSize: "12px", fontWeight: "bold"}}>{model.title}</p>
                                        <p style={{color: "var(--blueGrey)", fontSize: "12px"}}>{model.description}</p>
                                    </div>;
                                })
                            }
                            {
                                response.courierList.map((listModel, listIndex) => {
                                    return <>
                                        <p style={{fontSize: "14px", fontWeight: "500", marginBottom: "8px"}}>{ listModel.type }</p>
                                        {
                                            listModel.items.map((itemModel, itemIndex) => {
                                                return <CourierSelectorItemCell 
                                                    isSelected={this.viewModel.selectedListIndex === listIndex && this.viewModel.selectedItemIndex === itemIndex}
                                                    courierImageUrl={itemModel.imageUrl}
                                                    price={itemModel.price}
                                                    title={itemModel.title}
                                                    onClick={() => {
                                                        this.viewModel.emit(() => {
                                                            this.viewModel.selectedListIndex = listIndex;
                                                            this.viewModel.selectedItemIndex = itemIndex;
                                                        });
                                                    }}
                                                />;
                                            })
                                        }
                                        {
                                            listModel.info &&
                                            <p style={{fontSize: "11px", color: "#999999", paddingBottom: "8px"}}>{listModel.info}</p>
                                        }
                                        <SizedDiv height={4} />
                                    </>;
                                })
                            }
                        </div>
                        <div id="CourierSelectorBottomContainer">
                            <ButtonWidget action={() => {
                                this.viewModel.selectCourier();
                            }}>
                                Confirm
                            </ButtonWidget>
                        </div>
                    </>
                }
            </Scaffold>
        </div>
    }
}

export default withSellerId(CourierSelectorViewController)