import React from "react";
import "./CheckoutItemSection.css";
import { convertToRupiah } from "../../../../CommonComponents/Utils";
import CheckoutBillingWidget from "../CheckoutBillingWidget/CheckoutBillingWidget";
import CheckoutCourierWidget from "../CheckoutCourierWidget/CheckoutCourierWidget";
import SizedDiv from "../../../../CommonComponents/UI/SizedDiv";
import CheckoutInsuranceWidget from "../CheckoutInsuranceWidget/CheckoutInsuranceWidget";
import Typography from "../Typography/Typography";
import { Optional } from "../../../../CommonTypes";

export interface CheckoutItemSectionProps {
  type?: "PREORDER" | "READYSTOCK";
  billingTotal?: number;
  billingItems?: Array<{
    label: string;
    value: number;
  }>;
  items?: Array<{
    title?: string;
    imageUrl?: string;
    name?: string;
    price?: {
      netPrice?: number;
      discount?: number;
      realPrice?: number;
    };
    attribute?: string;
    quantity?: number;
    weight?: string;
    itemDescription?: string;
  }>;
  courierImageUrl?: string;
  courierPrice?: number;
  courierName?: string;
  isMixedPurchase?: boolean;
  shouldUseShippingInsurance?: boolean;
  onShouldUseShippingInsuranceChange?: (
    shouldUseShippingInsurance: boolean
  ) => void;
  insuranceLabel?: string;
  isInsuranceChecked?: boolean;
  shippingAddressId?: Optional<string>;
  onIsInsuranceCheckedChange?: (isChecked?: boolean) => void;
  onCourierClick?: () => void;
}

export default class CheckoutItemSection extends React.Component<CheckoutItemSectionProps> {
  titleForSectionType(type?: "PREORDER" | "READYSTOCK") {
    switch (type) {
      case "READYSTOCK":
        return "Ready Stock Items";
      case "PREORDER":
        return "Pre-order Items (7-14 Days)";
      default:
        return "Items";
    }
  }

  render() {
    const {
      items = [],
      type,
      isMixedPurchase = false,
      billingItems = [],
      billingTotal,
      courierImageUrl,
      courierName,
      courierPrice,
      shippingAddressId,
      onIsInsuranceCheckedChange,
      insuranceLabel,
      isInsuranceChecked,    
      onCourierClick,
    } = this.props;

    return (
      <div id="CheckoutItemSection" className="sectionContainer">
        <Typography.Title mb={16}>
          {this.titleForSectionType(type)}
        </Typography.Title>
        {items?.map((item, index) => (
          <div className="itemContainer" key={index}>
            <div className="itemImageContainer">
              <img src={item.imageUrl} className="itemImage" alt={item.name} />
            </div>
            <div className="itemDetailsContainer">
              <p className="itemTitle">{item.name}</p>              
              <p className="itemDescription">{item.itemDescription}</p>
               {item.price?.discount ?
                    <p className="itemPrice">
                        <span className="itemCompareAtPrice">{convertToRupiah(item.price?.realPrice)}</span><span className="itemDiscountPercentage">{item.price?.discount}%</span><br></br>
                        {convertToRupiah(item.price?.netPrice)}
                    </p>
                :
                    <p className="itemPrice">{convertToRupiah(item.price?.netPrice)}</p>
                }

              <p className="itemAttribute">{item.attribute}</p>
              <p className="itemWeight">
                <span>{item.weight}</span>
              </p>
            </div>
          </div>
        ))}
        <SizedDiv height={4} />
        {!isMixedPurchase && <Typography.Title mb={12}>Shipping</Typography.Title>}
        <CheckoutCourierWidget
          name={courierName}
          price={courierPrice}
          courierImageUrl={courierImageUrl}
          shippingAddressId={shippingAddressId}
          onClick={onCourierClick}
        />
        <SizedDiv height={8} />
        {shippingAddressId && <CheckoutInsuranceWidget
          isChecked={isInsuranceChecked}
          label={insuranceLabel}
          onIsCheckedChange={onIsInsuranceCheckedChange}
        />}
        <SizedDiv height={16} />
        <CheckoutBillingWidget
          billing={billingItems}
          total={billingTotal}
        />
      </div>
    );
  }
}
