import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import LabelItemModel from "../../CommonComponents/Models/LabelItemModel";
import { ShowErrorModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { getPreloadProps } from "../../CommonComponents/Utils";
import {
  OrderAndDeliveryResponseModel,
  OrderModel,
  OrderType,
} from "./Models/OrderAndDeliveryResponseModel";
import TrackingResponse from "./Models/TrackingResponse";

export default class OrderAndDeliveryViewModel extends ViewModel {
  isCreator: boolean = false;
  creatorId: string = "";
  isLoading: boolean = false;
  errorMessage: ShowErrorModel | null = null;
  activeCategoryId?: string;
  tabs: LabelItemModel[] = [];
  isLoadingTracking?: boolean = false;
  trackingData?: TrackingResponse;
  shouldShowTrackingModal?: boolean = false;
  totalItems: number = 0;
  orders: Array<OrderModel & { isReAddingToCart?: boolean }> = [];

  constructor() {
    super();
  }

  changeCategoryId(id: string) {
    this.emit(() => {
      this.orders = [];
      this.activeCategoryId = id;
    });
    this.loadList();
  }

  openTrackingModal() {
    this.emit(() => {
      this.shouldShowTrackingModal = true;
    });
  }

  closeTrackingModal() {
    this.emit(() => {
      this.shouldShowTrackingModal = false;
    });
  }

  loadTracking(orderId?: string) {
    if (!orderId) return;

    this.emit(() => {
      this.isLoadingTracking = true;
    });

    requestAPI(Endpoints.trackMyOrder, {
      parameters: {
        fpAuth: localStorage.fpAuth,
        orderId: orderId,
      },
      onSuccess: (response: BaseResponse<TrackingResponse>) => {
        this.emit(() => {
          this.isLoadingTracking = false;
          this.trackingData = response.data;
        });
      },
      onFailure: (error: string) => {
        this.emit(() => {
          this.isLoadingTracking = false;
          this.errorMessage = {
            message: error,
            onRetryButtonTapped: () => {},
          };
        });
      },
    });
  }

  reAddOrderToCart(orderId: string) {
    const order = this.orders.find((o) => o.orderId === orderId);

    if (!order) return;

    this.emit(() => {
      this.orders = this.orders.map((o) => {
        if (o.orderId === orderId) return { ...o, isReAddingToCart: true };
        return o;
      });
    });

    requestAPI(Endpoints.reorder, {
      onFailure: (error, errorCode) => {
        this.emit(() => {
          this.orders = this.orders.map((o) => {
            if (o.orderId === orderId) return { ...o, isReAddingToCart: false };
            return o;
          });
        });
      },
      onSuccess: () => {
        this.emit(() => {
          this.orders = this.orders.map((o) => {
            if (o.orderId === orderId) return { ...o, isReAddingToCart: false };
            return o;
          });
        });
      },
      parameters: {
        fpAuth: localStorage.getItem("fpAuth"),
        bagItemIds: order.bagItemIds || [],
      },
    });
  }

  onEndReached() {
    if (this.orders.length < this.totalItems) {
      this.loadList();
    }
  }

  loadList() {
    // this.emit(() => {
    //   this.isLoading = true;
    // });
    requestAPI(Endpoints.orderAndDelivery, {
      parameters: {
        categoryId: this.activeCategoryId || null,
        startIndex: this.orders.length - 1 < 0 ? 0 : this.orders.length - 1,
        limit: 24,
      },
      onFailure: () => {
        // this.emit(() => {
        //   this.isLoading = false;
        // });
      },
      onSuccess: (response: BaseResponse<OrderAndDeliveryResponseModel>) => {
        this.emit(() => {
          // this.isLoading = false;
          this.totalItems = response.data.totalItems || 0;
          this.tabs = response.data.categories.map((c) => ({
            id: c.id,
            label: c.label,
            shouldShowPing: c.isThereNew
          }));
          this.orders = this.orders.concat(
            response.data.orders.map((o) => ({
              ...o,
              isReAddingToCart: false,
            }))
          );
        });
      },
    });
  }

  loadPreloadingProps() {
    this.emit(() => {
      this.isLoading = true;
    });
    getPreloadProps({
      onSuccess: (isCreator, creatorId, numberInCart) => {
        this.emit(() => {
          this.isLoading = false;
          this.isCreator = isCreator;
          this.creatorId = creatorId;
        });
      },
      onFailure: (error) => {
        this.emit(() => {
          this.isLoading = false;
          this.errorMessage = {
            message: error,
            onRetryButtonTapped: () => this.loadPreloadingProps(),
          };
        });
      },
    });
  }
}
