import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { AllReviewsResponse } from "./Widgets/Models/AllReviewsResponse";

export default class ReviewListViewModel extends ViewModel {
  isLoading?: boolean = false;
  allReviewsResult?: AllReviewsResponse;
  totalReviews: number = 0;
  productId?: string;

  constructor(args: { productId?: string }) {
    super();
    Object.assign(this, args);
    this.loadReviews();
  }

  loadReviews() {
    if (this.totalReviews > (this.allReviewsResult?.reviews.length || 0)) {
      return;
    }
    this.emit(() => {
      this.isLoading = true;
    });
    requestAPI(Endpoints.reviews, {
      onFailure: () => {
        this.emit(() => {
          this.isLoading = false;
        });
      },
      onSuccess: (response: BaseResponse<AllReviewsResponse>) => {
        this.emit(() => {
          this.isLoading = false;
          this.totalReviews = response.data.totalReviews;
          this.allReviewsResult = response.data;
        });
      },
      parameters: {
        productId: this.productId,
        limit: 10,
        startIndex: this.allReviewsResult?.reviews.length || 0,
      },
    });
  }
}
