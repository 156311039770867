// kenapa dibikin ini?
// karena satu analytics event ada yang perlu data dari beberapa page
// nah karena project ini navigasi antar page nya ga pake react-router-dom tapi kebanyakan pake tag anchor html
// class ini buat nyimpen sementara data dari page sebelumnya

import { AnalyticEvent } from "./AnalyticEvent";
import { ItemEventEntity } from "./Common";
import { EventState } from "./EventState";

export class EventManager {
  key = "__EVENT_MANAGER_STATE__";
  debug = process.env.REACT_ENV !== "production";

  flush() {
    if (window) {
      window.localStorage.removeItem(this.key);
      this.set({});
    }
  }

  get() {
    if (window) {
      return JSON.parse(
        window.localStorage.getItem(this.key) || "{}"
      ) as EventState;
    }
  }

  add(args: EventState) {
    try {
      const currentState = JSON.parse(
        window.localStorage.getItem(this.key) || "{}"
      );
      const nextState = Object.assign(currentState, { ...args });
      if (this.debug) {
        console.log({ incomingState: args, currentState, nextState });
      }
      this.set(nextState);
    } catch (err) {}
  }

  set(args: EventState) {
    if (window) {
      window.localStorage.setItem(this.key, JSON.stringify(args));
    }
  }

  fire(Event: any) {
    try {
      const e = new Event(this.get()) as AnalyticEvent;
      e.exec();
    } catch (err) {}
  }
}
