import React from "react";
import "../StoreSectionFeed.css";
import StoreSectionFeedModel from "../../Models/StoreSectionFeedModel";
import { mixpanelManager } from "../../../../Analytics/Mixpanel/Common";
import { standarizeThefepiLink } from "../../../../CommonComponents/Utils";
import { ImageWidget } from "../../../../CommonComponents/UI/ImageWidget/ImageWidget";

export type StoreGridIconListWidgetWithTitleAndSeeAllProps = {
  model?: StoreSectionFeedModel;
  onGridItemClick?: (url?: string) => void;
  title?: string;
  subtitle?: string;
};

const StoreGridIconListWidgetWithTitleAndSeeAll: React.FC<StoreGridIconListWidgetWithTitleAndSeeAllProps> = ({
  model,
  onGridItemClick,
}) => {
  if (!model) return null;

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
          justifyContent: "left",
          alignItems: "center",
          padding: "16px 16px 0",
        }}>
        <div id="StoreGridIconListWidgetWithTitleAndSeeAllTitle">
          Top Brands
        </div>
      </div>

      <div id="StoreGridIconListWidgetWithTitleAndSeeAll" className="GridContainer">
        {model.contents.icons.map((icon, index) => (
          <button
            onClick={() => {
              mixpanelManager.storeFeedClick({
                type: "button",
                title: icon.label,
                group_name:
                  window.location.href.match(/Store\/([a-zA-Z]+)/)?.[1] || "HOME",
                identifier: model?.identifier || "",
                screen_name: "Store",
              });
              onGridItemClick?.(standarizeThefepiLink(icon.deeplink));
            }}
            key={`button_${index}`}
            className="GridItemContainer"
          >
            <div className="GridItemImage">
              <ImageWidget imageUrl={icon.imageUrl} />
            </div>
            <div className="GridItemLabel">{icon.label}</div>
          </button>
        ))}

      </div>
    </div>
  );
};

export default StoreGridIconListWidgetWithTitleAndSeeAll;
