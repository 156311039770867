import React from "react";
import "./Typography.css";

export interface TitleProps {
  // margin bottom or bottom spacing
  mb?: number;
  children?: React.ReactNode;
}

export class Title extends React.Component<TitleProps> {
  render() {
    const { mb, ...rest } = this.props;

    return (
      <p className="TypographyTitle" style={{ marginBottom: mb || 0 }} {...rest} />
    );
  }
}

const Typography = {
  Title,
};

export default Typography;
