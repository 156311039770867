import { eventManager } from "../../Analytics/Events/Common";
import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import BaseViewModel from "../../CommonComponents/Base/BaseViewModel";
import ProductSummaryItemModel from "../../CommonComponents/Models/ProductSummaryItemModel";
import PopUpWidgetModel from "../../CommonComponents/UI/PopUp/Models/PopUpWidgetModel";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { ShareUrlModel } from "../../CommonComponents/UI/ShareUrlPopUp/Models/ShareUrlModel";
import { getPreloadProps, productTypesForBadgeUrls } from "../../CommonComponents/Utils";
import { ProductBadgeType } from "../../CommonTypes";
import { CreatorContentCellModel, CreatorContentsResponse } from "./Models/CreatorContentsResponse";
import { CreatorProductsResponse } from "./Models/CreatorProductsResponse";
import CreatorSpaceResponse from "./Models/CreatorSpaceResponse";

type ItemsType = "content" | "product";

export default class CreatorSpaceViewModel extends BaseViewModel {
    id: string;
    isCreator: boolean = false;
    creatorId: string = "";
    numberInCart: number = 0;
    isPreloadingLoaded: boolean = false;
    navBarTransparency: number = 0;
    isShowFloatingCategoryButtons: boolean = false;
    isLoading: boolean = true;
    detail: CreatorSpaceResponse | null = null;
    errorMessage: ShowErrorModel | null = null;
    snackbarMessage: ShowSnackbarModel | null = null;
    contents: CreatorContentCellModel[] | null = null;
    products: ProductSummaryItemModel[] | null = null;
    coverImageIndex: number = 0;
    showingItemsType: ItemsType = "content";
    shareUrlModel: ShareUrlModel | null = null;
    popUpModel: PopUpWidgetModel | null = null;
    badgeUrlsMap?: {[key in ProductBadgeType]: string};
    totalProducts: number = 0;

    constructor(id: string) {
        super({
            key: 'CreatorSpaceViewModel'
        });
        this.id = id;
        this.refresh();
    }

    changeNavBarTransparency(value: number) {
        this.updateState({navBarTransparency: value})
    }

    showFloatingCategoryButton(isShowing: boolean) {
        this.updateState({isShowFloatingCategoryButtons: isShowing});
    }

    loadPreloadingProps() {
        const url = window.location.href;
        localStorage.setItem("sellerHome", url)
        const username = url.substring(url.lastIndexOf('/') + 1);
        localStorage.setItem("username", username);
        
        this.updateState({isLoading: true});

        getPreloadProps({
            onSuccess: (isCreator, creatorId, numberInCart,x) => {
              
              this.badgeUrlsMap = x
                this.updateState({
                    isCreator: isCreator,
                    creatorId: creatorId,
                    numberInCart: numberInCart,
                    isPreloadingLoaded: true
                })

                this.loadSpaceDetail();
            },
            onFailure: (error) => {
                this.updateState({
                    isLoading: false,
                    errorMessage: {
                        message: error,
                        onRetryButtonTapped: () => this.loadPreloadingProps()
                    },
                });
            }
        });
    }

    loadSpaceDetail() {
        this.updateState({isLoading: true});

        requestAPI(Endpoints.spaceDetail, {
            parameters: {
                sellerId: this.id
            },
            onSuccess: (response: BaseResponse<CreatorSpaceResponse>) => {
                this.updateState({detail: response.data});
                this.loadContents();
            },
            onFailure: (error: string) => {
                this.updateState({
                    errorMessage: {
                        message: error,
                        onRetryButtonTapped: () => this.loadSpaceDetail()
                    },
                    isLoading: false
                });
            }
        })
    }

    loadContents() {
        this.updateState({isLoading: true});

        requestAPI(Endpoints.spaceContents, {
            parameters: {
                sellerId: this.id,
                startIndex: this.contents?.length ?? 0,
                limit: 24
            },
            onSuccess: (response: BaseResponse<CreatorContentsResponse>) => {
                this.updateState({contents: response.data.items, isLoading: !this.products});

                if (!this.products) {
                    this.loadProducts();
                }
            },
            onFailure: (error: string) => {
                this.updateState({
                    errorMessage: {
                        message: error,
                        onRetryButtonTapped: () => this.loadContents()
                    },
                    isLoading: false
                });
            }
        })
    }

    loadProducts() {
        this.updateState({isLoading: true});

        requestAPI(Endpoints.spaceProducts, {
            parameters: {
                sellerId: this.id,
                startIndex: this.products?.length ?? 0,
                limit: 10
            },
            onSuccess: (response: BaseResponse<CreatorProductsResponse>) => {
   
                this.updateState({ products: [...(this.products || []), ...response.data.items.map(i => ({...i, badgeUrls: productTypesForBadgeUrls(i.badgeTypes, this.badgeUrlsMap || {})}))], isLoading: false, totalProducts: response.data.totalOfItems});
            },
            onFailure: (error: string) => {
                this.updateState({
                    errorMessage: {
                        message: error,
                        onRetryButtonTapped: () => this.loadProducts()
                    },
                    isLoading: false
                });
            }
        })
    }

    onShareLink() {
        let splitted = window.location.href.split("/") || []
        eventManager.flush();
        eventManager.add({
          content_type: "user",
          item_id: "user_" + parseInt(splitted[splitted.length-1])
        })
        this.updateState({shareUrlModel: new ShareUrlModel({urlString: this.detail?.shareUrl ?? ""})});
    }

    followCreator(isFollowed: boolean) {
        if (this.detail) {
            const newDetail = this.detail;

            newDetail.sellerProfile.isFollowed = isFollowed;

            this.updateState({detail: newDetail});
        }

        if (!localStorage.fpAuth) {
            this.updateState({
                popUpModel: PopUpWidgetModel.loginRequestPopUpModel
            });

            return;
        }
        
        requestAPI(Endpoints.followCreator, {
            parameters: {
                sellerId: this.id,
                isFollowed: isFollowed
            },
            onSuccess: (response: {}) => {
                // TODO:
            },
            onFailure: (error: string) => {
                // TODO:
            }
        })
    }

    changeItemsCategory(value: ItemsType) {
        this.updateState({showingItemsType: value})
    }

    // Mark: - BaseViewModel

    override copy({
        isCreator = this.isCreator,
        creatorId = this.creatorId,
        numberInCart = this.numberInCart,
        isPreloadingLoaded = this.isPreloadingLoaded,
        navBarTransparency = this.navBarTransparency,
        isShowFloatingCategoryButtons = this.isShowFloatingCategoryButtons,
        isLoading = this.isLoading,
        detail = this.detail,
        errorMessage = this.errorMessage,
        snackbarMessage = this.snackbarMessage,
        contents = this.contents,
        products = this.products,
        coverImageIndex = this.coverImageIndex,
        showingItemsType = this.showingItemsType,
        shareUrlModel = this.shareUrlModel,
        totalProducts = this.totalProducts
    }): this {
        this.isCreator = isCreator;
        this.creatorId = creatorId;
        this.totalProducts = totalProducts;
        this.numberInCart = numberInCart;
        this.isPreloadingLoaded = isPreloadingLoaded;
        this.navBarTransparency = navBarTransparency;
        this.isShowFloatingCategoryButtons = isShowFloatingCategoryButtons;
        this.isLoading = isLoading;
        this.detail = detail;
        this.errorMessage = errorMessage;
        this.snackbarMessage = snackbarMessage;
        this.contents = contents;
        this.products = products;
        this.coverImageIndex = coverImageIndex;
        this.showingItemsType = showingItemsType;
        this.shareUrlModel = shareUrlModel;

        return this;
    }
}