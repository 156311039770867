import React from "react";
import ProductSummaryItemModel from "../../../../CommonComponents/Models/ProductSummaryItemModel";
import SearchBrandModel from "../../../../CommonComponents/Models/SearchBrandModel";
import ic_sort from "../../../../Assets/Images/ic_sort.svg";
import "./SearchResultProductWidget.css";
import ProductSummaryItemCellWidget from "../../../../CommonComponents/UI/Cells/ProductSummaryItemCellWidget";

export type SearchResultProductWidgetProps = {
  brands?: SearchBrandModel[];
  products?: ProductSummaryItemModel[];
  shouldShowSeeMoreBrandsLink?: boolean;
  sortId?: string;
  sortLabel?: string;
  onSortButtonClick?: () => void;
  seeMoreHref?: string;
  productsCardWidth?: number;
};

export default class SearchResultProductWidget extends React.Component<SearchResultProductWidgetProps> {

  render() {
    return (
      <div id="SearchResultProductWidgetContainer">
        <div id="SearchResultProductWidgetSortSection">
          <button
            id="SearchResultProductWidgetSortButton"
            onClick={() => this.props.onSortButtonClick?.()}
          >
            <p id="SearchResultProductWidgetSortLabel">
              {this.props.sortLabel}
            </p>
            <img src={ic_sort} alt="" />
          </button>
        </div>
        <div id="SearchResultProductWidgetBrandsSection">
          {this.props.brands?.map((b) => (
            <a
              href={`/Brand/${b.id}`}
              id="SearchResultProductWidgetBrandListItem"
            >
              {b.imageUrl ? (
                <img
                  src={b.imageUrl}
                  id="BrandListsViewControllerBrandListItemImage"
                  alt={``}
                />
              ) : (
                <div id="BrandListsViewControllerBrandListItemImage" style={{position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 600, fontSize: 30, border: '0.5px solid #C0C0C0'}}>
                  {b.name.charAt(0)}
                </div>
              )}
              <p id="SearchResultProductWidgetBrandsName">{b.name}</p>
            </a>
          ))}
          {this.props.shouldShowSeeMoreBrandsLink && (
            <a
              id="SearchResultProductWidgetBrandsShowMore"
              href={this.props.seeMoreHref}
            >
              See all brands
            </a>
          )}
        </div>
        {this.props.shouldShowSeeMoreBrandsLink && (
          <div id="SearchResultProductWidgetDivider" />
        )}
        <div id="SearchResultContentWidgetProductsContainer">

          {this.props.products?.map((p) => (
            <ProductSummaryItemCellWidget
              cellWidth={this.props.productsCardWidth || 0}
              badgeUrls={p.badgeUrls}
              model={{
                badgeTypes: p.badgeTypes,

                id: p.id,
                imageUrl: p.imageUrl,
                price: p.price,
                title: p.title,
                ageRestricted: p.ageRestricted,
                brand: p.brand,
                footnote: p.footnote,
                isSoldOut: p.isSoldOut,
                ribbonDetail: p.ribbonDetail,
                rank: p.rank,
                videoUrl: p.videoUrl,
                videoImageThumbnailUrl: p.videoImageThumbnailUrl,
                thumbnailLink: p.thumbnailLink
              }}
            />
          ))}
        </div>
      </div>
    );
  }
}
