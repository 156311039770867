import React from "react";
import "./CouponSheet.css";
import ic_back from "../../../Assets/Images/ic_back.svg";
import ButtonWidget from "../../../CommonComponents/UI/ButtonWidget/OutlineButtonWidget";
import { ButtonWidgetType } from "../../../CommonComponents/UI/ButtonWidget/ButtonWidget";

export type CouponSheetProps = {
  title?: string;
  description?: string;
  tnc?: string[];
  isVisible?: boolean;
  onBackClick?: () => void;
  onCloseClick?: () => void;
  onUnderlayClick?: () => void;
  onSelectClick?: () => void;
  hideSelectButton?: boolean;
};

export default class CouponSheet extends React.Component<CouponSheetProps> {
  render() {
    return (
      <div
        id="CouponSheetContainer"
        style={{ pointerEvents: this.props.isVisible ? "auto" : "none" }}
      >
        <div id={this.props.isVisible ? "CouponSheetActive" : "CouponSheet"}>
          <div id="CouponSheetNavigation">
            <button
              id="CouponSheetBackHandle"
              onClick={() => this.props.onBackClick?.()}
            >
              <img alt="back" src={ic_back}></img>
            </button>
            <div id="CouponSheetNavigationTitle">Detail</div>
            <button
              id="CouponSheetCloseHandle"
              onClick={() => this.props.onCloseClick?.()}
            >
              Close
            </button>
          </div>
          <div id="CouponSheetBody" style={{marginBottom: this.props.hideSelectButton ? "0" : "72px"}}>
            <div id="CouponSheetTitle">{this.props.title}</div>
            <div id="CouponSheetDescription">{this.props.description}</div>
            <div id="CouponSheetDescription">
              <ul>
                {this.props.tnc?.map((term: string) => (
                  <li>{term}</li>
                ))}
              </ul>
            </div>
          </div>
          {!this.props.hideSelectButton && 
          <div id="CouponSheetFooterSection">
            <div className="col-12 text-center">
              <ButtonWidget
                buttonType={ButtonWidgetType.primary}
                // isLoading={this.viewModel.isLoadingButtonSubmit}
                isEnabled={
                  localStorage.getItem("selectedBagItem") === null
                    ? false
                    : true
                }
                width="calc(100% - 6px)"
                action={() => {
                  // this.Apply();
                  this.props.onSelectClick?.();
                }}
              >
                Select
              </ButtonWidget>
            </div>
          </div> }
        </div>
        {this.props.isVisible && (
          <div
            id="ProductDetailVariantPickerSheetUnderlay"
            onClick={() => {
              if (!this.props.isVisible) return;
              this.props.onUnderlayClick?.();
            }}
          />
        )}
      </div>
    );
  }
}
