import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowErrorModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { getPreloadProps } from "../../CommonComponents/Utils";
import { ShowCaseDetailCoverModel } from "../Detail/Models/ShowCaseDetailCoverModel";
import { ShowCaseHomeCoverModel } from "./Models/ShowCaseHomeCoverModel";
import { ShowCaseHomeResponse } from "./Models/ShowCaseHomeResponse";

export default class ShowCaseHomeViewModel extends ViewModel {
    isCreator: boolean = false;
    creatorId: string = "";
    numberInCart: number = 0;
    response?: ShowCaseHomeResponse;
    isLoadingPreloading: boolean = false;
    errorMessage?: ShowErrorModel;
    isLoadmoreFeeds: boolean = false;

    limit: number = 10

    cover: ShowCaseHomeCoverModel = {
        covers: []
    }
    
    feeds: ShowCaseDetailCoverModel[] = []
    totalOfFeeds: number = 0
    
    loadPreloadingProps() {
        this.emit(() => { 
            this.isLoadingPreloading = true;
        });

        getPreloadProps({
            onSuccess: (isCreator, creatorId, numberInCart) => {
                this.emit(() => {
                    this.isCreator = isCreator;
                    this.creatorId = creatorId;
                    this.numberInCart = numberInCart;
                });

                this.loadFeeds()
            },
            onFailure: error => {
                this.emit(() => {
                    this.isLoadingPreloading = false;
                    this.errorMessage = {
                        message: error,
                        onRetryButtonTapped: () => this.loadPreloadingProps(),
                    };
                });
            },
        });
    }

    loadFeeds() {
        this.emit(() => this.isLoadingPreloading = true);

        requestAPI(Endpoints.showCaseHome, {
            parameters: {
                startIndex: 0,
                limit: this.limit
            },
            onSuccess: (response: BaseResponse<ShowCaseHomeResponse>) => {
                var covers: ShowCaseDetailCoverModel[] = []

                response.data.highlightedFeeds.forEach(element => {
                    // TODO: Check it later
                    // element.coverRatio = 1.0
                    covers.push(element);
                });

                const responseCover: ShowCaseHomeCoverModel = {
                    covers: covers
                };

                this.emit(() => {
                    this.isLoadingPreloading = false;
                    this.response = response.data;
                    this.cover = responseCover;
                    this.feeds = this.feeds.concat(response.data.feeds);
                    this.totalOfFeeds = response.data.totalOfFeeds;
                })
            },
            onFailure: (error) => {
                this.emit(() => {
                    this.isLoadingPreloading = false;
                    this.errorMessage = {
                        message: error, 
                        onRetryButtonTapped: () => this.loadFeeds()
                    }
                });
            }
        })
    }

    loadMoreFeeds() {

        this.emit(() => {
            this.isLoadmoreFeeds = true;
            this.errorMessage = undefined;
        });

        requestAPI(Endpoints.showCaseHome, {
            parameters: {
                startIndex: this.feeds.length,
                limit: this.limit
            },
            onSuccess: (response: BaseResponse<ShowCaseHomeResponse>) => {

                this.emit(() => {
                    this.isLoadmoreFeeds = false;
                    this.response = response.data;
                    this.feeds = this.feeds.concat(response.data.feeds)
                })
            },
            onFailure: (error) => {
                this.emit(() => {
                    this.isLoadingPreloading = false;
                    this.errorMessage = {
                        message: error, 
                        onRetryButtonTapped: () => this.loadMoreFeeds()
                    }
                });
            }
        })
    }
}