import React from "react";

import "./IndexIndicator.css";

type IndexIndicatorProps = {
    showingIndex: number;
    totalOfIndices: number;
    color?: string;
}

export class IndexIndicator extends React.Component<IndexIndicatorProps, {}> {
    render() {
        const currentIndex = this.props.showingIndex;
        const totalOfIndices = this.props.totalOfIndices;
        
        return (
            <div id="IndexIndicator">
                {
                    Array.from({length: totalOfIndices > 1 ? totalOfIndices : 0}).map((_, index) => {
                        return <div 
                            className={index === currentIndex ? "ActiveIndicator" : "UnactiveIndicator"} 
                            key={index}
                            style={{
                                backgroundColor: this.props.color ?? "white"
                            }}
                        />;
                    })
                }
            </div>
        )
    }
}