import React from "react";

export type NotFoundWidgetProps = {
  label?: string;
};

export default class NotFoundWidget extends React.Component<NotFoundWidgetProps> {
  render(): React.ReactNode {
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "center", marginBottom: 12}}>
          <svg
            width="51"
            height="50"
            viewBox="0 0 51 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="25.5" cy="25" r="25" fill="#E7EDFF" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M27.5 18.75C27.09 18.75 26.75 18.41 26.75 18C26.75 17.59 27.09 17.25 27.5 17.25H33.5C33.91 17.25 34.25 17.59 34.25 18C34.25 18.41 33.91 18.75 33.5 18.75H27.5Z"
              fill="#6677FF"
              stroke="#6677FF"
              stroke-width="0.5"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M27.5 21.75C27.09 21.75 26.75 21.41 26.75 21C26.75 20.59 27.09 20.25 27.5 20.25H30.5C30.91 20.25 31.25 20.59 31.25 21C31.25 21.41 30.91 21.75 30.5 21.75H27.5Z"
              fill="#6677FF"
              stroke="#6677FF"
              stroke-width="0.5"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.75 24.5C14.75 18.85 19.35 14.25 25 14.25C25.41 14.25 25.75 14.59 25.75 15C25.75 15.41 25.41 15.75 25 15.75C20.17 15.75 16.25 19.68 16.25 24.5C16.25 29.32 20.17 33.25 25 33.25C29.83 33.25 33.75 29.32 33.75 24.5C33.75 24.09 34.09 23.75 34.5 23.75C34.91 23.75 35.25 24.09 35.25 24.5C35.25 30.15 30.65 34.75 25 34.75C19.35 34.75 14.75 30.15 14.75 24.5Z"
              fill="#6677FF"
              stroke="#6677FF"
              stroke-width="0.5"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M34.9704 35.5299L32.9704 33.5299C32.6804 33.2399 32.6804 32.7599 32.9704 32.4699C33.2604 32.1799 33.7404 32.1799 34.0304 32.4699L36.0304 34.4699C36.3204 34.7599 36.3204 35.2399 36.0304 35.5299C35.8804 35.6799 35.6904 35.7499 35.5004 35.7499C35.3104 35.7499 35.1204 35.6799 34.9704 35.5299Z"
              fill="#6677FF"
              stroke="#6677FF"
              stroke-width="0.5"
            />
          </svg>
        </div>
        <p
          style={{
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "27px",
            textAlign: "center",
            color: "#999999",
          }}
        >
          {this.props.label || "No result found"}
        </p>
      </div>
    );
  }
}
