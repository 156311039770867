import React from "react";
import "./SearchResultContentWidget.css";

export type SearchResultContentWidgetProps = {
  onEndReached?: () => void;
  seeMoreHref?: string;
  shouldShowSeeMoreLink?: boolean;
  stylesWidth?: number;
  stylesHeight?: number;
  styles?: {
    imageUrl?: string;
    id?: string;
  }[];
  accounts?: {
    id?: string;
    name?: string;
    imageUrl?: string;
  }[];
  query?: string;
};

export default class SearchResultContentWidget extends React.Component<SearchResultContentWidgetProps> {
  renderStyles(styles?: typeof this.props.styles) {
    return (
      <div id="SearchResultContentWidgetStylesContainer">
        {styles?.map((s) => (
          <a href={`/Content/${s.id}?query=${this.props.query}`} style={{ height: this.props.stylesHeight, overflow: "hidden" }}>
            <img
              src={s.imageUrl}
              alt={`${s.id}'s style`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </a>
        ))}
      </div>
    );
  }

  renderAccounts(accounts?: typeof this.props.accounts) {
    return accounts?.map((acc) => (
      <a
        href={`/${acc.id}`}
        id="SearchResultContentWidgetAccountContainer"
        key={acc.id}
      >
        <img
          src={acc.imageUrl}
          alt={`${acc.name} profile`}
          id="SearchResultContentWidgetAccountsImage"
        />
        <p id="SearchResultContentWidgetAccountsLabel">{acc.name}</p>
      </a>
    ));
  }

  render() {
    return (
      <div id="SearchResultContentWidget">
        <p id="SearchResultContentWidgetSectionTitle">Accounts</p>
        {this.props.accounts ? (
          this.renderAccounts(this.props.accounts)
        ) : (
          <p id="SearchResultContentWidgetNoAccountsFound">No results found</p>
        )}
        {this.props.shouldShowSeeMoreLink && (
          <a
            id="SearchResultContentWidgetAccountsShowMore"
            href={this.props.seeMoreHref}
          >
            See all result
          </a>
        )}
        <div id="SearchResultContentWidgetAccountDivider"></div>
        <p id="SearchResultContentWidgetSectionTitle">Styles</p>
        {this.renderStyles(this.props.styles)}
      </div>
    );
  }
}
