import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { WithCreatorLinkProps, getLocationQueries, getRawLocationQueriesString, isResellerDomain } from "../../CommonComponents/Utils";
import AddressDetailResponse from "../AddressUpdater/Models/AddressDetailResponse";
import LocationSearchResponse, { LocationSearchResultModel } from "./Models/LocationSearchResponse";
import ReverseGeoLocationResponse from "./Models/ReverseGeoLocationResponse";

export default class LocationSearchViewModel extends ViewModel {
    currentLocation?: LocationSearchResultModel;
    isCurrentLocationLoading: boolean = false;
    isLoading: boolean = false;
    isSubmittingPinPoint: boolean = false;
    results: LocationSearchResultModel[] = [];
    query: string = "";
    errorMessage?: string;
    onGoingRequest?: NodeJS.Timeout;
    searchRequestTimeout?: AbortController;
    snackbarMessage?: ShowSnackbarModel;
    creatorLink?: string;
    
    constructor(args: WithCreatorLinkProps) {
        super();
        this.creatorLink = args.creatorLink
    }

    loadLiveLocation() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                this.emit(() => this.isCurrentLocationLoading = true);

                requestAPI(Endpoints.checkoutReverseGeoLocation, {
                    parameters: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    },
                    onSuccess: (response: BaseResponse<ReverseGeoLocationResponse>) => {
                        this.emit(() => {
                            this.isCurrentLocationLoading = false;
                            this.currentLocation = {
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude,
                                title: response.data.location.title,
                                addressLabel: response.data.location.addressLabel
                            };
                        });
                    },
                    onFailure: (_) => {
                        this.emit(() => {
                            this.isCurrentLocationLoading = false;
                        });
                    }
                });
            }
        );
    }

    onQueryChanged(value: string) {
        this.emit(() => {
            this.query = value;
            this.isLoading = value.length > 0;
            this.results = [];
        });

        clearTimeout(this.onGoingRequest);

        this.onGoingRequest = setTimeout(() => {
            this.searchRequestTimeout?.abort("Modify query");

            if (value.length > 0) {
                this._requestLocationSearch();
            }
        }, 1000);
    }

    onLocationSelected(location: {
        label: string;
        latitude: number;
        longitude: number;
    }) {
        if (getLocationQueries()["mode"] === "setPinPointOnly") {
            const shippingAddressId = getLocationQueries()["id"];

            this.emit(() => this.isSubmittingPinPoint = true);

            requestAPI(localStorage.fpAuth ? Endpoints.addressDetail : Endpoints.addressDetailNonLogin, {
                parameters: {
                    shippingAddressId: shippingAddressId,
                    deviceId: localStorage.uuid
                },
                onSuccess: (response1: BaseResponse<AddressDetailResponse>) => {
                    if (response1.result) {
                        this.emit(() => {
                            requestAPI(localStorage.fpAuth ? Endpoints.addressUpdate : Endpoints.addressUpdateNonLogin, {
                                parameters: {
                                    shippingAddressId: shippingAddressId,
                                    name: response1.data.name,
                                    phone: response1.data.phone,
                                    email: response1.data.email,
                                    addressDetail: response1.data.addressDetail,
                                    pinpoint: {
                                        addressLabel: location.label,
                                        latitude: location.latitude,
                                        longitude: location.longitude
                                    },
                                },
                                onSuccess: (response2) => {
                                    if (response2.result) {
                                        this.emit(() => {
                                            this.isSubmittingPinPoint = false;
                                            window.location.href = `${isResellerDomain() ?  `/shop/${this.creatorLink}/Checkout/AddAddress` : '/Checkout/AddressSelector'}?${getRawLocationQueriesString({ exceptionKeys: ["id"] })}`;
                                        });
                                    } else {
                                        this.emit(() => {
                                            this.isSubmittingPinPoint = false;
                                            this.snackbarMessage = new ShowSnackbarModel({ message: response2.message });
                                        });
                                    }
                                },
                                onFailure: (errorMessage) => {
                                    this.emit(() => {
                                        this.isSubmittingPinPoint = false;
                                        this.snackbarMessage = new ShowSnackbarModel({ message: errorMessage });
                                    });
                                }
                            });
                        });
                    } else {
                        this.emit(() => {
                            this.isSubmittingPinPoint = false;
                            this.snackbarMessage = new ShowSnackbarModel({ message: response1.message });
                        });
                    }
                },
                onFailure: (errorMessage) => {
                    this.emit(() => {
                        this.isSubmittingPinPoint = false;
                        this.snackbarMessage = new ShowSnackbarModel({ message: errorMessage });
                    });
                }
            });
        } else {
            window.localStorage.setItem('addressLabel', location.label);
            window.localStorage.setItem('latitude', `${location.latitude}`);
            window.localStorage.setItem('longitude', `${location.longitude}`);
            window.localStorage.setItem('isLoaded', 'false')

            window.location.href = `${isResellerDomain() ?  `/shop/${this.creatorLink}/Checkout/AddAddress` : '/Checkout/AddressUpdater'}?${getRawLocationQueriesString()}`;
            
        }
    }

    _requestLocationSearch() {
        this.emit(() => {
            this.isLoading = true;
            this.errorMessage = undefined;
        });

        requestAPI(Endpoints.checkoutLocation, {
            parameters: {
                query: this.query,
                latitude: this.currentLocation?.latitude,
                longitude: this.currentLocation?.longitude
            },
            onSuccess: (response: BaseResponse<LocationSearchResponse>) => {
                if (response.result) {
                    this.emit(() => {
                        this.isLoading = false;
                        this.results = response.data.locations;
                    });
                    console.log(this.results);
                } else {
                    this.emit(() => {
                        this.isLoading = false;
                        this.errorMessage = response.message;
                    });
                }
            },
            onFailure: (errorMessage) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = errorMessage;
                });
            }
        });
    }
}