import React from "react";
import { ImageWidget } from "../../../../CommonComponents/UI/ImageWidget/ImageWidget";
import SizedDiv from "../../../../CommonComponents/UI/SizedDiv";
import "./ReviewListWidget.css";

export type ReviewListWidgetProps = {
  reviews?: {
    score?: number;
    name?: string;
    date?: string;
    review?: string;
    images?: string[];
    profilePicture?: string;
    problems?: string[];
  }[];
};

export default class ReviewListWidget extends React.Component<ReviewListWidgetProps> {
  render(): React.ReactNode {
    return (
      <div style={{ backgroundColor: "#fff", padding: 24 }}>
        {this.props.reviews?.map((review) => (
          <>
            <div>
              <div className="ReviewListItemContainer">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      marginRight: 12,
                      height: 24,
                      width: 24,
                      borderRadius: 999,
                      overflow: "hidden",
                    }}
                  >
                    <ImageWidget imageUrl={review.profilePicture} />
                  </div>
                  <p
                    style={{
                      flex: 1,
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "21px",
                    }}
                  >
                    {review.name}
                  </p>
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      lineHeight: "18px",
                      display: "flex",
                      alignItems: "center",
                      textAlign: "right",

                      color: "#999999",
                    }}
                  >
                    {review.date}
                  </p>
                </div>
                <div style={{ marginTop: 10 }}>{review.review}</div>
                <div style={{ display: "flex", marginTop: 10 }}>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <svg
                      style={{ marginRight: 4 }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 9 9"
                    >
                      <path
                        d="M4.5 7.52 2.27 8.677a.4.4 0 0 1-.578-.42l.411-2.478L.314 4.016a.4.4 0 0 1 .221-.68L3.02 2.96 4.142.715a.4.4 0 0 1 .716 0L5.98 2.961l2.484.375a.4.4 0 0 1 .22.68L6.898 5.78l.411 2.477a.4.4 0 0 1-.579.42L4.5 7.52z"
                        fill={
                          index + 1 > (review.score || 0)
                            ? "#E4E4E4"
                            : "#894CF5"
                        }
                        fill-rule="evenodd"
                      />
                    </svg>
                  ))}
                </div>
                {(review.problems?.length || 0) > 0 && (
                  <div
                    style={{
                      fontWeight: 400,
                      marginTop: 10,
                      fontSize: "10px",
                      lineHeight: "15px",
                      display: "flex",
                      alignItems: "center",
                      color: "#999999",
                    }}
                  >
                    Problems: {review.problems?.join(", ")}
                  </div>
                )}
                <SizedDiv height={12} />
                {review.images?.map((image) => (
                  <div style={{ height: 58, width: 58 }}>
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      src={image}
                      alt=""
                    />
                  </div>
                ))}
              </div>
            </div>
            <SizedDiv height={16} />
          </>
        ))}
      </div>
    );
  }
}
