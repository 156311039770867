import React from "react";

import "./CuratedProductsWidgets.css";

import ic_checkbox from "../../../Assets/Images/ic_checkbox.svg";
import LabelItemModel from "../../../CommonComponents/Models/LabelItemModel";

type CuratedProductsSortViewWidgetProps = {
    onApplySortTapped: () => void;
    models: LabelItemModel[];
    selectedId: string;
    onSelectSortId: (value: string) => void;
};

export default class CuratedProductsSortViewWidget extends React.Component<CuratedProductsSortViewWidgetProps> {
    render() {
        return (
            <>
                <div>
                    <div id="CuratedProductsSortTitleContainer">
                        <p>SORT BY</p>
                        <div />
                        <button onClick={() => this.props.onApplySortTapped()}>Done</button>
                    </div>
                    {this.props.models.map(model => {
                        return (
                            <>
                                <div id="CuratedProductsSortSeparator" />
                                <button
                                    id="CuratedProductsSortItemContainer"
                                    className={model.id === this.props.selectedId ? "CuratedProductSelectedSort" : "CuratedProductUnselectedSort"}
                                    onClick={() => {
                                        this.props.onSelectSortId(model.id);
                                    }}
                                >
                                    <p>{model.label}</p>
                                    {model.id === this.props.selectedId && <img src={ic_checkbox} alt="" />}
                                </button>
                            </>
                        );
                    })}
                </div>
            </>
        );
    }
}
