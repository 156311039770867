import { AnalyticEvent } from "../AnalyticEvent";
import { EventState } from "../EventState";
import { GA4BaseEvent } from "./GA4BaseEvent";

export class ShareEvent extends GA4BaseEvent implements AnalyticEvent {
  payload: EventState;
  name: string;

  constructor(args: EventState) {
    super();
    this.payload = args;
    this.name = "SHARE_GA4";
  }

  exec(): void {
    this.fire("share", {
      method: this.payload.method || "",
      content_type: this.payload.content_type || "",
      item_id: this.payload.item_id || ""
    })
  }
}