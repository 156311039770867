import React, { ReactNode } from "react";
import { LoadingWidget } from "../LoadingWidget/LoadingWidget";

import "./Button.css";

export enum ButtonWidgetType {
    primary = "ButtonWidgetPrimary", 
    secondary = "ButtonWidgetSecondary", 
    purple = "ButtonWidgetPurple", 
    lightPurple = "ButtonWidgetLightPurple",
    lightPink = "ButtonWidgetLightPurple"
}

type ButtonWidgetProps = {
    buttonType?: ButtonWidgetType;
    buttonHeight?: number;
    isLoading?: boolean;
    isEnabled?: boolean;
    width?: string | number;
    children?: ReactNode;
    action?: () => void;
    backgroundColor?: string;
    titleColor?: string;
    borderColor?: string;
}

export default class ButtonWidget extends React.Component<ButtonWidgetProps, {}> {
    render() {
        const isDisable = this.props.isLoading || this.props.isEnabled === false;

        return (
            <button 
                id={isDisable ? "OutlineButtonWidget" : (this.props.buttonType ?? ButtonWidgetType.primary)} 
                onClick={isDisable ? undefined : this.props.action} 
                style={{
                    height: `${this.props.buttonHeight ?? 50}px`, 
                    maxHeight: `${this.props.buttonHeight ?? 50}px`, 
                    width: (this.props.width ? this.props.width : "100%"),
                    backgroundColor: this.props.backgroundColor,
                    color: this.props.titleColor,
                    flexShrink: 0,
                    border: this.props.borderColor ? `1px solid ${this.props.borderColor}` : undefined

                }}
            >
                {this.props.isLoading ? <div style={{width:"100%"}}><LoadingWidget/></div> : this.props.children}
            </button>
        );
    }
}