import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import LabelItemModel from "../../CommonComponents/Models/LabelItemModel";
import ProductSummaryItemModel from "../../CommonComponents/Models/ProductSummaryItemModel";
import SearchBrandModel from "../../CommonComponents/Models/SearchBrandModel";
import { ShowErrorModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { mapBadgeTypes, productTypesForBadgeUrls } from "../../CommonComponents/Utils";
import { ProductBadgeType } from "../../CommonTypes";
import {
  PreloadingResponse,
  SearchDefaultModel,
} from "./models/PreloadingResponse";
import { SearchContentsResponse } from "./models/SearchContentsResponse";
import SearcProductsResponse from "./models/SearchProductsResponse";

export default class SearchProductsViewModel extends ViewModel {
  isLoadingPreloading: boolean = true;
  recentQueries: string[] = [];
  query: string = "";
  isLoadingProducts: boolean = false;
  products: ProductSummaryItemModel[] = [];
  sorts: LabelItemModel[] = [];
  onGoingRequest?: NodeJS.Timeout;
  requestSearchProductsTimeout?: AbortController;
  requestSearchContentsTimeout?: AbortController;
  sortId: string | null = null;
  totalOfItems: number = 0;
  errorMessage?: ShowErrorModel;
  searchInputValue: string = "";
  selectedSortIdInSortWidget: string | null = null;
  isShowingSort: boolean = false;
  response?: SearchDefaultModel;

  badgeUrlsMap?: { [key in ProductBadgeType]: string };

  selectedTabKey?: string;
  shouldShowSeeMoreAccountsLink?: boolean = false;

  accounts?: {
    imageUrl: string;
    name: string;
    id: string;
  }[];
  isLoadingContents?: boolean = false;
  contents: {
    imageUrl: string;
    id: string;
  }[] = [];
  shouldShowSeeMoreBrandsLink?: boolean = false;
  brands: SearchBrandModel[] = [];
  totalContents: number = 0;

  constructor() {
    super({key: 'OmniSearchViewModel'});
    this.refresh();
    const params = new URLSearchParams(window.location.search);
    this.selectedTabKey = params.get("defaultTabKey") || "products";
    // this.query = params.get('query') || "";
    this.searchInputValue = this.searchInputValue || this.query;
    if (this.query.length > 0) {
      this.loadContents();
      this.loadProducts();
    }
  }

  onTabKeyChange(key: string) {
    this.emit(() => {
      this.selectedTabKey = key;
    });
  }

  getPreloading() {
    this.emit(() => (this.isLoadingPreloading = true));

    requestAPI(Endpoints.preloading, {
      onSuccess: (response: BaseResponse<PreloadingResponse>) => {
        // @ts-ignore
        this.badgeUrlsMap = mapBadgeTypes(response.data.productBadgeImageUrls);
        this._loadRecentSearchedQueries();
        this.emit(() => {
          this.isLoadingPreloading = false;
          this.response = response.data.searchDefault;
        });
      },
      onFailure: () => {
        this.emit(() => (this.isLoadingPreloading = false));
      },
    });
  }

  changeQuery(value: string) {
    clearTimeout(this.onGoingRequest);

    this.emit(() => {
      this.searchInputValue = value;
    });

    this.onGoingRequest = setTimeout(() => {
      this.requestSearchProductsTimeout?.abort("modify query");
      this.requestSearchContentsTimeout?.abort("modify query");

      if (value.length > 0) {
        let newRecentQueries = `${value}`;

        if (this.recentQueries.length > 10) {
          this.recentQueries.pop();
        }

        this.recentQueries.forEach((oldValue) => {
          if (value !== oldValue) {
            newRecentQueries += `;${oldValue}`;
          }
        });

        window.localStorage.setItem("recentlySearched", newRecentQueries);
      }

      this.query = value;
      this.products = [];
      this.contents = [];

      if (value.length > 0) {
        this.loadProducts();
        this.loadContents();
      } else {
        this._loadRecentSearchedQueries();
      }
    }, 1000);
  }

  loadContents() {
    this.emit(() => {
      this.isLoadingContents = true;
      this.errorMessage = undefined;
    });

    this.requestSearchContentsTimeout = requestAPI(Endpoints.searchContents, {
      parameters: {
        query: this.query,
        startIndex: this.contents.length - 1 < 0 ? 0 : this.contents.length - 1,
        limit: 24,
      },
      onSuccess: (response: BaseResponse<SearchContentsResponse>) => {
        this.emit(() => {
          this.isLoadingContents = false;

          if (response.result) {
            this.accounts = response.data.accounts?.items;
            this.shouldShowSeeMoreAccountsLink =
              response.data.accounts?.isSeeMore;
            this.contents = this.contents.concat(response.data.contents.items);
            this.totalContents = response.data.contents.totalOfItems;
          } else {
            this.errorMessage = {
              message: response.message,
              onRetryButtonTapped: () => this.loadContents(),
            };
          }
        });
      },
      onFailure: (error) => {
        console.log(error);
        this.emit(() => {
          this.isLoadingContents = false;

          if (error !== "ABORT_REQUEST") {
            this.errorMessage = {
              message: error,
              onRetryButtonTapped: () => this.loadContents(),
            };
          }
        });
      },
    });
  }

  loadProducts() {
    this.emit(() => {
      this.isLoadingProducts = true;
      this.errorMessage = undefined;
    });

    this.requestSearchProductsTimeout = requestAPI(Endpoints.searchProducts, {
      parameters: {
        query: this.query,
        startIndex: this.products.length,
        limit: 24,
        sortId: this.sortId,
      },
      onSuccess: (response: BaseResponse<SearcProductsResponse>) => {
        this.emit(() => {
          this.isLoadingProducts = false;

          if (response.result) {
            this.products = this.products.concat(
              response.data.products.items.map((x) => ({
                ...x,
                badgeUrls: productTypesForBadgeUrls(
                  x.badgeTypes,
                  this.badgeUrlsMap || {}
                ),
              }))
            );
            this.totalOfItems = response.data.products.totalOfItems;
            this.brands = response.data.brands?.items || [];
            this.shouldShowSeeMoreBrandsLink = response.data.brands?.isSeeMore;

            if (this.sorts.length === 0) {
              this.sorts = response.data.products.sorts;
            }

            if (!this.sortId && this.sorts.length > 0) {
              this.sortId = this.sorts[0].id;
              this.selectedSortIdInSortWidget = this.sortId;
            }
          } else {
            this.errorMessage = {
              message: response.message,
              onRetryButtonTapped: () => this.loadProducts(),
            };
          }
        });
      },
      onFailure: (error) => {
        console.log(error);
        this.emit(() => {
          this.isLoadingProducts = false;

          if (error !== "ABORT_REQUEST") {
            this.errorMessage = {
              message: error,
              onRetryButtonTapped: () => this.loadProducts(),
            };
          }
        });
      },
    });
  }

  showSort() {
    this.emit(() => (this.isShowingSort = true));
  }

  hideSort() {
    this.emit(() => {
      this.isShowingSort = false;
      this.selectedSortIdInSortWidget = this.sortId;
    });
  }

  changeSort(value: string) {
    this.emit(() => (this.selectedSortIdInSortWidget = value));
  }

  applySort() {
    const selectedSortId = this.selectedSortIdInSortWidget;
    const isReloadRequired = this.sortId !== this.selectedSortIdInSortWidget;

    if (selectedSortId !== null) {
      this.emit(() => {
        this.sortId = selectedSortId;
        this.selectedSortIdInSortWidget = this.sortId;
        this.isShowingSort = false;
      });
    }

    if (isReloadRequired) this.changeQuery(this.query);
  }

  _loadRecentSearchedQueries() {
    const recentlySearchedInLocalStorage =
      window.localStorage.getItem("recentlySearched");
    if (recentlySearchedInLocalStorage !== null) {
      const queries =
        recentlySearchedInLocalStorage.length > 0
          ? (recentlySearchedInLocalStorage as string).split(";")
          : [];

      this.emit(() => {
        this.recentQueries = queries;
        this.errorMessage = undefined;
      });
    }
  }
}
