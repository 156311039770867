import React from "react";
import { getScreenSizeWithMaxWidth } from "../Utils";
import ViewModel from "./ViewModel";

interface ViewControllerState<T extends ViewModel> {
    viewModel: T;
    width: number;
    height: number;
}

export default class ViewController<Props, VM extends ViewModel> extends React.Component<Props, ViewControllerState<VM>> {
    constructor(props: Props, viewModel: VM) {
        super(props);

        const screenSize = getScreenSizeWithMaxWidth();

        this.state = {
            viewModel: viewModel,
            width: screenSize.width,
            height: screenSize.height
        };

        window.addEventListener("resize", this.updateSize);

        this.viewModel.emit = (action: () => void) => {
            action();

            this.setState({
                viewModel: this.state.viewModel
            });
        };
    }

    private updateSize = () => {
        const screenSize = getScreenSizeWithMaxWidth();
        
        this.setState({
            viewModel: this.state.viewModel,
            width: screenSize.width,
            height: screenSize.height
        });
    }

    get viewModel(): VM {
        return this.state.viewModel;
    }
}