import React, { useState } from "react";

export type CollapsibleViewWidgetProps = {
  children?: React.ReactNode;
  renderHandle?: (props: {
    isOpen?: boolean;
    onClick?: () => void;
  }) => React.ReactNode;
};

function CollapsibleViewWidget(props: CollapsibleViewWidgetProps) {
  const [isOpen, setIsOpen] = useState(false);

  const { renderHandle, children, ...rest } = props;

  const contentRef = React.useRef(null as any);

  return (
    <div style={{ position: "relative" }} {...rest}>
      <div style={{ zIndex: 9 }}>
        {renderHandle?.({ isOpen, onClick: () => setIsOpen((prev) => !prev) })}
      </div>
      <div
        style={{
          position: "relative",
          transition: "all ease-in 0.3s",
          overflow: "hidden",
          height: isOpen ? contentRef.current.clientHeight : 0
        }}
      >
        <div
          ref={contentRef}
          style={{
            position: "absolute",
            top: 0,
            overflow: "hidden",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default CollapsibleViewWidget;
