import { useState, useRef, useEffect } from "react";
import {
  BrandItem,
  CategoryItem,
  ContentContainer,
  Grid,
  SearchBar,
  Section,
  StickyHeader,
  StyledContainer,
  StyledSidebar,
  LocalBrandContainer,
  LocalBrandHeader,
  ProductGrid,
  ProductItem,
  SearchBarWrapper,
  ScrollableFilterBar,
  FilterButton,
  EmptyState,
  FilteredCounter,
  FilterPill,
} from "./styled";
import FilterDrawer from "./FilterDrawer";
import ic_filter from "../../Assets/Images/ic-filter.svg";
import ic_search from "../../Assets/Images/ic-search-black.svg";
import ic_arrow_right from "../../Assets/Images/ic-arrow-right.svg";
import ic_close from "../../Assets/Images/ic-close.svg";

const host = process.env.REACT_APP_ENV === "production" ? "https://app.thefepi.com/" : "https://staging.thefepi.com/";

export interface SelectedFilters {
  style: string[];
  gender: string[];
  age: string[];
}

const BrandScreen = () => {
  const [originalCategoriesData, setOriginalCategoriesData] = useState<any[]>([]);
  const [filteredCategoriesData, setFilteredCategoriesData] = useState<any[]>([]);
  const [activeCategory, setActiveCategory] = useState<string>("Trending");
  const [targetCategory, setTargetCategory] = useState<string | null>(null);
  const [scrolling, setScrolling] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    style: [],
    gender: [],
    age: [],
  });

  const [searchQueries, setSearchQueries] = useState<{ [key: string]: string }>({});
  const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState(false);
  const sectionsRef = useRef<(HTMLElement | null)[]>([]);
  const observer = useRef<IntersectionObserver | null>(null);

  const handleApplyFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    applyFilters(filters);
  };

  const applyFilters = (filters: SelectedFilters) => {
    const filteredData = originalCategoriesData.map((category) => {
      if (category.label === "Local Brands") {
        return {
          ...category,
          brands: category.brands.filter((brand: any) => {
            const brandTag = brand.tag ? brand.tag.trim().toLowerCase() : "";
            const brandGender = brand.gender ? brand.gender.trim().toLowerCase() : "";

            return (
              (filters.gender.length > 0 ? filters.gender.some((gender) => gender.toLowerCase() === brandGender) : true) &&
              (filters.age.length > 0 ? filters.age.includes(brand.age) : true) &&
              (filters.style.length > 0 ? filters.style.some((style) => brandTag.includes(`#${style.toLowerCase()}`)) : true)
            );
          }),
        };
      }
      return category;
    });

    setFilteredCategoriesData(filteredData);
  };

  const removeFilter = (category: keyof SelectedFilters, value: string) => {
    const updatedFilters = {
      ...selectedFilters,
      [category]: selectedFilters[category].filter((item) => item !== value),
    };
    setSelectedFilters(updatedFilters);
    applyFilters(updatedFilters);
  };

  useEffect(() => {
    const bannerEl = document.getElementById("promotional-banner") as HTMLElement;
    if (bannerEl) bannerEl.style.display = "none";

    fetch(`${host}mobile/node/Store/V2/TopBrands`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data.result && data.data.topBrands) {
          const deduplicatedData = data.data.topBrands.map((category: any) => {
            const uniqueBrands = Array.from(new Map(category.brands.map((brand: any) => [brand.name, brand]))).map(([_, brand]) => brand);
            return { ...category, brands: uniqueBrands };
          });
          setOriginalCategoriesData(deduplicatedData);
          setFilteredCategoriesData(deduplicatedData);
        }
      })
      .catch((error) => console.error("Failed to fetch brands:", error));
  }, []);

  useEffect(() => {
    if (observer.current) observer.current.disconnect();

    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleEntries = entries.filter((entry) => entry.isIntersecting);
        if (visibleEntries.length > 0) {
          const mostVisibleEntry = visibleEntries.reduce((maxEntry, entry) => (entry.intersectionRatio > maxEntry.intersectionRatio ? entry : maxEntry));
          const visibleSectionLabel = mostVisibleEntry.target.getAttribute("data-label");
          if (visibleSectionLabel) {
            if (visibleSectionLabel === targetCategory) {
              setActiveCategory(visibleSectionLabel);
              setTargetCategory(null);
              setScrolling(false);
            } else if (!scrolling) {
              setActiveCategory(visibleSectionLabel);
            }
          }
        }
      },
      { rootMargin: "0px 0px -70% 0px", threshold: [0.2, 0.5, 0.8] }
    );

    sectionsRef.current.forEach((section) => {
      if (section) {
        observer.current?.observe(section);
      }
    });

    return () => {
      observer.current?.disconnect();
    };
  }, [filteredCategoriesData, targetCategory, scrolling]);

  const handleCategoryClick = (index: number) => {
    const targetSection = sectionsRef.current[index];
    if (targetSection) {
      setScrolling(true);
      targetSection.scrollIntoView({ behavior: "smooth", block: "start" });
      setTargetCategory(filteredCategoriesData[index]?.label);
    }
  };

  const filterBrands = (categoryLabel: string, query: string) => {
    if (query === "") {
      setFilteredCategoriesData(originalCategoriesData);
    } else {
      const filteredData = originalCategoriesData.map((category: any) => {
        if (category.label === categoryLabel) {
          return {
            ...category,
            brands: category.brands.filter((brand: any) => brand?.name?.toLowerCase().includes(query)),
          };
        }
        return category;
      });
      setFilteredCategoriesData(filteredData);
    }
  };

  const handleSearchChange = (category: string, value: string) => {
    setSearchQueries((prevQueries) => ({
      ...prevQueries,
      [category]: value,
    }));
    const query = value.trim().toLowerCase();
    filterBrands(category, query);
  };

  const handleOpenFilterDrawer = () => {
    setIsFilterDrawerVisible(true);
  };

  const handleCloseFilterDrawer = () => {
    setIsFilterDrawerVisible(false);
  };

  const selectedFilterCount = Object.values(selectedFilters).reduce((count, filterArray) => count + filterArray.length, 0);

  return (
    <div>
      <StyledContainer>
        <StyledSidebar>
          {filteredCategoriesData.map((category, index) => (
            <CategoryItem key={category?.label} active={activeCategory === category?.label} onClick={() => handleCategoryClick(index)}>
              {category?.label}
            </CategoryItem>
          ))}
        </StyledSidebar>

        <ContentContainer>
          {filteredCategoriesData.map((category, index) => (
            <Section key={category?.label} ref={(el) => (sectionsRef.current[index] = el)} data-label={category?.label}>
              <StickyHeader id="category-header">
                <h2>{category?.label}</h2>
                <SearchBarWrapper>
                  <img src={ic_search} alt="search" />
                  <SearchBar placeholder={`Search ${category?.label}`} value={searchQueries[category?.label] || ""} onChange={(e) => handleSearchChange(category?.label, e.target.value)} />
                </SearchBarWrapper>
                {category?.label === "Local Brands" && (
                  <ScrollableFilterBar>
                    <div style={{ position: "relative" }}>
                      <button style={{ display: "flex", alignItems: "center", gap: "4px" }} onClick={handleOpenFilterDrawer}>
                        <img src={ic_filter} alt="filter" />
                        Filter
                      </button>
                      {selectedFilterCount > 0 && <FilteredCounter>{selectedFilterCount}</FilteredCounter>}
                    </div>
                    {Object.entries(selectedFilters).flatMap(([category, values]) =>
                      values.map((value: string) => (
                        <FilterPill key={`${category}-${value}`}>
                          {value}
                          <img src={ic_close} alt="arrow" style={{ width: "10px", marginLeft: "5px", cursor: "pointer" }} onClick={() => removeFilter(category as keyof SelectedFilters, value)} />
                        </FilterPill>
                      ))
                    )}
                  </ScrollableFilterBar>
                )}
              </StickyHeader>

              {category?.label === "Local Brands" ? (
                category?.brands?.length > 0 ? (
                  category.brands.map((brand: any, index: number) => (
                    <LocalBrandContainer key={brand?.name} index={index} totalBrands={category?.brands.length}>
                      <LocalBrandHeader>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <a href={brand?.link} style={{ display: "flex", alignItems: "center", gap: "3px" }}>
                            <img src={brand?.imageUrl} alt={brand?.name} />
                            <h3>{brand?.name}</h3>
                          </a>
                          <span style={{ fontWeight: 700, textTransform: "uppercase" }}>{brand?.tag?.replace(/#/g, "")}</span>
                        </div>
                        <a id="arrow" href={brand?.link}>
                          <img src={ic_arrow_right} alt="arrow" style={{ width: "25px", marginRight: 0, paddingRight: "5px" }} />
                        </a>
                      </LocalBrandHeader>
                      <ProductGrid>
                        {brand?.products?.slice(0, 3).map((product: any, index: number) => (
                          <a key={index} href={product?.thumbnailLink || `/Product/${product.productId}`} target={product?.thumbnailLink ? "_blank" : "_self"} rel="noreferrer">
                            <ProductItem>
                              <img src={product?.imageUrl} alt={product?.name} />
                            </ProductItem>
                          </a>
                        ))}
                      </ProductGrid>
                    </LocalBrandContainer>
                  ))
                ) : (
                  <EmptyState>No {category?.label} found</EmptyState>
                )
              ) : (
                <>
                  {category?.brands.length > 0 ? (
                    <Grid id="category-grid">
                      {category?.brands.map((brand: any) => (
                        <BrandItem key={brand?.name}>
                          <a href={brand?.link}>
                            <img src={brand?.imageUrl || ""} alt={brand?.name} />
                            <p>{brand?.name}</p>
                          </a>
                        </BrandItem>
                      ))}
                    </Grid>
                  ) : (
                    <EmptyState>No results found</EmptyState>
                  )}
                </>
              )}
            </Section>
          ))}
        </ContentContainer>
      </StyledContainer>

      <FilterDrawer onApplyFilters={handleApplyFilters} isVisible={isFilterDrawerVisible} onClose={handleCloseFilterDrawer} selectedFilters={selectedFilters} />
    </div>
  );
};

export default BrandScreen;
