import React, { useRef } from "react";
import { LoadingWidget } from "../../../CommonComponents/UI/LoadingWidget/LoadingWidget";

export type ReviewPhotoWidgetProps = {
  imageUrl?: string;
  onDeletePhoto?: () => void;
  onAddPhoto?: () => void;
  onFileChange?: (file?: any) => void;
  isLoading?: boolean;
};

function ReviewPhotoWidget(props: ReviewPhotoWidgetProps) {
  const { imageUrl, onAddPhoto, onDeletePhoto, isLoading = false } = props;

  const inputFileRef = useRef(null as any);

  return (
    <button
      onClick={() => {
        inputFileRef.current?.click();
      }}
      style={Object.assign(
        {
          height: 80,
          width: 80,
          position: "relative" as const,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          borderRadius: "10px",
        },
        !imageUrl && {
          background: "#FFFFFF",
          border: "1px dashed #C0C0C0",
        }
      )}
    >
      {isLoading && (
        <div
          style={{
            position: "absolute",
            inset: 0,
            backgroundColor: "#000",
            opacity: 0.3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></div>
      )}
      {isLoading && (
        <>
          <div
            style={{
              position: "absolute",
              inset: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingWidget theme="dark" />
          </div>
        </>
      )}
      {imageUrl && (
        <img
          src={imageUrl}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt=""
        />
      )}
      <input
        ref={inputFileRef}
        onChange={(e) => {
          props.onFileChange?.(e.target.files?.[0]);
        }}
        type="file"
        style={{ display: "hidden", width: 0, height: 0, visibility: "hidden" }}
      />
      {!imageUrl && (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.975 0C7.64363 0 7.375 0.26863 7.375 0.6V7.375H0.6C0.268629 7.375 0 7.64363 0 7.975V8.025C0 8.35637 0.26863 8.625 0.6 8.625H7.375V15.4C7.375 15.7314 7.64363 16 7.975 16H8.025C8.35637 16 8.625 15.7314 8.625 15.4V8.625H15.4C15.7314 8.625 16 8.35637 16 8.025V7.975C16 7.64363 15.7314 7.375 15.4 7.375H8.625V0.6C8.625 0.268629 8.35637 0 8.025 0H7.975Z"
            fill="#585B63"
          />
        </svg>
      )}
    </button>
  );
}

export default ReviewPhotoWidget;
