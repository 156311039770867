import React from "react";
import "./CheckoutInsuranceWidget.css";

type CheckoutInsuranceWidgetProps = {
  isChecked?: boolean;
  label?: string;
  onIsCheckedChange?: (isChecked: boolean) => void;
};

export default class CheckoutInsuranceWidget extends React.Component<CheckoutInsuranceWidgetProps> {
  render() {
    const { isChecked, label, onIsCheckedChange = () => {} } = this.props;

    return (
      <div className="row" id="CheckoutInsuranceWidget">
        <label
          className="col"
          style={{ display: "flex", alignItems: "center" }}
        >
          <span className={`checkboxMark ${isChecked && "checked"}`} />
          <input
            checked={isChecked}
            type="checkbox"
            id="checkbox_insurance"
            onChange={() => onIsCheckedChange(!isChecked)}
          />
          <span style={{ fontSize: "12px" }}>&nbsp;&nbsp;{label}</span>
        </label>
      </div>
    );
  }
}
