import React from "react";
import SizedDiv from "../../../CommonComponents/UI/SizedDiv";
import ReviewListWidget from "../../ReviewList/Widgets/ReviewList/ReviewListWidget";
import ReviewSummaryWidget from "../../ReviewList/Widgets/ReviewSummary/ReviewSummaryWidget";
import ProductDetailResponse from "../Models/ProductDetailResponse";
import ProductDetailFeedSectionWidget from "./ProductDetailFeedSectionWidget";

type ProductDetailDescriptionWidgetProps = {
  productDetail: ProductDetailResponse | null;
  reviewCount?: number;
  productId?: string;
  tabId: string | 1;
  onTabClicked: (tabId: string) => void;
};

export default class ProductDetailDescriptionWidget extends React.Component<ProductDetailDescriptionWidgetProps> {
  render() {
    const productDetail = this.props.productDetail;
    return (
      <div id="ProductDescriptionContainer" style={{ marginTop: 36 }}>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            className={this.props.tabId === "1" ? "col-6 active" : "col-6"}
            style={{ paddingBottom: 4 }}
          >
            <button
              onClick={() => {
                this.props.onTabClicked("1");
              }}
            >
              <p>Description</p>
            </button>
          </div>
          <div
            className={this.props.tabId === "2" ? "col-6 active" : "col-6"}
            style={{ paddingBottom: 4 }}
          >
            <button
              onClick={() => {
                this.props.onTabClicked("2");
              }}
            >
              <p>Review ({this.props.reviewCount || 0})</p>
            </button>
          </div>
        </div>
        <div
          id="DESCRIPTION_PRODUCT"
          className="tabContent"
          style={{ display: this.props.tabId === "1" ? "block" : "none" }}
        >
          <ProductDetailFeedSectionWidget
            models={
              this.props.productDetail?.productDescription.detailFeeds ?? []
            }
          />
          <div className="row">
            {productDetail?.productDescription.descriptionInfo.map(
              (description, index) => (
                <div
                  className="row"
                  key={index}
                  style={{ marginBottom: "16px" }}
                >
                  <div className="col-4">
                    <p>{description.title}</p>
                  </div>
                  <div className="col-8">
                    <p style={{ whiteSpace: "pre-line" }}>
                      {description.descriptionText}
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div
          id="REVIEW_PRODUCT"
          className="tabContent"
          style={{ display: this.props.tabId === "2" ? "block" : "none" }}
        >
          <div style={{ margin: "24px 32px" }}>
            <ReviewSummaryWidget
              maxScore={5}
              score={productDetail?.review.reviewSummary.starAverage}
              starDistribution={{
                "1": productDetail?.review.reviewSummary.numberOfEachStar
                  .oneStar,
                "2": productDetail?.review.reviewSummary.numberOfEachStar
                  .twoStar,
                "3": productDetail?.review.reviewSummary.numberOfEachStar
                  .threeStar,
                "4": productDetail?.review.reviewSummary.numberOfEachStar
                  .fourStar,
                "5": productDetail?.review.reviewSummary.numberOfEachStar
                  .fiveStar,
              }}
              reviewCount={productDetail?.review.reviewSummary.totalReviews}
            />
          </div>
          <div style={{ backgroundColor: "#F2F2F2", height: 8 }}></div>

          <div>
            <SizedDiv height={24} />
            <div
              className="d-flex"
              style={{
                margin: "0px 24px",
                fontSize: "12px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p>All ({productDetail?.review.reviewSummary.totalReviews})</p>
              <a href={`/Product/${this.props.productId}/Reviews`}>See All</a>
            </div>
            <ReviewListWidget
              reviews={productDetail?.review.reviews.slice(0, 5).map((r) => ({
                date: r.timeLabel,
                images: r.imageUrls,
                profilePicture: r.profileImageUrl,
                name: r.username,
                review: r.reviewLabel,
                problems: r.problems,
                score: r.star,
              }))}
            />
          </div>
        </div>
      </div>
    );
  }
}
