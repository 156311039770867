import styled from "styled-components";

interface CategoryItemProps {
  active: boolean;
}

export const StyledContainer = styled.div`
  display: flex;
  height: calc(100vh - 150px);
  width: 100%;
`;

export const StyledSidebar = styled.div`
  flex: 0 0 125px;
  background-color: #f8f8f8;
  padding: 20px 0;
  position: sticky;
  top: 0;
  height: calc(100vh - 150px);
  overflow-y: auto;
`;

export const CategoryItem = styled.div<CategoryItemProps>`
  padding: 16px 14px;
  cursor: pointer;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: black;
  &:focus {
    outline: none;
  }
  font-size: 14px;
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const StickyHeader = styled.div`
  background-color: white;
  padding: 20px 20px 30px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;

  h2 {
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
  }
`;

export const Section = styled.div`
  margin-bottom: 20px;
`;

export const FilterBar = styled.div`
  padding-top: 15px;
  display: flex;
  gap: 10px;

  button {
    font-size: 12px;
    padding: 4px 10px;
    border-radius: 50px;
    border: 1px solid #E4E4E4;
    background-color: white;
    cursor: pointer;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 15px;
  padding: 0 20px;
  width: 100%;
  justify-content: center;
`;

export const BrandItem = styled.div`
  background-color: #fff;
  text-align: center;

  img {
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 8px;
    object-fit: cover;
  }

  p {
    font-size: 12px;
    padding-top: 10px;
  }
`;

export const SearchBarWrapper = styled.div`
  padding: 10px 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px #E4E4E4 solid;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SearchBar = styled.input`
  font-size: 14px;
  border: none;
  width: 100%;
  outline: none;
`;

export const LocalBrandContainer = styled.div<{ index: number; totalBrands: number }>`
  padding: ${({ index }) => (index === 0 ? '0 20px 25px' : '20px 20px 25px')};
  border-radius: 10px;
  border-bottom: ${({ index, totalBrands }) => (index !== totalBrands - 1 ? '1px solid #E4E4E4' : 'none')};
  padding-bottom: ${({ index, totalBrands }) => (index !== totalBrands - 1 ? '25px' : '0')};
`;

export const LocalBrandHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: space-between;
  gap: 5px;

  div {
    display: flex;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }

  h3 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding-right: 2px;
  }

  span {
    font-size: 10px;
    color: #585B63;
    background-color: #E8EBF5;
    padding: 2px 6px;
    border-radius: 50px;
  }

  #arrow {
    width: 20px;
  }
`;

export const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
`;

export const ProductItem = styled.div`
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const ScrollableFilterBar = styled(FilterBar)`
  display: flex;
  gap: 8px;
  overflow-x: auto;
  white-space: nowrap;
`;

export const FilterButton = styled.button<{ active?: boolean }>`
  padding: 8px 12px;
  border-radius: 20px;
  background: ${({ active }) => (active ? 'linear-gradient(65.16deg, #FFC8B4 -8.13%, #FF662F 16.38%, #FF00CE 103.92%)' : 'white')} !important;
  color: ${({ active }) => (active ? 'white' : 'black')};
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  border: ${({ active }) => (active ? "1px solid transparent" : "1px solid #E4E4E4")};
`;

export const EmptyState = styled.div`
  text-align: center;
  padding: 20px 0;
  font-size: 14px;
  color: #585b63;
`;

export const FilteredCounter = styled.div`
  background: linear-gradient(65.16deg, #FFC8B4 -8.13%, #FF662F 16.38%, #FF00CE 103.92%);
  width: 16px;
  height: 16px;
  top: -6px;
  left: 57px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  font-weight: 600;
  border-radius: 50%;
`;

export const FilterPill = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  margin-right: 1px;
  font-size: 12px;
  font-weight: 500;
  background: linear-gradient(65.16deg, #FFC8B4 -8.13%, #FF662F 16.38%, #FF00CE 103.92%);
  color: white;
  border-radius: 20px;
  cursor: pointer;
`;
