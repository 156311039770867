import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { ImageWidget } from "../../../CommonComponents/UI/ImageWidget/ImageWidget";
import { IndexIndicator } from "../../../CommonComponents/UI/IndexIndicator/IndexIndicatorWidget";
import CreatorSpaceResponse from "../Models/CreatorSpaceResponse";

import ic_photo_profile_placeholder from "../../../Assets/Images/ic_photo_profile_placeholder.svg";
import ic_share from "../../../Assets/Images/ic_share_box.svg";
import ic_plus from "../../../Assets/Images/ic_plus.svg";
import ic_check from "../../../Assets/Images/ic_check.svg";

type CreatorSpaceTopSectionWidgetProps = {
    isMySpace: boolean,
    screenWidth: number,
    spaceDetail: CreatorSpaceResponse | null,
    isFollowed: boolean,
    shareLink: () => void,
    followButtonTapped: (isFollowed: boolean) => void
};

type CreatorSpaceTopSectionWidgetState = {
    showingIndex: number
}

export default class CreatorSpaceTopSectionWidget extends React.Component<CreatorSpaceTopSectionWidgetProps, CreatorSpaceTopSectionWidgetState> {
    constructor(props: CreatorSpaceTopSectionWidgetProps) {
        super(props);

        this.state = {
            showingIndex: 0
        };
    }

    render() {
        const coverImageHeight = this.props.screenWidth * 3 / 2;
        const spaceDetail = this.props.spaceDetail;
        const photoProfile = (spaceDetail?.sellerProfile.imageUrl ?? "");

        return(
            <div id="SpaceTopContainer">
                <Swiper 
                    id="SpaceCoverContainer" 
                    slidesPerView={1}
                    style={{height:`${coverImageHeight}px`}}
                    onSlideChangeTransitionEnd={(swiper) => {
                        this.setState({
                            showingIndex: swiper.activeIndex
                        });
                    }}
                >
                    {
                        Array.from({length: ((this.props.spaceDetail?.backgroundImageUrls ?? []).length ?? 0)}).map((_, index) => {
                            return <SwiperSlide key={index}>
                                <div
                                    style={{height:`${coverImageHeight}px`}}
                                >
                                    <ImageWidget imageUrl={this.props.spaceDetail?.backgroundImageUrls[index] ?? ""} key={`${index}_image`} />
                                </div>
                            </SwiperSlide>;
                        })
                    }
                </Swiper>
                <div id="SpaceCoverBottomGradient" style={{top:`${coverImageHeight-50}px`}}/>
                <div id="SpaceIndexIndicatorContainer" style={{top:`${coverImageHeight - 34}px`}}>
                    <IndexIndicator 
                        showingIndex={this.state.showingIndex} 
                        totalOfIndices={(this.props.spaceDetail?.backgroundImageUrls ?? []).length ?? 0} 
                    />
                </div>
                <div id="SpaceProfilePicture" style={{top:`${coverImageHeight - 40}px`}}>
                    <ImageWidget imageUrl={photoProfile} placeholder={ic_photo_profile_placeholder} />
                </div>
                <div style={{height:"52px"}}>
                    <div id="SpaceShareAndFollow">
                        {
                            !this.props.isMySpace &&
                            <button 
                                id={this.props.isFollowed ? "SpaceFollowButtonFollowed" : "SpaceFollowButtonNonFollowed"} 
                                onClick={() => this.props.followButtonTapped(!this.props.isFollowed)}
                                key={`${this.props.isFollowed}_follow_button`}
                            >
                                {
                                    this.props.isFollowed ?
                                    <>
                                        <img src={ic_check} alt="" />
                                        <p>Following</p>
                                    </> :
                                    <>
                                        <img src={ic_plus} alt="" />
                                        <p>Follow</p>
                                    </>
                                }
                            </button>
                        }
                        <button onClick={() => this.props.shareLink()}>
                            <img src={ic_share} alt="" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}