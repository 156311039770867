import React from "react";
import "./ProductDetailVariantPicker.css";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  AttributeItemModel,
  AttributeModel,
  AttributeType,
} from "../../ProductDetailViewModel";

export type ProductDetailVariantPickerProps = {
  attribute?: AttributeModel;
  title?: string;
  oneLiner?: boolean;
  onSeeAllClick?: () => void;
  shouldShowSeeAll?: boolean;
  onAttributeItemClick?: (
    attribute?: AttributeItemModel,
    stock?: number,
    isPreOrder?: boolean
  ) => void;
  selectedMapAttributes?: Record<AttributeType, AttributeItemModel>;
  type?: AttributeType;
  isDisabled?: boolean;
};

export default class ProductDetailVariantPicker extends React.Component<ProductDetailVariantPickerProps> {


  render() {
    return (
      <div id="ProductDetailVariantPicker">
        <div id="ProductDetailVariantPickerHeader">
          <div id="ProductDetailVariantPickerTitle">{this.props.title}</div>
          {this.props.shouldShowSeeAll && (
            <button
              id="ProductDetailSeeAllLink"
              onClick={() => this.props.onSeeAllClick?.()}
            >
              See all
            </button>
          )}
        </div>
        <div id="ProductDetailVariantPickerPillContainer">
          <ScrollContainer
            horizontal
            hideScrollbars={false}
            style={{
              display: "flex",
              flexWrap: this.props.oneLiner ? "unset" : "wrap",
              opacity: this.props.isDisabled ? 0.25 : 1
            }}
          >
            {this.props.attribute?.items?.map(
              ({ attribute, stock = 0, isPreOrder }) => {
                if (stock <= 0) {
                  if (!isPreOrder) {
                    return (
                      <button id="ProductDetailVariantPickerPillDisabled">
                        {attribute.label}
                      </button>
                    );
                  }
                }
                return (
                  <button
                    key={attribute.id}
                    id={
                      attribute.id ===
                      this.props.selectedMapAttributes?.[
                        this.props.type as AttributeType
                      ]?.id
                        ? "ProductDetailVariantPickerPillSelected"
                        : "ProductDetailVariantPickerPill"
                    }
                    onClick={() => {
                     
                      if (stock > 0 || isPreOrder) {
                        this.props.onAttributeItemClick?.(attribute, stock, isPreOrder);
                      }
                     
                    }}
                  >
                    {attribute.label}
                  </button>
                );
              }
            )}
          </ScrollContainer>
        </div>
      </div>
    );
  }
}
