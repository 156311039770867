
import { requestAPI } from "../../../CommonComponents/ApiRequest/APIRequest";
import { Endpoints } from "../../../CommonComponents/ApiRequest/Endpoints";
import { ContentDisplayModel } from "../Models/ContentDisplayModels";
import { ShowErrorModel, ShowSnackbarModel } from "../../../CommonComponents/UI/Scaffold/Scaffold";
import PopUpWidgetModel from "../../../CommonComponents/UI/PopUp/Models/PopUpWidgetModel";
import { getPreloadProps } from "../../../CommonComponents/Utils";
import { ShareUrlModel } from "../../../CommonComponents/UI/ShareUrlPopUp/Models/ShareUrlModel";
import BaseResponse from "../../../CommonComponents/ApiRequest/BaseResponse";
import ContentExplorerResponse, { ContentExplorerFilterModalModel, ContentExplorerWithSelectedStatusModel } from "../Models/ContentExplorerResponse";
import ViewModel from "../../../CommonComponents/Base/ViewModel";
import { eventManager } from "../../../Analytics/Events/Common";

export type ExporeType = "discover" | "following";

export class ContentExplorerViewModel extends ViewModel {
    isCreator: boolean = false;
    creatorId: string = "";
    numberInCart: number = 0;
    isPreloadingLoaded: boolean = false;
    contents: ContentDisplayModel[] = [];
    isFollowAnyCreator?: boolean;
    loadedContentInSwiper: ContentDisplayModel[] | null = null;
    isLoading: boolean;
    errorMessage: ShowErrorModel | null = null;
    snackbarMessage: ShowSnackbarModel | null = null;
    isAbleToLoadMoreContents = false;
    isLoadingMoreContents = false;
    explorerType: ExporeType;
    showingContentIndex: number = 0;
    popUpModel?: PopUpWidgetModel;
    shareUrlModel: ShareUrlModel | null = null;
    quickFilters?: { id: string; label: string }[];
    filters?: ContentExplorerFilterModalModel[];
    selectedQuickFilterId?: string;
    isFilterEnabledToBeShown: boolean = false;
    isFilterModalShown?: boolean;
    filterBasedOnProductModel?: ContentExplorerWithSelectedStatusModel;
    tempFilterBasedOnProductModel?: ContentExplorerWithSelectedStatusModel;
    totalOfFilterOptionsSelected: number = 0;

    get showModalStatus(): string {
        if (this.isFilterModalShown === undefined) return "";

        if (this.isFilterModalShown) return "FilterModalAppear";

        setTimeout(() => this.emit(() => this.isFilterModalShown = undefined), 500);

        return "FilterModalDissapear";
    }
    
    get isAnyChangesOnFilter(): boolean {
        if (this.filterBasedOnProductModel && this.tempFilterBasedOnProductModel) {
            for (let componentIndex = 0; componentIndex < this.filterBasedOnProductModel.components.length; componentIndex++) {
                const options = this.filterBasedOnProductModel.components[componentIndex].options;
                for (let optionIndex = 0; optionIndex < options.length; optionIndex++) {
                    return true
                }
            }
        }

        return false;
    }

    constructor(explorerType: ExporeType) {
        super();
        this.explorerType = explorerType;
        this.isLoading = !(explorerType === "following" && !localStorage.fpAUth);
    }

    loadPreloadingProps() {
        this.emit(() => { 
            this.isLoading = true ;
        });

        getPreloadProps({
            onSuccess: (isCreator, creatorId, numberInCart) => {
                this.emit(() => {
                    this.isCreator = isCreator;
                    this.creatorId = creatorId;
                    this.numberInCart = numberInCart;
                    this.isPreloadingLoaded = true;
                });

                this.loadContents();
            },
            onFailure: error => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = {
                        message: error,
                        onRetryButtonTapped: () => this.loadPreloadingProps(),
                    };
                });
            },
        });
    }

    loadContents() {
        this.totalOfFilterOptionsSelected = 0;

        let selectedFilters: {
            componentId: string;
            selectedOptionIds: string[];
        }[] = [];

        this.filterBasedOnProductModel?.components.forEach((component) => {
            let isAnyOptionSelected = false;

            component.options.forEach((option) => {
                if (option.isSelected) {
                    if (isAnyOptionSelected) {
                        selectedFilters[selectedFilters.length - 1].selectedOptionIds.push(option.id);
                    } else {
                        isAnyOptionSelected = true;
                        selectedFilters.push({
                            componentId: component.componentId,
                            selectedOptionIds: [option.id]
                        });
                    }

                    this.totalOfFilterOptionsSelected += 1;
                }
            });
        });

        if (this.contents.length === 0) {
            this.emit(() => {
                this.isLoading = true;
                this.errorMessage = null;
                this.snackbarMessage = null;
            });
        }

        this.isLoadingMoreContents = true;

        requestAPI(this.explorerType === "discover" ? Endpoints.discover : Endpoints.following, {
            parameters: {
                startIndex: this.contents.length,
                limit: 24,
                isFilterModelLoaded: this.quickFilters != undefined,
                selectedQuickFilterId: this.selectedQuickFilterId,
                selectedFilters
            },
            onSuccess: (response: BaseResponse<ContentExplorerResponse>) => {
                const firstLoadedContentsInSwiper = response.data.contents.length >= 5 ? 5 : response.data.contents.length;
                const isAbleTolLoadMoreContents = this.contents.length + response.data.contents.length < response.data.totalOfItems;
                const updatedContents = this.contents;

                updatedContents.push(...response.data.contents);

                this.isLoadingMoreContents = false;
  
                this.emit(() => {
                    this.isLoading = false;
                    this.contents = updatedContents;
                    this.isFollowAnyCreator = response.data.isFollowAnyCreator;
                    this.loadedContentInSwiper = this.loadedContentInSwiper === null ? response.data.contents.slice(0, firstLoadedContentsInSwiper) : this.loadedContentInSwiper;
                    this.isAbleToLoadMoreContents = isAbleTolLoadMoreContents;

                    if (this.quickFilters === undefined) {
                        this.quickFilters = response.data.quickFilters;
                    }

                    if (this.filters === undefined) {
                        this.filters = response.data.filters;
                    }
                });
            },
            onFailure: (error: string) => {
                this.isLoadingMoreContents = false;
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = this.contents.length === 0 ? 
                        {
                            message: error,
                            onRetryButtonTapped: () => this.loadContents(),
                        } : null;
                    this.snackbarMessage = this.contents.length !== 0 ? new ShowSnackbarModel({ message: error }) : null;
                });
            },
        });
    }

    loveContent(id: string, isLiked: boolean) {
        if (!localStorage.fpAuth) {
            this.emit(() => {
                this.popUpModel = PopUpWidgetModel.loginRequestPopUpModel;
            });

            return;
        }

        for (let index = 0; index < this.contents.length; index++) {
            if (this.contents[index].contentId === id) {
                this.contents[index].isLiked = isLiked;
                break;
            }
        }

        requestAPI(Endpoints.likeContent, {
            parameters: {
                contentId: id,
                isLiked: isLiked,
            },
            onSuccess: (response: {}) => {
                // TODO:
            },
            onFailure: (error: string) => {
                // TODO:
            },
        });
    }

    followCreator(id: string) {
        if (!localStorage.fpAuth) {
            this.emit(() => {
                this.popUpModel = PopUpWidgetModel.loginRequestPopUpModel;
            });

            return;
        }

        requestAPI(Endpoints.followCreator, {
            parameters: {
                sellerId: id,
                isFollowed: true,
            },
            onSuccess: (response: {}) => {
                // TODO:
            },
            onFailure: (error: string) => {
                // TODO:
            },
        });
    }

    onContentIndexChanged(index: number) {
        let numberOfItemsShouldLoaded = index + 5;

        if (numberOfItemsShouldLoaded > this.contents.length) {
            numberOfItemsShouldLoaded = this.contents.length;
        }

        if (numberOfItemsShouldLoaded > (this.loadedContentInSwiper?.length ?? 0)) {
            this.emit(() => {
                this.loadedContentInSwiper = this.contents.slice(0, numberOfItemsShouldLoaded);
                this.showingContentIndex = index;
            });
        } else {
            this.emit(() => this.showingContentIndex = index );
        }

        if (index > this.contents.length - 5 && !this.isLoadingMoreContents) {
            this.loadContents();
        }
    }

    onShareLink(value: string) {
        let splitted = value.split("/")
        eventManager.flush();
        eventManager.add({
          content_type: "content",
          item_id: "content_" + parseInt(splitted[splitted.length-1])
        })
        this.emit(() => this.shareUrlModel = new ShareUrlModel({ urlString: value }) );
    }

    onSelectQuickFilter(id: string) {
        if (this.selectedQuickFilterId === id) return;

        this.emit(() => {
            this.selectedQuickFilterId = id;
            this.isFilterEnabledToBeShown = false;

            if (this.filters) {
                for (let index = 0; index < (this.filters).length; index++) {
                    if (this.filters[index].productFilterId === id) {
                        this.isFilterEnabledToBeShown = true;
    
                        const filterModel = this.filters[index];
    
                        this.filterBasedOnProductModel = {
                            productFilterId: filterModel.productFilterId,
                            components: filterModel.components.map((model) => {
                                return {
                                    options: model.options.map((option) => {
                                        return {
                                            id: option.id,
                                            title: option.label,
                                            isSelected: false
                                        }
                                    }),
                                    title: model.title,
                                    isMultiple: model.isMultiple,
                                    componentId: model.componentId,
                                }
                            })
                        };
                        break;
                    }
                }
            }
        });

        this.emit(() => {
            this.isAbleToLoadMoreContents = true;
            this.contents = [];
            this.loadedContentInSwiper = null;
        });
        
        this.loadContents();
    }

    changeShowFilterModalStatus(isShown: boolean) {
        this.emit(() => {
            this.isFilterModalShown = isShown;
            if (isShown) this.tempFilterBasedOnProductModel = JSON.parse(JSON.stringify(this.filterBasedOnProductModel));
        });
    }

    saveTempFilterModel() {
        this.filterBasedOnProductModel = JSON.parse(JSON.stringify(this.tempFilterBasedOnProductModel));
        
        this.emit(() => {
            this.isFilterModalShown = undefined;
            this.isAbleToLoadMoreContents = true;
            this.contents = [];
            this.loadedContentInSwiper = null;
            this.loadContents();
        });
    }

    retryLoadContentWithResetFilter() {
        if (this.filterBasedOnProductModel) {
            this.filterBasedOnProductModel.components.forEach(
                (component) => component.options.forEach(
                    (option) => option.isSelected = false 
                )
            );
        }

        this.loadContents();
    }
}
