import React from "react";

type RadioButtonProps = {
    title?: string,
    className?: string,
    inputType?: string,
    isEnabled?: boolean,
    errorMessage?: string,
    isRequired?: boolean,
    value?: number,
    name?: string,
    action?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default class RadioButtonWidget extends React.Component<RadioButtonProps, {}> {
    render() {
        const isDisable = this.props.isEnabled === false;
        return (
            <>
                <input 
                    className={this.props.className}
                    type={this.props.inputType} 
                    required={this.props.isRequired} 
                    value={this.props.value}
                    name={this.props.name}
                    onChange={isDisable ? undefined : this.props.action} 
                >
                </input>
                <span>&nbsp;&nbsp;{this.props.title}</span>
            </>
        );
    }
}