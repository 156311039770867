import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import SellersListResponseModel from "./Models/SellerListsResponseModel";

export default class SellersListsViewModel extends ViewModel {
  isLoadingSellers?: boolean = false;
  sellers: {
    name?: string;
    imageUrl?: string;
    id: string;
  }[] = [];
  loadingSellersErrorMessage?: string;
  query?: string;
  fpAuth?: string;
  totalSellers: number;

  constructor() {
    super({key: 'SellersListsViewModel'});
    this.refresh();
    const params = new URLSearchParams(window.location.search);
    this.query = this.query ?? params.get("query") ?? undefined;
    this.fpAuth = localStorage.getItem("fpAuth") || undefined;
    this.totalSellers = 0;
  }

  loadSellers() {
    this.emit(() => {
      this.isLoadingSellers = true;
      this.loadingSellersErrorMessage = undefined;
    });

    requestAPI(Endpoints.searchSellers, {
      parameters: {
        fpAuth: this.fpAuth,
        query: this.query,
        startIndex: this.sellers.length - 1 < 0 ? 0 : this.sellers.length - 1,
        limit: 50,
      },
      onSuccess: (response: BaseResponse<SellersListResponseModel>) => {
        this.emit(() => {
          this.totalSellers = response.data.totalOfItems;
          this.sellers = this.sellers.concat(response.data.items);
          this.isLoadingSellers = false;
          this.loadingSellersErrorMessage = undefined;
        });
      },
      onFailure: (error: string) => {
        this.emit(() => {
          this.isLoadingSellers = false;
          this.loadingSellersErrorMessage = error;
        });
      },
    });
  }
}
