import React from "react";

export default function EmptyState() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        justifyContent: "center",
        marginTop: 92,
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <svg
            width="97"
            height="96"
            viewBox="0 0 97 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="0.5" width="96" height="96" rx="48" fill="#E7EDFF" />
            <path
              d="M50.5 34H54.5C56.1569 34 57.5 35.3431 57.5 37V65C57.5 66.6569 56.1569 68 54.5 68H32.5C30.8431 68 29.5 66.6569 29.5 65V37C29.5 35.3431 30.8431 34 32.5 34H36.5"
              stroke="#6677FF"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M49.7857 36L52.5 36C53.6046 36 54.5 36.8954 54.5 38V62C54.5 63.1046 53.6046 64 52.5 64H34.5C33.3954 64 32.5 63.1046 32.5 62V38C32.5 36.8954 33.3954 36 34.5 36L37.2143 36"
              stroke="#6677FF"
              stroke-width="2"
              stroke-linecap="round"
            />
            <rect
              x="37.5"
              y="32"
              width="12"
              height="5"
              rx="2"
              stroke="#6677FF"
              stroke-width="2"
            />
            <line
              x1="42.5"
              y1="52"
              x2="44.5"
              y2="52"
              stroke="#6677FF"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M36.5 48C36.5 48 37 49 38.5 49C40 49 40.5 48 40.5 48"
              stroke="#6677FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M46.5 48C46.5 48 47 49 48.5 49C50 49 50.5 48 50.5 48"
              stroke="#6677FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M60.5 35H63.5L60.5 38H63.5"
              stroke="#6677FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M63.5 28H67.5L63.5 32H67.5"
              stroke="#6677FF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <p
          style={{
            fontWeight: 600,
            fontSize: 18,
            marginTop: 12,
            color: "#999999",
          }}
        >
          No items to review
        </p>
      </div>
    </div>
  );
}
