import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowErrorModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { getPreloadProps } from "../../CommonComponents/Utils";
import ContentEventDetailResponse from "./Models/ContentEventDetailResponse";

export default class ContentEventDetailViewModel extends ViewModel {
    contentId: string;
    contentDetail: string = "";
    isCreator: boolean = false;
    creatorId: string = "";
    numberInCart: number = 0;
    model: ContentEventDetailResponse | null = null;
    isLoading: boolean = true;
    errorMessage: ShowErrorModel | null = null;

    constructor(contentId: string) {
        super();
        this.contentId = contentId;
    }

    loadPreloadingProps() {
        this.emit(() => { 
            this.isLoading = true;
         });

        getPreloadProps({
            onSuccess: (isCreator, creatorId, numberInCart) => {
                this.emit(() => {
                    this.isCreator = isCreator;
                    this.creatorId = creatorId;
                    this.numberInCart = numberInCart;
                });

                this.loadContentDetail();
            },
            onFailure: error => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = {
                        message: error,
                        onRetryButtonTapped: () => this.loadPreloadingProps(),
                    };
                });
            },
        });
    }

    loadContentDetail() {
        this.emit(() => {
            this.isLoading = true;
            this.errorMessage = null;
        });
        requestAPI(Endpoints.contentEventDetail, {
            parameters: {
                contentId: this.contentId,
            },
            onSuccess: (response: BaseResponse<ContentEventDetailResponse>) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.contentDetail = response.data.contentDetail;
                    this.model = response.data;
                });
            },
            onFailure: (error: string) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = {
                        message: error,
                        onRetryButtonTapped: () => this.loadContentDetail()
                    };
                });
            }
        });
    }
}