import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { getLocationQueries, getRawLocationQueriesString } from "../../CommonComponents/Utils";
import { AddressRemoveResponse } from "./Models/AddressRemoveResponse";
import { AddressSelectorItemModel, AddressSelectorResponse } from "./Models/AddressSelectorResponse";

export default class AddressSelectorViewModel extends ViewModel {
    isLoading: boolean = true;
    isLoadingCta: boolean = false;
    errorMessage?: string;
    snackbarError?: ShowSnackbarModel;
    addressList: AddressSelectorItemModel[] = [];
    selectedIndex?: number;

    loadList() {
        this.emit(() => {
            this.isLoading = true;
            this.errorMessage = undefined;
        });

        requestAPI(localStorage.fpAuth ? Endpoints.addressList : Endpoints.addressListNonLogin, {
            parameters: {
                deviceId: localStorage.uuid,
            },
            onSuccess: (response: BaseResponse<AddressSelectorResponse>) => {
                if (response.result) {
                    this.emit(() => {
                        this.isLoading = false;
                        this.addressList = response.data.addressList;

                        if (this.addressList.length > 0) {
                            this.selectedIndex = 0;
                        }
                    });
                } else {
                    this.emit(() => {
                        this.isLoading = false;
                        this.errorMessage = response.message;
                    });
                }
            },
            onFailure: (errorMessage) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.errorMessage = errorMessage;
                });
            }
        });
    }

    checkIsConfirmButtonActive(): boolean {
        if (this.selectedIndex !== undefined) {
            const selectedAddress = this.addressList[this.selectedIndex];

            return selectedAddress.hasPinpoint ? true : false;
        }

        return false;
    }

    deleteAddress(id: string, index: number) {
        this.emit(() => {
            this.isLoading = true;
            this.snackbarError = undefined;
        });

        requestAPI(localStorage.fpAuth ? Endpoints.addressRemove : Endpoints.addressRemoveNonLogin, {
            parameters: {
                shippingAddressId: id,
                deviceId: localStorage.uuid
            },
            onSuccess: (response: BaseResponse<AddressRemoveResponse>) => {
                if (response.result) {
                    if (this.selectedIndex && this.selectedIndex > 0 && this.selectedIndex >= index) {
                        this.selectedIndex = this.selectedIndex - 1;
                    }

                    this.emit(() => {
                        this.isLoading = false;
                        this.addressList = response.data.updatedAddressList;
                        this.selectedIndex = undefined;
                    });
                } else {
                    this.emit(() => {
                        this.isLoading = false;
                        this.snackbarError = new ShowSnackbarModel({
                            message: response.message
                        });
                    });
                }
            },
            onFailure: (errorMessage) => {
                this.emit(() => {
                    this.isLoading = false;
                    this.snackbarError = new ShowSnackbarModel({
                        message: errorMessage
                    });
                });
            }
        })
    }

    selectLocation() {
        this.emit(() => {
            this.isLoadingCta = true;
            this.snackbarError = undefined;
        });

        requestAPI(localStorage.fpAuth ? Endpoints.addressSelect : Endpoints.addressSelectNonLogin, {
            parameters: {
                shippingAddressId: this.addressList[this.selectedIndex ?? 0].shippingAddressId,
                orderId: getLocationQueries()["orderId"],
                deviceId: localStorage.uuid
            },
            onSuccess: (response: BaseResponse<{}>) => {
                if (response.result) {
                    this.emit(() => {
                        this.isLoadingCta = false;
                    });

                    const selectedAddress = this.addressList[this.selectedIndex ?? 0];
                    const oldQueries = getRawLocationQueriesString();
                    const locationHref = `/Checkout?locationId=${selectedAddress.shippingAddressId}&recepientName=${selectedAddress.name}&recepientAddress=${selectedAddress.address}&recepientPhone=${selectedAddress.phone}${oldQueries.length > 0 ? `&${oldQueries}` : ""}`;
                    window.location.href = locationHref;
                } else {
                    this.emit(() => {
                        this.isLoadingCta = false;
                        this.snackbarError = new ShowSnackbarModel({ message: response.message });
                    });
                }
            },
            onFailure: (errorMessage) => {
                this.emit(() => {
                    this.isLoadingCta = false;
                    this.snackbarError = new ShowSnackbarModel({ message: errorMessage });
                });
            }
        })
    }
}