export enum OrderType {
  TO_PAY = "TO_PAY",
  PACKING = "PACKING",
  IN_DELIVERY = "IN_DELIVERY",
  TO_REVIEW = "TO_REVIEW",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
}

export type OrderModel = {
  orderId: string;
  timeLabel: string;
  type: OrderType;
  subTotal: number;
  status: string;
  secondStatus?: string;
  isPostedToFeed: boolean;
  badgeUrls: string[];
  moreInfo: string;
  orderTotal: number;
  product: {
    productId: string;
    bagItemId: string;
    imageUrl: string;
    brand: string;
    name: string;
    attribute: string;
  };
  warning?: string;
  bagItemIds?: string[];
  reviewPoint?: number;
  postFeedPoint?: number;
  postFeedCommission?: number;
  whatsappUrl: string;
};

export type OrderAndDeliveryResponseModel = {
  totalItems?: number;
  categories: {
    id: string;
    label: string;
    isThereNew: boolean;
  }[];
  orders: OrderModel[];
};
