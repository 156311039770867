import React from "react";

export interface MyPageNumberOfOrdersDetailWidgetProps {
  id?: string;
  label: string;
  isSomethingNew: boolean;
  value: number;
}
export default class MyPageNumberOfOrdersDetailWidget extends React.Component<MyPageNumberOfOrdersDetailWidgetProps> {
  render() {
    return (
      <div style={{ position: "relative" }}>
        <p
          style={{
            position: "relative",
            color:
              this.props.value > 0 ? "var(--blueGrey)" : "var(--lightGrey)",
          }}
        >
          {this.props.value}{" "}
          {this.props.isSomethingNew && (
            <span
              style={{
                position: "absolute",
                top: 0,
                borderRadius: 999,
                overflow: "hidden",
                right: -12,
                backgroundColor: "#894CF5",
                height: 6,
                width: 6,
              }}
            ></span>
          )}
        </p>
        <p>{this.props.label}</p>
      </div>
    );
  }
}
