import { EventManager } from "./EventManager";

const eventManager = new EventManager();

export type ItemEventEntity = {
  id?: string;
  name?: string;
  brand?: string;
  price?: {
    net?: number;
    discount?: number;
  };
  variant?: string;
  quantity?: number;
};

export { eventManager };
