import { useNavigate } from "react-router-dom";

interface BackButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  children?: string | JSX.Element | JSX.Element[];
}

const BackButton = ({children, ...props} : BackButtonProps) => {
  const navigate = useNavigate();

  return (
    <button {...props}
      onClick={() => {
        navigate(-1);
      }}
    >
     {children}
    </button>
  );
};

export default BackButton;