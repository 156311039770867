import "./VideoPlayer.css";

import ReactHlsPlayer from "react-hls-player/dist";
import { useEffect, useRef, useState } from "react";

export function VideoPlayer(props) {
    const [videoPlayerId, _] = useState(props.videoHtmlId ?? `VideoPlayerNonController_${`${Math.random()}`.replace("0.", "")}`);
    const ref = useRef();

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            const videoElement = document.getElementById(videoPlayerId);
            if (entry.isIntersecting) {
                videoElement.play();
            } else {
                videoElement.pause();
            }
        });

        if (ref.current) observer.observe(ref.current);

        return () => {
            if (ref.current) observer.unobserve(ref.current);
        }
    }, [ref]);

    return <div ref={ref} key={props.videoUrl} style={{ width: "100%", height: "100%", position: "relative" }}>
        <ReactHlsPlayer
            className="VideoPlayer"
            id={videoPlayerId}
            src={props.videoUrl}
            muted={true}
            loop={props.loop ?? true}
            playsInline={true}
            poster={props.videoThumbUrl}
            style={props.style}
            autoPlay={props.forcePlay}
        />
    </div>;
}