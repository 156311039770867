import React from "react";
import ProductSummaryItemModel from "../../Models/ProductSummaryItemModel";
import { convertToRupiah } from "../../Utils";
import { ImageWidget } from "../ImageWidget/ImageWidget";
import "./ProductSummaryWithVideoItemCellWidget.css";
import { VideoPlayer } from "./VideoPlayer";

type ProductSummaryWithVideoItemCellWidgetProps = {
    sellerId?: string,
    model: ProductSummaryItemModel,
    leadingIndex?: string,
    isPlayingVideo: boolean,
    cellWidth: number
    theme?: string
}

export default class ProductSummaryWithVideoItemCellWidget extends React.Component<ProductSummaryWithVideoItemCellWidgetProps, {}> {
    render() {
        const model = this.props.model;
        const sellerId = this.props.sellerId;
        const leadingIndex = this.props.leadingIndex ?? (model.rank ? `${model.rank}` : undefined);

        return (
            <div 
                key={`VideoPlayerContainer_${model.videoUrl}_${this.props.isPlayingVideo}`}
                id="ProductSummaryWithVideoItemContainer"
                style={{
                    width: this.props.cellWidth ? `${this.props.cellWidth}px` : "inherit"
                }}
                onClick={() => model.ageRestricted !== true ? window.location.href = `/Product/${model.id}${sellerId !== undefined ? `?creator=${sellerId}` : ""}` : this.showAgeRestrictedModal(model.id, sellerId)}
            >
                <div 
                    key={model.id} 
                    style={{
                        height: this.props.cellWidth ? `${this.props.cellWidth}px` : "inherit", 
                        borderRadius:"8px", 
                        overflow:"hidden", 
                        marginBottom:"4px"
                    }}
                >
                    {
                        this.props.isPlayingVideo && this.props.model.videoUrl ?
                        <VideoPlayer 
                            videoUrl={this.props.model.videoUrl}
                            videoThumbUrl={this.props.model.videoImageThumbnailUrl ?? this.props.model.imageUrl}
                            style={{
                                borderRadius: "10px"
                            }}
                        /> :
                        <ImageWidget imageUrl={this.props.model.videoImageThumbnailUrl ?? this.props.model.imageUrl} />
                    }
                    {
                        leadingIndex &&
                        <div id="ProductSummaryWithVideoItemCellLeadingLabel">
                            <p>{leadingIndex}</p>
                        </div>
                    }
                </div>
                <p style={{color:this.props.theme === "DARK" ? "white" : undefined}}>
                    {model.brand}
                </p>
                <p style={{color:this.props.theme === "DARK" ? "white" : undefined}}>
                    {model.title}
                </p>
                <p
                    style={
                        model.price.discount > 0 ? 
                        {
                            color:"#F14100",
                            fontWeight:"bold"
                        } : 
                        undefined
                    }
                >{convertToRupiah(model.price.netPrice)}</p>
                {
                    model.price.discount > 0 &&
                    <p>
                        <span>{convertToRupiah(model.price.realPrice)}</span>  <span>-{model.price.discount}%</span>
                    </p>
                }
                {
                    model.footnote !== undefined &&
                    <p id="ProductSummaryWithVideoFooterLabel" style={{color:"var(--mediumGrey)"}}>
                        {model.footnote ?? ""}
                    </p>
                }
            </div>
        );
    }

    showAgeRestrictedModal(productId: string, sellerId?: string){
        const scaffold = document.querySelectorAll("#Scaffold")[0];
        scaffold.appendChild(document.createElement('div')).className = "modal-backdrop show";
        const append = scaffold.appendChild(document.createElement('div'));
        append.className = "modal fade show";
        append.tabIndex = 1;
        const htmldata = `
        <div class="modal-dialog" role="document" id="AgeRestrictedModal"> 
            <div class="modal-content"> 
                <div class="modal-body"> 
                    <p><b>Age Confirmation</b></p>
                    <span>You must be over 21 to access this items</span>
                <div class="row" style="margin-top:36px;"> 
                    <div class="col text-center">
                        <button id="GoButton" class="btn btn-secondary">I’m over 21</button>
                    </div>
                    <div class="col text-center">
                        <button id="CancelButton" data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        `;
        append.innerHTML = htmldata;
        const GoButton = document.getElementById("GoButton");
        const cancelButton = document.getElementById("CancelButton");

        if (GoButton) {
            GoButton.onclick = (event) => {
                return window.location.href = `/Product/${productId}${sellerId !== undefined ? `?creator=${sellerId}` : ""}`;
            }
        }
        if (cancelButton) {
            cancelButton.onclick = (event) => {
                return this.closeAgeRestrictedModal();
            }
        }
    }

    closeAgeRestrictedModal(){
        const modal = document.querySelectorAll(".modal")[0];
        modal.remove();
        const modalBackdrop = document.querySelectorAll(".modal-backdrop")[0];
        modalBackdrop.remove();
    }
}