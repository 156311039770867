import React from "react";
import ic_pinpoint_purple from "../../../Assets/Images/ic_pinpoint_purple.svg";
import ic_no_pinpoint_blue from "../../../Assets/Images/ic_no_pinpoint_blue.svg";
import { getRawLocationQueriesString } from "../../../CommonComponents/Utils";

interface AddressSelectorLocationCellProps {
    id: string;
    name: string;
    address: string;
    phone: string;
    hasPinPoint: boolean;
    isSelected: boolean;
    onClick: () => void;
    onDeleteButtonClicked: () => void;
}

export default class AddressSelectorLocationCell extends React.Component<AddressSelectorLocationCellProps> {
    render() {
        const selectedClassName = this.props.isSelected ? "Selected" : "Unselected";

        return <div id="AddressSelectorLocationCell" className={selectedClassName} onClick={() => {
            if (this.props.hasPinPoint) {
                this.props.onClick();
            } else {
                window.location.href = `/Checkout/SearchLocation?id=${this.props.id}&mode=setPinPointOnly&${getRawLocationQueriesString()}`;
            }
        }}>
            <div id="RadioButton" className={selectedClassName}>
                <div id="RadioButtonInnerCircle" className={selectedClassName} />
            </div>
            <div id="AddressSelectorDetailContainer">
                <div>
                    <p style={{fontSize: "14px", fontWeight: "500", flexGrow: 1, color: "black"}}>{this.props.name}</p>
                    <button onClick={(event) => {
                        event.stopPropagation();
                        window.location.href = `/Checkout/AddressUpdater?id=${this.props.id}&${getRawLocationQueriesString()}`;
                    }}>
                        Modify
                    </button>
                    <div />
                    <button onClick={(event) => {
                        event.stopPropagation();
                        this.props.onDeleteButtonClicked();
                    }}>
                        Delete
                    </button>
                </div>
                <p style={{fontSize: "11px"}}>{this.props.address}</p>
                <p style={{fontSize: "11px"}}>{this.props.phone}</p>
                <div style={{display: "flex", columnGap: "4px"}}>
                    {
                        this.props.hasPinPoint ?
                        <>
                            <img src={ic_pinpoint_purple} alt="" />
                            <p style={{fontSize: "12px", fontWeight: "bold", color: "var(--blueGrey)"}}>Has Pinpoint</p>
                        </> :
                        <>
                            <img src={ic_no_pinpoint_blue} alt="" />
                            <p style={{fontSize: "12px", fontWeight: "bold", color: "#364DFF"}}>Set Pinpoint</p>
                        </>
                    }
                </div>
            </div>
        </div>;
    }
}