import React from "react";
import ProgressBar from "./ProgressBar";
import "./ReviewSummaryWidget.css";

export type ReviewSummaryWidgetProps = {
  score?: number;
  maxScore?: number;
  reviewCount?: number;
  starDistribution?: {
    "5"?: number;
    "4"?: number;
    "3"?: number;
    "2"?: number;
    "1"?: number;
  };
};

export default class ReviewSummaryWidget extends React.Component<ReviewSummaryWidgetProps> {
  generateStars(fill: number = 0, n: number = 0, sizeFactor = 2) {
    return Array.from({ length: n }).map((_, index) => (
      <svg
        style={{ marginRight: 4 }}
        xmlns="http://www.w3.org/2000/svg"
        width={9 * sizeFactor}
        height={9 * sizeFactor}
        viewBox="0 0 9 9"
      >
        <path
          d="M4.5 7.52 2.27 8.677a.4.4 0 0 1-.578-.42l.411-2.478L.314 4.016a.4.4 0 0 1 .221-.68L3.02 2.96 4.142.715a.4.4 0 0 1 .716 0L5.98 2.961l2.484.375a.4.4 0 0 1 .22.68L6.898 5.78l.411 2.477a.4.4 0 0 1-.579.42L4.5 7.52z"
          fill={index + 1 > (fill || 0) ? "#E4E4E4" : "#894CF5"}
          fill-rule="evenodd"
        />
      </svg>
    ));
  }

  render(): React.ReactNode {
    return (
      <div>
        <div className="d-flex" style={{ alignItems: "center" }}>
          <div
            className="d-flex items-center justify-center"
            style={{ flexDirection: "column" }}
          >
            <div>
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <span className="font20" style={{ fontWeight: 700 }}>
                  {this.props.score}
                </span>
                <span className="font12">/</span>
                <span className="font12">{this.props.maxScore}</span>
              </div>
              <div style={{ display: "flex", marginTop: 4 }}>
                {Array.from({ length: 5 }).map((_, index) => (
                  <svg
                    style={{ marginRight: 4 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 9 9"
                  >
                    <path
                      d="M4.5 7.52 2.27 8.677a.4.4 0 0 1-.578-.42l.411-2.478L.314 4.016a.4.4 0 0 1 .221-.68L3.02 2.96 4.142.715a.4.4 0 0 1 .716 0L5.98 2.961l2.484.375a.4.4 0 0 1 .22.68L6.898 5.78l.411 2.477a.4.4 0 0 1-.579.42L4.5 7.52z"
                      fill={
                        index + 1 > (this.props.score || 0)
                          ? "#E4E4E4"
                          : "#894CF5"
                      }
                      fill-rule="evenodd"
                    />
                  </svg>
                ))}
              </div>
              <div
                className="font12 mt-2"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                ({this.props.reviewCount} ulasan)
              </div>
            </div>
          </div>
          <div
            style={{
              flexShrink: 0,
              height: "100%",
              borderRight: "solid 1px #f2f2f2",
              marginRight: 16,
              marginLeft: 16,
            }}
          />
          <div className="flex-grow-1">
            <div className="row">
              <div className="d-flex" style={{ alignItems: "center" }}>
                <div>{this.generateStars(5, 5, 1)}</div>
                <div style={{ flex: 1, marginLeft: 16, marginRight: 16 }}>
                  <ProgressBar
                    max={this.props.maxScore}
                    progress={
                      (this.props.starDistribution?.["5"] || 0 || 0) /
                      (this.props.reviewCount || 0)
                    }
                  />
                </div>
                <div>
                  <span style={{ fontSize: 12 }}>
                    {this.props.starDistribution?.["5"] || 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex" style={{ alignItems: "center" }}>
                <div>{this.generateStars(4, 5, 1)}</div>
                <div style={{ flex: 1, marginLeft: 16, marginRight: 16 }}>
                  <ProgressBar
                    max={this.props.maxScore}
                    progress={
                      (this.props.starDistribution?.["4"] || 0 || 0) /
                      (this.props.reviewCount || 0)
                    }
                  />
                </div>
                <div>
                  <span style={{ fontSize: 12 }}>
                    {this.props.starDistribution?.["4"] || 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex" style={{ alignItems: "center" }}>
                <div>{this.generateStars(3, 5, 1)}</div>
                <div style={{ flex: 1, marginLeft: 16, marginRight: 16 }}>
                  <ProgressBar
                    max={this.props.maxScore}
                    progress={
                      (this.props.starDistribution?.["3"] || 0 || 0) /
                      (this.props.reviewCount || 0)
                    }
                  />
                </div>
                <div>
                  <span style={{ fontSize: 12 }}>
                    {this.props.starDistribution?.["3"] || 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex" style={{ alignItems: "center" }}>
                <div>{this.generateStars(2, 5, 1)}</div>
                <div style={{ flex: 1, marginLeft: 16, marginRight: 16 }}>
                  <ProgressBar
                    max={this.props.maxScore}
                    progress={
                      (this.props.starDistribution?.["2"] || 0 || 0) /
                      (this.props.reviewCount || 0)
                    }
                  />
                </div>
                <div>
                  <span style={{ fontSize: 12 }}>
                    {this.props.starDistribution?.["2"] || 0}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex" style={{ alignItems: "center" }}>
                <div>{this.generateStars(1, 5, 1)}</div>
                <div style={{ flex: 1, marginLeft: 16, marginRight: 16 }}>
                  <ProgressBar
                    max={this.props.maxScore}
                    progress={
                      (this.props.starDistribution?.["1"] || 0 || 0) /
                      (this.props.reviewCount || 0)
                    }
                  />
                </div>
                <div>
                  <span style={{ fontSize: 12 }}>
                    {this.props.starDistribution?.["1"] || 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
