import React from "react";
import "./ProgressBar.css";

export type ProgressBarProps = {
  progress?: number;
};

export default class ProgressBar extends React.Component<ProgressBarProps> {
  render(): React.ReactNode {
    const { progress = 0 } = this.props;

    return (
      <div id="ProgressBarContainer">
        <div id="ProgressBarFill" style={{ right: `${(100 - progress * 100)}%` }}></div>
      </div>
    );
  }
}
