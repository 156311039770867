import React from "react";
import "./OrderTimerWidget.css";

export type OrderTimerWidgetProps = {
  seconds?: number;
};

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

function OrderTimerWidget(props: OrderTimerWidgetProps) {
  // const {seconds = 0} = props

  const [initialSeconds, setInitialSeconds] = React.useState<number>(props.seconds || 0);

  React.useEffect(() => {
    const handle = setInterval(() => {
      if (initialSeconds > 0) {
        setInitialSeconds((s) => s - 1);
      }
    }, 1000);

    return () => clearInterval(handle);
  }, []);

  const hours = Math.floor(initialSeconds / 3600);
  const minutes = Math.floor((initialSeconds % 3600) / 60);
  const seconds = Math.floor(initialSeconds % 60);

  return (
    <div className="OrderTimerWidgetContainer">
      {zeroPad(hours, hours < 10 ? 2 :1 )} : {zeroPad(minutes, minutes < 10 ? 2 : 1)} :{" "}
      {zeroPad(seconds, seconds < 10 ? 2 :1 )}
    </div>
  );
}

export default OrderTimerWidget;
