import ic_play from "../../../Assets/Images/ic_video_play.svg";
import ic_mute from "../../../Assets/Images/ic_mute.svg";
import ic_unmute from "../../../Assets/Images/ic_unmute.svg";

import ReactHlsPlayer from "react-hls-player/dist";
import { useEffect, useRef, useState } from "react";

export function VideoPlayerWithController(props) {
    let [isPlaying, setIsPlaying] = useState(true);
    let isCurrentlyOnPlayingMode = props.isPlaying;
    let isMuted = true;
    let isAudioAvailable = props.isAudioAvailable ?? false;

    const ref = useRef();
    const videoPlayerId = `VideoPlayer_${props.videoUrl}`;
    const videoPlayerPlayButtonId = `${videoPlayerId}_PlayButton`;

    const videoPlayerMuteButtonId = `${videoPlayerId}_MuteButton`;
    const _handlePlayingAction = () => {
        const videoElement = document.getElementById(videoPlayerId);
        const playButton = document.getElementById(videoPlayerPlayButtonId);

        isPlaying && isCurrentlyOnPlayingMode ? videoElement.play() : videoElement.pause();

        playButton.style.opacity = isCurrentlyOnPlayingMode ? 0 : 1;
    }
    const _handleMuteAction = () => {
        const videoElement = document.getElementById(videoPlayerId);
        const muteButton = document.getElementById(videoPlayerMuteButtonId);

        videoElement.muted = isMuted;

        muteButton.src = isMuted ? ic_mute : ic_unmute;
    };
    const _changeIsPlayingCondition = () => {
        _handlePlayingAction();
    }

    useEffect(() => {
        const currentRef = ref.current;
        const observer = new IntersectionObserver(([entry]) => {
            setIsPlaying(entry.isIntersecting);
            _handlePlayingAction();
        });

        if (currentRef) observer.observe(currentRef);

        return () => {
            if (currentRef) observer.unobserve(currentRef);
        }
    });

    return (
        <div ref={ref} key={props.videoUrl} style={{ backgroundColor: "black", width: "100%", height: "100%", position: "relative" }}>
            <ReactHlsPlayer
                className="VideoPlayer"
                id={videoPlayerId}
                src={props.videoUrl}
                muted={true}
                loop={props.loop ?? true}
                playsInline={true}
                poster={props.videoThumbUrl}
                style={props.style}
            />
            {props.children}
            <button
                id="VideoPlayerPlayButtonContainer"
                onClick={() => {
                    isCurrentlyOnPlayingMode = !isCurrentlyOnPlayingMode;
                    _changeIsPlayingCondition();
                }}
            >
                <img id={videoPlayerPlayButtonId} src={ic_play} alt="" />
            </button>
            {
                isAudioAvailable &&
                <button
                    id="VideoPlayerMuteButtonContainer"
                    onClick={() => {
                        isMuted = !isMuted;
                        _handleMuteAction();
                    }}
                >
                    <img id={videoPlayerMuteButtonId} src={ic_mute} alt="" />
                </button>
            }
        </div>
    );
}