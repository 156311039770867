import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import BaseViewModel from "../../CommonComponents/Base/BaseViewModel";
import RegisterResponse from "./Models/RegisterResponse";

export default class RegisterPasswordViewModel extends BaseViewModel {
    password: string = '';
    confirm_password: string = '';
    isLoadingButtonSubmit: boolean = false;
    errorMessage: string | null = null;
    isSuccess: boolean = false;
    errorMessagePassword: string = '';
    errorMessageConfirmPassword: string = '';

    onPasswordChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        this.updateState({errorMessagePassword: null})        
        
        if(val.length >= 8) {
            this.updateState({errorMessagePassword: ''})
            this.updateState({
                password: val
            })
        }
        else {
            this.updateState({errorMessagePassword: 'At least 8 characters'})
            this.updateState({
                password: ''
            })
        }
    }

    onConfirmPasswordChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        this.updateState({errorMessageConfirmPassword: null})

        if(val === this.password) {
            this.updateState({errorMessageConfirmPassword: ''})
            this.updateState({
                confirm_password: val
            })
        }
        else {
            this.updateState({errorMessageConfirmPassword: 'The password do not match the password above'})
            this.updateState({
                confirm_password: ''
            })
        }

        this.updateState({
            confirm_password: val
        })
    }

    onSnackbarIsFinished() {
        this.updateState({errorMessage: null})
    }

    onSubmitPassword() {
        this.updateState({isLoadingButtonSubmit: true})
        requestAPI(Endpoints.registerPassword, {
            parameters: {
                input_email_telp: localStorage.input_email_telp,
                password: this.confirm_password,
                svc_id : '101'
            },
            onSuccess: (response: BaseResponse<RegisterResponse>) => {
                this.updateState({isSuccess: true});
            },
            onFailure: (error) => {
                this.updateState({errorMessage: error});
            }
        });
    }

    override copy({
        password = this.password,
        confirm_password = this.confirm_password,
        isLoadingButtonSubmit = this.isLoadingButtonSubmit,
        errorMessage = this.errorMessage,
        errorMessagePassword = this.errorMessagePassword,
        errorMessageConfirmPassword = this.errorMessageConfirmPassword,
        isSuccess = this.isSuccess,
    }): this {
        this.password = password;
        this.confirm_password = confirm_password;
        this.isLoadingButtonSubmit = isLoadingButtonSubmit;
        this.errorMessage = errorMessage;
        this.errorMessagePassword = errorMessagePassword;
        this.errorMessageConfirmPassword = errorMessageConfirmPassword;
        this.isSuccess = isSuccess;

        return this;
    }
}