import React from "react";
import { convertToRupiah } from "../../../CommonComponents/Utils";

interface Props {
    isSelected: boolean;
    courierImageUrl: string;
    title: string;
    price: {
        netPrice: number;
        priceBeforeDiscount?: number;
    };
    onClick: () => void;
}

export default class CourierSelectorItemCell extends React.Component<Props> {
    render() {
        const selectedStatusClassName = this.props.isSelected ? "Selected" : "Unselected";

        return <button id="CourierSelectorItemCell" className={selectedStatusClassName} onClick={this.props.onClick}>
            <div id="RadioButton" className={selectedStatusClassName}>
                <div id="RadioButtonInnerCircle" className={selectedStatusClassName} />
            </div>
            <div>
                <div id="CourierSelectorItemCellTitleContainer">
                    <img src={this.props.courierImageUrl} alt="" /><p>{this.props.title}</p>
                </div>
                <p>{convertToRupiah(this.props.price.netPrice)} {this.props.price.priceBeforeDiscount && <span style={{color: "var(--lightGrey)", textDecoration: "line-through"}}>{convertToRupiah(this.props.price.priceBeforeDiscount)}</span>}</p>
            </div>
        </button>;
    }
}