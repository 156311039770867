import React from "react";
import { getScreenSizeWithMaxWidth } from "../Utils";
import BaseViewModel from "./BaseViewModel";

interface BaseState<T extends BaseViewModel> {
    viewModel: T;
    width: number;
    height: number;
}

export default class BaseUIComponent<A, B extends BaseViewModel> extends React.Component<A, BaseState<B>> {
    state: BaseState<B>;
    
    constructor(props: A, viewModel: B) {
        super(props)

        const screenSize = getScreenSizeWithMaxWidth();
        
        this.state = {
            viewModel: viewModel,
            width: screenSize.width,
            height: screenSize.height
        };

        window.addEventListener("resize", this.updateSize);
        this.viewModel().updateState = this.updateState;
    }

    viewModel(): B {
        return this.state.viewModel;
    }

    private updateState = (args: {}) => {
        this.setState({
            viewModel: this.state.viewModel.copy(args),
            width: this.state.width,
            height: this.state.height
        });
    }

    private updateSize = () => {
        const screenSize = getScreenSizeWithMaxWidth();
        
        this.setState({
            viewModel: this.state.viewModel,
            width: screenSize.width,
            height: screenSize.height
        });
    }
}