import { eventManager } from "../../Analytics/Events/Common";
import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import ProductSummaryItemModel from "../../CommonComponents/Models/ProductSummaryItemModel";
import { ShowErrorModel, ShowSnackbarModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { ShareUrlModel } from "../../CommonComponents/UI/ShareUrlPopUp/Models/ShareUrlModel";
import { getPreloadProps, productTypesForBadgeUrls } from "../../CommonComponents/Utils";
import { ProductBadgeType } from "../../CommonTypes";
import StoreBrandProductsResponse from "./Models/StoreBrandProductsResponse";

export default class StoreBrandProductsViewModel extends ViewModel {
    numberInCart: number = 0;
    isPreLoading: boolean = true;
    isLoading: boolean = true;
    errorMessage: ShowErrorModel | null = null;
    errorSnackbar: ShowSnackbarModel | null = null;
    coverImages: string[] = [];
    brandImageUrl: string = "";
    brandName: string = "";
    brandDescription: string = "";
    brandLink: string = "";
    totalOfItems: number = 0;
    products: ProductSummaryItemModel[] = [];
    showingIndex: number = 0;
    navBarTransparency: number = 0;
    shareUrlModel: ShareUrlModel | null = null;
    badgeUrlsMap?: {[key in ProductBadgeType]: string};

    secondaryCategories?: {
      id?: string;
      label?: string;
    }[] = [];
    primaryCategories?: {
      id?: string;
      label?: string;
    }[] = [];
    sorts?: {
      id: string;
      label: string;
    }[] = [];
    shouldShowSortModal = false;
    
    url = new URL(window.location.href)
    urlParams = new URLSearchParams(window.location.search);
    primaryCategoryId = this.urlParams.get("primaryCategoryId");
    secondaryCategoryId = this.urlParams.get("secondaryCategoryId");
    sortId = this.urlParams.get("sortId");

    selectedPrimaryCategoryId?: string | null = this.primaryCategoryId || "ALL";
    selectedSecondaryCategoryId?: string | null = this.secondaryCategoryId || "ALL";
    selectedSortId?: null | string = this.sortId || "1";

    private brandId: string;
    private limit: number = 24;

    constructor(brandId: string) {
        super({key: 'StoreBrandProductsViewModel_' + brandId});
        this.brandId = brandId;
        this.refresh();
    }

    showSortModal() {
      this.emit(() => {
        this.shouldShowSortModal = true
      })
    }

    hideSortModal() {

      this.emit(() => {
        this.shouldShowSortModal = false
      })
    }

    updateUrl() {
      // eslint-disable-next-line no-restricted-globals
      history.pushState({},"", this.url.toString().split("?")[0] 
      + `${this.urlParams.toString() ? "?" + this.urlParams.toString() : ""}`)
    }

    selectSortId(id?: string) {
      this.emit(() => {
        this.products = [];
        this.totalOfItems = 0;
        if (id) {
          this.selectedSortId = id
        }
      })
      this.loadProducts()
      if(id) this.urlParams.set("sortId", id);
      if(id === "1") this.urlParams.delete("sortId")
      this.updateUrl();
    }
    
    selectPrimaryCatId(id?: string) {
      this.emit(() => {
        this.products = []
        this.totalOfItems = 0;
        this.selectedPrimaryCategoryId = id
        this.selectedSecondaryCategoryId = "ALL";
      })
      this.loadProducts()
      if(id) this.urlParams.set("primaryCategoryId", id);
      if(id === "ALL") this.urlParams.delete("primaryCategoryId")
      this.updateUrl();
    }

    selectSecondaryCatId(id?: string) {
      this.emit(() => {
        this.selectedSecondaryCategoryId = id
        this.products = []
        this.totalOfItems = 0;
      })
      this.loadProducts()
      if(id) this.urlParams.set("secondaryCategoryId", id);
      if(id === "ALL") this.urlParams.delete("secondaryCategoryId")
      this.updateUrl();
    }

    loadPreloadingProps() {
        this.emit(() => this.isPreLoading = true);

        getPreloadProps({
            onSuccess: (_, __, numberInCart, badgeUrlsMap) => {
                this.numberInCart = numberInCart;
                this.badgeUrlsMap = badgeUrlsMap;

                this.emit(() => {});

                this.loadProducts();
            },
            onFailure: (error) => {
                this.isPreLoading = true;
                this.errorMessage = {
                    message: error,
                    onRetryButtonTapped: () => this.loadPreloadingProps()
                };

                this.emit(() => {});
            }
        })
    }

    loadProducts() {
        this.isLoading = true;

        this.emit(() => {});

        requestAPI(Endpoints.brandProducts, {
            parameters: {
                brandId: this.brandId,
                limit: this.limit,
                startIndex: this.products.length,
                secondaryCategoryId: this.selectedSecondaryCategoryId,
                primaryCategoryId: this.selectedPrimaryCategoryId,
                sortId: this.selectedSortId || this.sorts?.[0]?.id
            },
            onSuccess: (response: BaseResponse<StoreBrandProductsResponse>) => {
                this.isLoading = false;
                this.isPreLoading = false;
                
                this.coverImages = response.data.coverImages;
                this.brandImageUrl = response.data.brandImageUrl;
                this.brandName = response.data.brandName;
                this.brandDescription = response.data.brandDescription;
                this.brandLink = response.data.brandLink;
                this.totalOfItems = response.data.totalOfItems;
                // @ts-ignore
                this.products.push(...response.data.items.map(i => ({...i, badgeUrls: productTypesForBadgeUrls(i.badgeTypes, this.badgeUrlsMap)})));
                this.primaryCategories = response.data.primaryCategories;
                this.secondaryCategories = response.data.secondaryCategories;
                this.sorts = response.data.sorts;

                this.emit(() => {});
            },
            onFailure: (error: string) => {
                this.isLoading = false;
                this.errorMessage = {
                    message: error,
                    onRetryButtonTapped: () => this.loadProducts()
                };

                this.emit(() => {});
            }
        });
    }

    updateActiveIndex(value: number) {
        this.showingIndex = value;

        this.emit(() => {});
    }

    changeNavBarTransparency(value: number) {
        this.navBarTransparency = value;
        this.emit(() => {});
    }

    onShareLink() {
        eventManager.flush();
        eventManager.add({
          content_type: "brand",
          item_id: "brand_" + this.brandId
        })
        this.shareUrlModel = new ShareUrlModel({ urlString: window.location.href});
        this.emit(() => {});
    }
}