import React from "react";

type LoginPopupMessageProps = {
    showModal?: boolean,
    isEnabled?: boolean,
    closeModal?: () => void
}

export default class LoginPopupMessageWidget extends React.Component<LoginPopupMessageProps, {}> {
    render() {
        return (
            <>
                <div className={this.props.showModal ? "modal-backdrop fade show" : ""}></div>
                <div className={this.props.showModal ? "modal fade show" : "modal fade"} style={this.props.showModal ? {display:'block'} : {display:'none'}} id="LoginModal" aria-labelledby="ErrorModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="row">
                                <div className="col">
                                    <p>Please Sign In to Continue</p>
                                    <span>Please Sign In. You need to Sign in to use this function</span>
                                    <br></br><br></br>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col text-center">
                                    <button data-bs-dismiss="modal" aria-label="Close" onClick={this.props.closeModal} className="btn btn-secondary">Cancel</button>
                                </div>
                                <div className="col text-center">
                                    <a href="/Login"><button className="btn btn-primary">Sign In</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}