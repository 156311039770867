import React from "react"

import "./LoginRequestPage.css";

import ic_error_light from "../../../Assets/Images/ic_error_pink.svg";
import ic_error_dark from "../../../Assets/Images/ic_error_dark_pink.svg";

type LoginRequestPageWidgetProps = {
    theme?: "dark" | "light"
}

export default class LoginRequestPageWidget extends React.Component<LoginRequestPageWidgetProps, {}> {
    render() {
        const isDark = this.props.theme === "dark";

        return (
            <div className="LoginRequestBackground" id={isDark ? "LoginRequestDark" : "LoginRequestLight"}>
                <img src={isDark ? ic_error_dark : ic_error_light} alt="" />
                <p>Please Sign In to Continue</p>
                <p>Please Sign In. You need to Sign In to use this function.</p>
                <button onClick={() => {
                    window.location.href = "/Login"
                }}>
                    Login
                </button>
                <div className="LoginRequestBackground" id={isDark ? "LoginRequestDark" : "LoginRequestLight"}>
                <img src={isDark ? ic_error_dark : ic_error_light} alt="" />
                <p>Please Sign In to Continue</p>
                <p>Please Sign In. You need to Sign In to use this function.</p>
                <button onClick={() => {
                    window.location.href = "/Login"
                }}>
                    Login
                </button>
                  <p onClick={() => {
                      window.location.href = "/TermService"
                    }} style={{
                      marginTop: 28,
                      fontStyle: "normal",
                      fontWeight: 400,
                      display: 'block',
                      fontSize: "11.5px",
                      lineHeight: "17px",

                      /* achromatic/medium grey */

                      color: "#999999"}}
                    >
                    Terms of Service
                    </p>
                    <p onClick={() => {
                      window.location.href = "/PrivacyPolicy"
                    }} style={{
                      marginTop: 16,
                      fontStyle: "normal",
                      display: 'block',
                      fontWeight: 400,
                      fontSize: "11.5px",
                      lineHeight: "17px",

                      /* achromatic/medium grey */

                      color: "#999999"}}
                    >
                   Privacy Policy
                    </p>
            </div>
          
            </div>
        );
    }
}