import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import ReactPixel from "react-facebook-pixel";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import {
  ShowErrorModel,
  ShowSnackbarModel,
} from "../../CommonComponents/UI/Scaffold/Scaffold";
import { getPreloadProps, isResellerDomain } from "../../CommonComponents/Utils";
import OrderReceiptResponse from "../../Store/OrderReceipt/Models/OrderReceiptResponse";
import TrackingResponse from "../OrderAndDelivery/Models/TrackingResponse";
import OrderDetailResponse from "./Models/OrderDetailResponse";

export default class OrderDetailViewModel extends ViewModel {
  orderId: string;
  isCreator: boolean = false;
  creatorId: string = "";
  numberInCart: number = 0;
  model: OrderDetailResponse | null = null;
  isLoading: boolean = true;
  errorMessage: ShowErrorModel | null = null;
  Message: string | null = null;
  isLoadingTracking: boolean = false;
  trackingData?: TrackingResponse;
  shouldShowTrackingModal: boolean = false;
  snackbarModel?: ShowSnackbarModel;
  isLoadingReorderForBagItemId?: string;
  orderReceiptData: OrderReceiptResponse | null = null;
  isCancelingOrder?: boolean;
  navBarTransparency = 1;
  shouldCallReceipt: boolean = false;
  delay: number = 120;
  creatorLink?: string;

  closeTrackingModal() {
    this.emit(() => {
      this.shouldShowTrackingModal = false;
    });
  }

  openTrackingModal() {
    this.emit(() => {
      this.shouldShowTrackingModal = true;
    });
  }

  constructor(orderId: string, shouldCallReceipt: boolean = false, creatorLink?: string) {
    super();
    this.orderId = orderId;
    this.shouldCallReceipt = shouldCallReceipt;
    const params = new URLSearchParams(window.location.search);
    const focus = params.get("focus") || "";
    this.creatorLink = creatorLink;

    if (focus) {
      setTimeout(() => {
        this.openTrackingModal();
      }, 0);
      this.loadTracking(this.orderId, [focus]);
    }
  }

  reoder(bagItemIds: string[] = []) {
    this.emit(() => {
      this.isLoadingReorderForBagItemId = bagItemIds[0];
    });

    requestAPI(Endpoints.reorder, {
      onFailure: () => {
        this.emit(() => {
          this.isLoadingReorderForBagItemId = undefined;
        });
      },
      onSuccess: () => {
        this.emit(() => {
          this.isLoadingReorderForBagItemId = undefined;
        });
      },
      parameters: {
        fpAuth: localStorage.getItem("fpAuth"),
        bagItemIds,
      },
    });
  }

  loadTracking(orderId?: string, bagItemIds: string[] = []) {
    if (!orderId) return;

    this.emit(() => {
      this.isLoadingTracking = true;
      this.trackingData = undefined;
    });

    requestAPI(Endpoints.trackMyOrder, {
      onFailure: () => {
        this.isLoadingTracking = false;
      },
      onSuccess: (response: BaseResponse<TrackingResponse>) => {
        this.isLoadingTracking = false;
        this.trackingData = response.data;
        this.emit(() => {});
      },
      parameters: {
        fpAuth: localStorage.fpAuth,
        orderId: orderId,
        bagItemIds,
      },
    });
  }

  createSnackbar(snackbar: ShowSnackbarModel) {
    this.emit(() => {
      this.snackbarModel = snackbar;
    });
  }

  loadPreloadingProps() {
    this.emit(() => {
      this.isLoading = true;
    });

    getPreloadProps({
      onSuccess: (isCreator, creatorId, numberInCart) => {
        this.emit(() => {
          this.isCreator = isCreator;
          this.creatorId = creatorId;
          this.numberInCart = numberInCart;
        });

        if (!this.shouldCallReceipt) {
          this.loadOrderDetail();
        } else {
          this.loadOrderReceipt();
        }
      },
      onFailure: (error) => {
        this.emit(() => {
          this.isLoading = false;
          this.errorMessage = {
            message: error,
            onRetryButtonTapped: () => this.loadPreloadingProps(),
          };
        });
      },
    });
  }

  cancelThisOrder() {
    this.emit(() => {
      this.isCancelingOrder = true;
    });
    requestAPI(Endpoints.cancelOrder, {
      parameters: {
        fpAuth: localStorage.fpAuth,
        orderId: this.orderId,
      },
      onSuccess: () => {
        this.emit(() => {
          this.isCancelingOrder = false;
        });
        this.loadOrderDetail();
      },
      onFailure: (error: string) => {
        this.emit(() => {
          this.isCancelingOrder = false;
        });
        this.emit(() => {
          this.isLoading = false;
          this.snackbarModel = new ShowSnackbarModel({
            message: error,
          });
          this.errorMessage = {
            message: error,
            onRetryButtonTapped: () => this.loadOrderDetail(),
          };
        });
      },
    });
  }

  loadOrderReceipt() {
    this.isLoading = true;
    this.emit(() => {});

    const addedParams = isResellerDomain() ? {
      resellerUsername: this.creatorLink,
      purchasedType: "Dropship"
    } : {}

    requestAPI(
      localStorage.fpAuth === undefined
        ? Endpoints.orderReceiptDevice
        : Endpoints.orderReceipt,
      {
        delay: 2000,
        parameters: {
          fpAuth: localStorage.fpAuth,
          deviceId: localStorage.uuid,
          orderId: this.orderId || localStorage.orderId,
          ...addedParams
        },
        onSuccess: (response: BaseResponse<OrderReceiptResponse>) => {
          this.isLoading = false;
          this.orderReceiptData = response.data;
          this.emit(() => {});

          if (this.shouldCallReceipt && localStorage.getItem('fpAuth')) {
            this.loadOrderDetail();
          }

          window.gtag("event", "purchase", {
            transaction_id: response.data.orderNumber,
            affiliation: "THEFEPI",
            value: response.data.billing.total,
            currency: "IDR",
            tax: 0,
            shipping: 0,
            items: [
              response.data.orderItems[0].items.map((items, index) => ({
                id: items.product_id,
                name: items.name,
                list_name: "Search Results",
                brand: items.brand,
                category: "Apparel/T-Shirts",
                variant: items.attribute,
                list_position: index,
                quantity: items.quantity,
                price: items.price.netPrice,
              })),
            ],
          });

          //TRACK GOOGLE
          window.gtag("event", "conversion", {
            send_to: "UA-75502214-1",
            value: response.data.billing.total,
            currency: "IDR",
          });

          if (window.location.host === "m.thefepi.com") {
            //TRACK FB
            var product_id_arr: string[] = [];
            response.data.orderItems[0].items.map((items) =>
              product_id_arr.push(items.product_id)
            );

            var brand_name_arr: string[] = [];
            response.data.orderItems[0].items.map((items) =>
              brand_name_arr.push(items.brand)
            );
            var data = {
              contents: [
                response.data.orderItems[0].items.map((items) => ({
                  id: items.product_id,
                  product_id: items.product_id,
                  quantity: items.quantity,
                  item_price: items.price.netPrice,
                  brand_name: items.brand,
                })),
              ],
              content_ids: product_id_arr,
              content_name: brand_name_arr,
              content_type: "product",
              value: response.data.billing.total,
              currency: "IDR",
            };
            ReactPixel.track("Purchase", data);
          }
          localStorage.removeItem("orderId");
        },
        onFailure: (error: string) => {
          this.emit(() => {
            this.isLoading = false;
            this.errorMessage = {
              message: error,
              onRetryButtonTapped: () => this.loadOrderDetail(),
            };
          });
        },
      }
    );
  }

  loadOrderDetail() {
    this.emit(() => {
      this.isLoading = true;
      this.errorMessage = null;
    });
    requestAPI(Endpoints.orderDetails, {
      parameters: {
        fpAuth: localStorage.fpAuth,
        orderId: this.orderId,
      },
      onSuccess: (response: BaseResponse<OrderDetailResponse>) => {
        console.log(response.data);
        this.emit(() => {
          this.isLoading = false;
          this.model = response.data;
        });
      },
      onFailure: (error: string) => {
        this.emit(() => {
          this.isLoading = false;
          this.errorMessage = {
            message: error,
            onRetryButtonTapped: () => this.loadOrderDetail(),
          };
        });
      },
    });
  }
}
