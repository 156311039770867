import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import { ShowErrorModel } from "../../CommonComponents/UI/Scaffold/Scaffold";
import { productTypesForBadgeUrls } from "../../CommonComponents/Utils";
import StoreSectionFeedModel from "./Models/StoreSectionFeedModel";
import StoreSectionGetProductsFromFeedResponse from "./Models/StoreSectionGetProductsFromFeedResponse";

export enum SectionType {
  SUBSUBCAT = "SUBSUBCAT"
}

export default class StoreSectionFeedViewModel extends ViewModel {
    model: StoreSectionFeedModel;
    bannerIndex: number = 0;
    timeRemaining?: number;
    isLoadingProducts: boolean = false;
    loadProductsError?: ShowErrorModel;
    selectedCategoryId?: string;
    isShowing: boolean = false;
    productId?: string;
    badgeUrlsMap?: any;
    sellerId?: string;

    constructor(args: {
        model: StoreSectionFeedModel
        badgeUrlsMap: any
    }) {
        super();
        this.model = args.model;
        this.timeRemaining = args.model.contents.timer?.durationRemainingInSeconds;
        this.selectedCategoryId = args.model.contents.categoryItems?.[0]?.id || args.model.contents.tabs?.[0]?.id
        this.badgeUrlsMap = args.badgeUrlsMap
        this.handleTimer();
    }

    handleGridItemClick(url?: string) {
      if (url) window.location.href = url;
    }
 
    changeBannerIndex(index: number) {
        this.emit(() => {
            this.bannerIndex = index;
        });
    }

    onSelectCategory(id?: string, sectionType?: SectionType) {
        this.emit(() => {
            this.model.contents.products = [];
            this.isLoadingProducts = true;
            this.loadProductsError = undefined;
            this.selectedCategoryId = id;
        });

        requestAPI(Endpoints.getProductFromFeed, {
            parameters: {
                feedIdentifier: this.model.identifier,
                categoryId: id
            },
            onSuccess: (response: BaseResponse<StoreSectionGetProductsFromFeedResponse>) => {
                this.emit(() => {
                  if (sectionType === SectionType.SUBSUBCAT) {
                    this.model.contents.singleBannerProducts = response.data.singleBannerProducts;
                    this.model.contents.singleBannerProducts.products =   this.model.contents.singleBannerProducts.products.map((p) => ({
                      ...p,
                      badgeUrls: productTypesForBadgeUrls(
                        p.badgeTypes,
                        this.badgeUrlsMap || {}
                      ),
                    }))
                  } else {
                
                    this.model.contents.products = response.data.products.map((p) => ({
                  ...p,
                  badgeUrls: productTypesForBadgeUrls(
                    p.badgeTypes,
                    this.badgeUrlsMap || {}
                  ),
                }));
                  }
                    this.isLoadingProducts = false;
                });
            },
            onFailure: (error) => {
                this.emit(() => {
                    this.isLoadingProducts = false;
                    this.loadProductsError = {
                        message: error,
                        onRetryButtonTapped: () => this.onSelectCategory(id)
                    };
                });
            }
        });
    }

    changeIsVisible(value: boolean) {
        this.emit(() => this.isShowing = value);
    }

    private handleTimer() {
        const timeRemaining = this.timeRemaining;

        if (timeRemaining) {
            setTimeout(() => {
                if (timeRemaining > 0) {
                    this.emit(() => {
                        this.timeRemaining = timeRemaining - 1;
                    });

                    this.handleTimer();
                }
            }, 1000);
        }
    }
}