import React from "react";
import OrderReceiptResponse from "../Models/OrderReceiptResponse";
import { convertToRupiah } from "../../../CommonComponents/Utils";

type OrderReceiptBillingWidgetProps = {
    orderReceiptData: OrderReceiptResponse | null
};

export default class OrderReceiptBillingWidget extends React.Component<OrderReceiptBillingWidgetProps> {
    render() {
        const orderReceiptData = this.props.orderReceiptData;

        return(  
            <div className="row">
                <div className="col no-padding">
                    <div className='box_white_cart'>
                        <div className="border_bottom">
                            <p><b>Total</b></p>
                        </div>
                        <div className="div_price">
                            {
                            orderReceiptData?.billing.billingItems.map((items,index) => (    
                            <div className="row" key={index}>
                                <div className="col-6">
                                    <span className="color585b63 font12">{items.label.length > 20 ? items.label.substring(0, 20)+'...' : items.label}</span>
                                </div>
                                <div className="col-6 text-end">
                                    <span className="color585b63 font12"><b>{convertToRupiah(items.value)}</b></span>
                                </div>
                            </div>
                            ))}
                            
                            <div className="row div_total">
                                <div className="col-6">
                                    <span className="font12">Total</span>
                                </div>
                                <div className="col-6 text-end">
                                    <span className="font12"><b>{convertToRupiah(orderReceiptData?.billing.total)}</b></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}