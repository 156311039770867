import React, { ReactNode } from "react";

interface SizedDivProps {
    height?: number;
    width?: number;
    children?: ReactNode;
    color?: string;
    isHidden?: boolean;
}

export default class SizedDiv extends React.Component<SizedDivProps> {
    render(): ReactNode {
        return <div
            style={{
                height: this.props.height ? `${this.props.height}px` : undefined,
                width: this.props.width ? `${this.props.width}px` : undefined,
                backgroundColor: this.props.color,
                visibility: this.props.isHidden ? "hidden" : "visible"
            }}
        >
            {this.props.children}
        </div>;
    }
}