import React, { ReactNode } from "react";

import ic_right_chevron_black from "../../../Assets/Images/ic_right_chevron_black.svg";

type MyPageActionProps = {
    onClick?: () => void,
    children?: ReactNode,
    backgroundColor?: string,
}

export default class MyPageActionButton extends React.Component<MyPageActionProps, {}> {
    render() {
        return (
            <button 
                id="MyPageOnlyTextSectionContainer" 
                onClick={() => {
                    if (this.props.onClick) {
                        this.props.onClick();
                    }
                }}
                style={{
                    backgroundColor:this.props.backgroundColor
                }}
            >
                <div>
                    {this.props.children}
                </div>
                {this.props.onClick && <img src={ic_right_chevron_black} alt="" />}
            </button>
        );
    }
}