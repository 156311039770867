import React from "react";
import SizedDiv from "../../../../CommonComponents/UI/SizedDiv";
import { convertToRupiah } from "../../../../CommonComponents/Utils";
import "./OrderTotalPriceWidget.css";

export type OrderTotalPriceWidgetProps = {
  title?: string;
  total?: number;
  billingItems?: {
    label?: string;
    value?: number;
  }[];
};

export default class OrderTotalPriceWidget extends React.Component<OrderTotalPriceWidgetProps> {
  getTextColorForPrice(price?: number) {
    if (!price) return "";
    return price < 0
      ? "OrderTotalPriceWidgetNegativePriceColors"
      : "OrderTotalPriceWidgetNormalPriceColors";
  }

  render(): React.ReactNode {
    const { billingItems = [], title, total = 0 } = this.props;

    return (
      <div className="OrderTotalPriceWidgetContainer">
        <div className="OrderTotalPriceWidgetTitle">{title}</div>
        <SizedDiv height={10} />
        <div className="OrderTotalPriceWidgetBillingItemsContainer">
          {billingItems.map((i) => {
            return (
              <>
                <div className="OrderTotalPriceWidgetBillingItem">
                  <div>
                    <p className="OrderTotalPriceWidgetBillingItemPriceLabel">
                      {i.label}
                    </p>
                  </div>
                  <div className="OrderTotalPriceWidgetBillingItemSpacer" />
                  <div className={this.getTextColorForPrice(i.value)}>
                    <p className="OrderTotalPriceWidgetBillingItemPrice">
                      {convertToRupiah(i.value)}
                    </p>
                  </div>
                </div>
                <SizedDiv height={8} />
              </>
            );
          })}
          <div className="OrderTotalPriceWidgetDivider" />
          <div
            className="OrderTotalPriceWidgetBillingItem"
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <p className="OrderTotalPriceWidgetBillingItemPriceLabel">
                Total
              </p>
            </div>
            <div className={this.getTextColorForPrice(total)}>
              <p className="OrderTotalPriceWidgetTotal">
                {convertToRupiah(total)}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
