import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import BaseViewModel from "../../CommonComponents/Base/BaseViewModel";
import RegisterResponse from "./Models/RegisterResponse";
import { ValidateEmailTelp } from "../../CommonComponents/Utils";

export default class RegisterViewModel extends BaseViewModel {
    input_email_telp: string = '';
    isLoadingButtonSubmit: boolean = false;
    errorMessage: string | null = null;
    isSuccess: boolean = false;
    errorMessageEmailTelp: string = '';

    onEmailOrPhoneNumberChanged(val: string) {
        this.updateState({isLoadingButtonSubmit: false})
        this.updateState({errorMessage: null})
        this.updateState({errorMessageEmailTelp: null})
        
        if(ValidateEmailTelp(val) === true) {
            this.updateState({errorMessageEmailTelp: ''})
            localStorage.setItem("input_email_telp", val)
            this.updateState({
                input_email_telp: val
            })
        }
        else {
            this.updateState({errorMessageEmailTelp: 'Plese input valid email or phone number'})
            this.updateState({
                input_email_telp: ''
            })
        }
    }

    onSnackbarIsFinished() {
        this.updateState({errorMessage: null})
    }

    onSubmitRegister() {
        this.updateState({isLoadingButtonSubmit: true})
        requestAPI(Endpoints.register, {
            parameters: {
                input_email_telp: this.input_email_telp,
                svc_id : '101'
            },
            onSuccess: (response: BaseResponse<RegisterResponse>) => {
                this.updateState({isSuccess: true});
            },
            onFailure: (error) => {
                this.updateState({errorMessage: error});
            }
        });
    }

    override copy({
        input_email_telp = this.input_email_telp, 
        isLoadingButtonSubmit = this.isLoadingButtonSubmit,
        errorMessage = this.errorMessage,
        errorMessageEmailTelp = this.errorMessageEmailTelp,
        isSuccess = this.isSuccess,
    }): this {
        this.input_email_telp = input_email_telp;
        this.isLoadingButtonSubmit = isLoadingButtonSubmit;
        this.errorMessage = errorMessage;
        this.errorMessageEmailTelp = errorMessageEmailTelp;
        this.isSuccess = isSuccess;

        return this;
    }
}