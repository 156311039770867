import React from "react";

import "./IndexIndicatorBullet.css";

type IndexIndicatorBulletProps = {
    showingIndex: number;
    totalOfIndices: number;
    color?: string;
}

export class IndexIndicatorBullet extends React.Component<IndexIndicatorBulletProps, {}> {
    render() {
        const currentIndex = this.props.showingIndex;
        const totalOfIndices = this.props.totalOfIndices;
        
        return (
            <div id="IndexIndicatorBullet">
              {currentIndex}/{totalOfIndices}
            </div>
        )
    }
}