import { requestAPI } from "../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../CommonComponents/ApiRequest/Endpoints";
import ViewModel from "../../CommonComponents/Base/ViewModel";
import {
  ShowErrorModel,
  ShowSnackbarModel,
} from "../../CommonComponents/UI/Scaffold/Scaffold";
import { AddReviewResponse } from "./Models/AddReviewResponse";
import { ToReviewListResponse } from "./Models/ToReviewListResponse";
import { ToReviewWidgetFormFields } from "./ToReviewForm/ToReviewFormWidget";

const DEFAULT_FORM_STATE = {
  checkedProblems: [],
  review: "",
  imageUrls: [],
  rating: 0,
};

export default class ToReviewViewModel extends ViewModel {
  shouldOpenReviewSheet?: boolean;
  toReviewListResponse?: ToReviewListResponse;
  addReviewResponse?: AddReviewResponse;
  formState: ToReviewWidgetFormFields = DEFAULT_FORM_STATE;
  isLoadingToReviewList: boolean = false;
  isSubmittingReview: boolean = false;
  selectedOrderId?: string;
  snackbar?: ShowSnackbarModel;
  error?: ShowErrorModel;
  selectedProduct?: {
    productId?: string;
    brandName?: string;
    productName?: string;
    rating?: number;
    productPhotoUrl?: string;
    productVariantName?: string;
    bagId?: string;
  } = {};

  closeSheet() {
    this.shouldOpenReviewSheet = false;
    this.emit(() => {});
  }

  setProduct(
    product: {
      brandName?: string;
      productName?: string;
      rating?: number;
      productPhotoUrl?: string;
      productVariantName?: string;
      bagId?: string;
      id?: string;
    } = {}
  ) {
    this.selectedProduct = product;
    this.emit(() => {});
  }

  setOrderId(id?: string) {
    this.emit(() => {
      this.selectedOrderId = id;
    });
  }

  resetState() {
    this.formState = DEFAULT_FORM_STATE;
    this.selectedOrderId = undefined;
    this.selectedProduct = undefined;
    this.emit(() => {});
  }

  updateFormState(state: ToReviewWidgetFormFields) {
    this.formState = { ...this.formState, ...state };
    this.emit(() => {});
  }

  submitReview() {
    this.emit(() => {
      this.isSubmittingReview = true;
    });

    requestAPI(Endpoints.addReview, {
      parameters: {
        fpAuth: localStorage.getItem("fpAuth"),
        productId: this.selectedProduct?.productId,
        orderId: this.selectedOrderId,
        review: this.formState.review,
        rating: this.formState.rating,
        imageUrls: this.formState.imageUrls
          ?.filter((x) => x)
          ?.filter((x) => x.state === "IDLE")
          .map((x) => x.url),
        problems: this.formState.checkedProblems,
        isPostToFeed: false,
      },
      onSuccess: (response: BaseResponse<AddReviewResponse>) => {
        this.addReviewResponse = response.data;
        this.isSubmittingReview = false;
        this.snackbar = new ShowSnackbarModel({
          type: "info",
          message: response.message,
        });
        this.emit(() => {});

        this.loadToReviewList();
      },
      onFailure: (err) => {
        console.log(err);
        this.addReviewResponse = undefined;
        this.isSubmittingReview = false;
        this.snackbar = new ShowSnackbarModel({
          type: "info",
          message: err,
        });
        this.emit(() => {});
      },
    });
  }

  openSheet() {
    this.shouldOpenReviewSheet = true;
    this.emit(() => {});
  }

  loadToReviewList() {
    this.emit(() => {
      this.isLoadingToReviewList = true;
    });
    requestAPI(Endpoints.toReviewList, {
      onSuccess: (response: BaseResponse<ToReviewListResponse>) => {
        this.toReviewListResponse = response.data;

        this.isLoadingToReviewList = false;
        this.emit(() => {});

        const params = new URLSearchParams(window.location.search);
        const id = params.get("id");
        setTimeout(() => {
          document.getElementById(id || "")?.scrollIntoView();
        }, 0);
      },
      onFailure: () => {
        this.isLoadingToReviewList = false;
        this.emit(() => {});
      },
      parameters: {
        fpAuth: localStorage.getItem("fpAuth"),
      },
    });
  }
}
