import { eventManager } from "../../../Analytics/Events/Common";
import { requestAPI } from "../../../CommonComponents/ApiRequest/APIRequest";
import BaseResponse from "../../../CommonComponents/ApiRequest/BaseResponse";
import { Endpoints } from "../../../CommonComponents/ApiRequest/Endpoints";
import BaseViewModel from "../../../CommonComponents/Base/BaseViewModel";
import PopUpWidgetModel from "../../../CommonComponents/UI/PopUp/Models/PopUpWidgetModel";
import { ShowErrorModel } from "../../../CommonComponents/UI/Scaffold/Scaffold";
import { ShareUrlModel } from "../../../CommonComponents/UI/ShareUrlPopUp/Models/ShareUrlModel";
import { getPreloadProps } from "../../../CommonComponents/Utils";
import { ContentDisplayModel } from "../Models/ContentDisplayModels";

export default class ContentDisplayViewModel extends BaseViewModel {
    numberInCart: number = 0;
    model: ContentDisplayModel[] = [];
    isLoading = true;
    errorMessage: ShowErrorModel | null = null;
    popUpModel: PopUpWidgetModel | null = null;
    shareUrlModel: ShareUrlModel | null = null;
    sellerId?: string;
    pages: number = 0;
    query?: string;
    currentPage: number = 0;

    private id: string;

    constructor(id: string) {
        super();
        this.id = id;
        const params = new URLSearchParams(window.location.search)
        this.query = params.get('query') || ""
    }

    loadPreloadingProps() {
        this.updateState({isLoading: true});

        getPreloadProps({
            onSuccess: (isCreator, creatorId, numberInCart) => {
                this.updateState({
                    isCreator: isCreator,
                    creatorId: creatorId,
                    numberInCart: numberInCart,
                    isPreloadingLoaded: true
                })

                this.loadContent();
            },
            onFailure: (error) => {
                this.updateState({
                    isLoading: false,
                    errorMessage: {
                        message: error,
                        onRetryButtonTapped: () => this.loadPreloadingProps()
                    },
                });
            }
        })
    }

    loadContent() {
        this.updateState({isLoading: this.model.length === 0, errorMessage: null});

        requestAPI(Endpoints.contentDisplay, {
            parameters: {
                contentId: this.id,
                limit: 2,
                query: this.query,
                pageNumber: this.currentPage
            },
            onSuccess: (response: BaseResponse<{contents: ContentDisplayModel[], pageNumber: number, totalOfPages: number}>) => {
                this.updateState({
                    isLoading: false, 
                    model: this.model.concat(response.data.contents),
                    pages: response.data.totalOfPages,
                    currentPage: this.currentPage + 1
                })
            },
            onFailure: (error: string) => {
                this.updateState({
                    isLoading: false, 
                    errorMessage: {
                        message: error,
                        onRetryButtonTapped: () => this.loadContent()
                    }
                })
            }
        });
    }

    loveContent(id: string, isLiked: boolean) {
        if (!localStorage.fpAuth) {
            this.updateState({
                popUpModel: PopUpWidgetModel.loginRequestPopUpModel
            });

            return;
        }

        this.model = this.model.map((m) => {
          if (m.contentId === id) {
            return {...m, contentId: id}
          }
          return m
        })

        requestAPI(Endpoints.likeContent, {
            parameters: {
                contentId: id,
                isLiked: isLiked
            },
            onSuccess: (response: {}) => {
                // TODO:
            },
            onFailure: (error: string) => {
                // TODO:
            }
        });
    }

    followCreator(id: string) {
        if (!localStorage.fpAuth) {
            this.updateState({
                popUpModel: PopUpWidgetModel.loginRequestPopUpModel
            });

            return;
        }
        
        requestAPI(Endpoints.followCreator, {
            parameters: {
                sellerId: id,
                isFollowed: true
            },
            onSuccess: (response: {}) => {
                // TODO:
            },
            onFailure: (error: string) => {
                // TODO:
            }
        })
    }

    onShareLink(value: string) {
        let splitted = value.split("/")
        eventManager.flush();
        eventManager.add({
          content_type: "content",
          item_id: "content_" + parseInt(splitted[splitted.length-1])
        })
        this.updateState({shareUrlModel: new ShareUrlModel({urlString: value})});
    }

    override copy({
        numberInCart = this.numberInCart,
        model = this.model,
        isLoading = this.isLoading,
        errorMessage = this.errorMessage,
        popUpModel = this.popUpModel,
        shareUrlModel = this.shareUrlModel,
        currentPage = this.currentPage,
        pages = this.pages
    }) {
        this.numberInCart = numberInCart;
        this.model = model;
        this.isLoading = isLoading;
        this.errorMessage = errorMessage;
        this.popUpModel = popUpModel;
        this.shareUrlModel = shareUrlModel;
        this.currentPage = currentPage;
        this.pages = pages

        return this;
    }
}